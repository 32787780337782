import { BaseArrayState, BaseObjectState } from '../../types';
import {
    SET_CREATE_ORGANIZATION_LOADING,
    SET_CREATE_ORGANIZATION_SUCCESS,
    SET_CREATE_ORGANIZATION_ERROR,
    SET_UPDATE_ORGANIZATION_LOADING,
    SET_UPDATE_ORGANIZATION_SUCCESS,
    SET_UPDATE_ORGANIZATION_ERROR,
    SET_GET_ORGANIZATION_LOADING,
    SET_GET_ORGANIZATION_SUCCESS,
    SET_GET_ORGANIZATION_ERROR,
    SET_LIST_ORGANIZATION_LOADING,
    SET_LIST_ORGANIZATION_SUCCESS,
    SET_LIST_ORGANIZATION_ERROR
} from './constants';

interface OrganizationState {
    create: BaseObjectState;
    update: BaseObjectState;
    get: BaseObjectState;
    list: BaseArrayState
}

const INITIAL_STATE: OrganizationState = {
    create: {
        state: "pending",
        data: {},
        error: {},
    },
    update: {
        state: "pending",
        data: {},
        error: {},
    },
    get: {
        state: "pending",
        data: {},
        error: {},
    },
    list: {
        state: "pending",
        data: [],
        error: {},
    },
};

const OrganizationReducer = (state = INITIAL_STATE, action: any): OrganizationState => {
    switch (action.type) {
        case SET_CREATE_ORGANIZATION_LOADING:
            return {
                ...state,
                create: {
                    ...state.create,
                    state: "loading",
                    error: {},
                },
            };

        case SET_CREATE_ORGANIZATION_SUCCESS:
            return {
                ...state,
                create: {
                    ...state.create,
                    state: "success",
                    data: action.payload,
                    error: {},
                },
            };

        case SET_CREATE_ORGANIZATION_ERROR:
            return {
                ...state,
                create: {
                    ...state.create,
                    state: "error",
                    error: action.payload,
                },
            };

        case SET_UPDATE_ORGANIZATION_LOADING:
            return {
                ...state,
                update: {
                    ...state.update,
                    state: "loading",
                    error: {},
                },
            };

        case SET_UPDATE_ORGANIZATION_SUCCESS:
            return {
                ...state,
                update: {
                    ...state.update,
                    state: "success",
                    data: action.payload,
                    error: {},
                },
            };

        case SET_UPDATE_ORGANIZATION_ERROR:
            return {
                ...state,
                update: {
                    ...state.update,
                    state: "error",
                    error: action.payload,
                },
            };

        case SET_GET_ORGANIZATION_LOADING:
            return {
                ...state,
                get: {
                    ...state.get,
                    state: "loading",
                    error: {},
                },
            };

        case SET_GET_ORGANIZATION_SUCCESS:
            return {
                ...state,
                get: {
                    ...state.get,
                    state: "success",
                    data: action.payload,
                    error: {},
                },
            };

        case SET_GET_ORGANIZATION_ERROR:
            return {
                ...state,
                get: {
                    ...state.get,
                    state: "error",
                    error: action.payload,
                },
            };
        case SET_LIST_ORGANIZATION_LOADING:
            return {
                ...state,
                list: {
                    ...state.list,
                    state: "loading",
                    error: {},
                },
            };

        case SET_LIST_ORGANIZATION_SUCCESS:
            return {
                ...state,
                list: {
                    ...state.list,
                    state: "success",
                    data: action.payload,
                    error: {},
                },
            };
        case SET_LIST_ORGANIZATION_ERROR:
            return {
                ...state,
                list: {
                    ...state.list,
                    state: "error",
                    error: action.payload,
                },
            };
        default:
            return state;
    }
};

export { OrganizationReducer };
