import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  AudioLinesIcon,
  Building2Icon,
  FolderUpIcon,
  InboxIcon,
  LayoutDashboardIcon,
  LogOutIcon,
  MessagesSquareIcon,
} from "lucide-react";
import _ from "lodash";
import Logo from "../assets/img/logo-full.png";
import { useAppSelector } from "../lib/hooks";
import { UserProfile } from "../lib/app/platform/types";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

export default function Sidebar() {
  const profile = useAppSelector<UserProfile>(
    (state) => state.app.platform.profile.data
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies([
    "accessToken",
    "refreshToken",
  ]);

  return (
    <Stack
      gap={3}
      borderRight={"1px solid #E4E4E4"}
      h={"100vh"}
      py={4}
      px={2}
      justifyContent={"space-between"}
    >
      <Box>
        <Image src={Logo} />
      </Box>
      <Divider />
      <Stack gap={3} flex={1}>
        <Text
          mb={1}
          ml={1}
          fontWeight={"bold"}
          color={"gray"}
          fontSize={"x-small"}
          textTransform={"uppercase"}
        >
          Overview
        </Text>
        <Button
          hidden
          size={"sm"}
          justifyContent={"start"}
          color={
            location.pathname.includes("/app/home")
              ? "var(--chakra-colors-primary-500)"
              : "black"
          }
          variant={"none"}
          background={
            location.pathname.includes("/app/home")
              ? "var(--chakra-colors-primary-50)"
              : "none"
          }
          onClick={() => {
            navigate("/app/home");
          }}
          fontSize={"sm"}
          colorScheme="primary"
          leftIcon={<LayoutDashboardIcon size={20} />}
          aria-label=""
        >
          Home
        </Button>
        <Button
          justifyContent={"start"}
          size={"sm"}
          onClick={() => {
            navigate("/app/assistants");
          }}
          colorScheme="primary"
          color={
            location.pathname.includes("/app/assistants")
              ? "var(--chakra-colors-primary-500)"
              : "#343434"
          }
          fontSize={"sm"}
          variant={"none"}
          background={
            location.pathname.includes("/app/assistants")
              ? "var(--chakra-colors-primary-50)"
              : "none"
          }
          leftIcon={<MessagesSquareIcon size={20} />}
          aria-label=""
        >
          Assistants
        </Button>
        <Button
          hidden
          justifyContent={"start"}
          size={"sm"}
          onClick={() => {
            navigate("/app/files");
          }}
          colorScheme="primary"
          color={
            location.pathname.includes("/app/files")
              ? "var(--chakra-colors-primary-500)"
              : "#343434"
          }
          variant={"none"}
          background={
            location.pathname.includes("/app/files")
              ? "var(--chakra-colors-primary-50)"
              : "none"
          }
          fontSize={"sm"}
          leftIcon={<FolderUpIcon size={20} />}
          aria-label=""
        >
          Files
        </Button>
        <Button
          onClick={() => {
            navigate("/app/voice-library");
          }}
          size={"sm"}
          color={
            location.pathname.includes("/app/voice-library")
              ? "var(--chakra-colors-primary-500)"
              : "#343434"
          }
          variant={"none"}
          background={
            location.pathname.includes("/app/voice-library")
              ? "var(--chakra-colors-primary-50)"
              : "none"
          }
          fontSize={"sm"}
          justifyContent={"start"}
          aria-label=""
          leftIcon={<AudioLinesIcon size={20} />}
        >
          Voice Library
        </Button>
        <Button
          onClick={() => {
            navigate("/app/organizations");
          }}
          size={"sm"}
          color={
            location.pathname.includes("/app/organizations")
              ? "var(--chakra-colors-primary-500)"
              : "#343434"
          }
          variant={"none"}
          background={
            location.pathname.includes("/app/organizations")
              ? "var(--chakra-colors-primary-50)"
              : "none"
          }
          fontSize={"sm"}
          justifyContent={"start"}
          aria-label=""
          leftIcon={<Building2Icon size={20} />}
        >
          Organizations
        </Button>
        <Button
          hidden
          color={
            location.pathname.includes("/app/logs")
              ? "var(--chakra-colors-primary-500)"
              : "#343434"
          }
          variant={"none"}
          background={
            location.pathname.includes("/app/logs")
              ? "var(--chakra-colors-primary-50)"
              : "none"
          }
          onClick={() => {
            navigate("/app/logs");
          }}
          px={3}
          justifyContent={"start"}
          aria-label=""
          fontSize={"sm"}
          leftIcon={<InboxIcon size={20} />}
        >
          Call Logs
        </Button>
      </Stack>
      <Flex justifyContent={"space-between"} alignItems={"center"} gap={2}>
        <Avatar size={"sm"} name={profile.username} />
        <Box flex={1}>
          <Text fontWeight={600}>{_.capitalize(profile.username)}</Text>
          <Text fontSize={"small"} color={"gray"}>
            {profile.email}
          </Text>
        </Box>
        <IconButton
          size={"sm"}
          onClick={() => {
            removeCookie("accessToken", {
              path: "/",
            });
            removeCookie("refreshToken", {
              path: "/",
            });
            navigate("/auth/login?logout=true");
          }}
          aria-label=""
          icon={<LogOutIcon size={20} />}
        />
      </Flex>
    </Stack>
  );
}
