/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex } from "@chakra-ui/react";
import Sidebar from "../@components/Sidebar";
import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch } from "../lib/hooks";
import { loadAgents, loadConfig, loadUserProfile } from "../lib/app/platform/thunk";

export default function Shell () {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(loadConfig())
        dispatch(loadUserProfile())
        // dispatch(loadAgents())
    }, [])
    
    return (
        <Flex>
            <Box w={'220px'}>
                <Sidebar />
            </Box>
            <Box w={"full"} h={"100vh"} overflow={"scroll"} flex={1}>
                <Outlet />
            </Box>
        </Flex>
    )
}