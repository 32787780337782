import { AppDispatch } from '../../store';
import Cookies from 'universal-cookie';
import moment from "moment-timezone";
import { setLoginError, setLoginLoading, setLoginSuccess } from "./actions"
import { axios, axiosv2 } from "../../../utils/axios.loader"
import { setUserProfile } from "../../app/platform/actions";
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { AuthManager } from '../../../utils/firebase.loader';

const cookies = new Cookies();

export const initiateEmailLogin = (email: string, password: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoginLoading())
    try {
        const request = await axios.post('/users/login', {
            email,
            password
        })
        const { username, id, profile_pic, access_token, refresh_token } = request.data;
        cookies.set("accessToken", access_token, {
            expires: moment().add(1, "day").toDate(),
            path: "/"
        })
        cookies.set("refreshToken", refresh_token, {
            expires: moment().add(1, "day").toDate(),
            path: "/"
        })
        axios.defaults.headers.Authorization = `Bearer ${access_token}`
        axiosv2.defaults.headers.Authorization = `Bearer ${access_token}`
        dispatch(setUserProfile({
            image: profile_pic,
            id,
            username
        }))
        dispatch(setLoginSuccess({
            username,
            id,
            image: profile_pic
        }))

    } catch (err: any) {
        dispatch(setLoginError(err.toString()))
    }
}

export const initiateGoogleLogin = () => async (dispatch: AppDispatch) => {
    dispatch(setLoginLoading())
    try {
        const provider = new GoogleAuthProvider()
        const credentials = await signInWithPopup(AuthManager, provider)
        console.log(credentials.user)
    } catch (err: any) {
        dispatch(setLoginError(err.toString()))
    }
}