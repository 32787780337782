import { BaseArrayState, BaseObjectState } from "../../types"
import { SET_AGENT_ERROR, SET_AGENT_LOADING, SET_AGENT_SUCCESS, SET_COMPANY, SET_CONFIG_ERROR, SET_CONFIG_LOADING, SET_CONFIG_SUCCESS, SET_LIST_AGENTS_ERROR, SET_LIST_AGENTS_LOADING, SET_LIST_AGENTS_SUCCESS, SET_ORGANIZATION_GET_ERROR, SET_ORGANIZATION_GET_LOADING, SET_ORGANIZATION_GET_SUCCESS, SET_USER_PROFILE } from "./constants"

interface PlatformState {
    profile: BaseObjectState
    company: BaseObjectState
    agents: {
        list: BaseArrayState
    },
    selectedAgent: BaseObjectState,
    config: BaseObjectState,
    get: BaseObjectState
}

const INITIAL_STATE: PlatformState = {
    profile: {
        state: "loading",
        data: {},
        error: {}
    },
    company: {
        state: "loading",
        data: {},
        error: {}
    },
    agents: {
        list: {
            state: "loading",
            data: [],
            error: {}
        }
    },
    selectedAgent: {
        state: "pending",
        data: {},
        error: {}
    },
    config: {
        state: "pending",
        data: {},
        error: {}
    },
    get: {
        state: "loading",
        data: {},
        error: {}
    }
}

export const PlatformReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case SET_USER_PROFILE:
            return {
                ...state,
                profile: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_LIST_AGENTS_LOADING:
            return {
                ...state,
                agents: {
                    ...state.agents,
                    list: {
                        state: "loading",
                        data: [],
                        error: ''
                    }
                }
            }
        case SET_LIST_AGENTS_SUCCESS:
            return {
                ...state,
                agents: {
                    ...state.agents,
                    list: {
                        state: "success",
                        data: action.payload.agents,
                        metadata: {
                            page: action.payload.page,
                            page_size: action.payload.page_size,
                            total_count: action.payload.total_count
                        },
                        error: ''
                    }
                }
            }
        case SET_LIST_AGENTS_ERROR:
            return {
                ...state,
                agents: {
                    ...state.agents,
                    list: {
                        state: "error",
                        data: [],
                        error: action.error,
                        metadata: {}
                    }
                }
            }
        case SET_COMPANY:
            return {
                ...state,
                company: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_AGENT_LOADING:
            return {
                ...state,
                selectedAgent: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_AGENT_SUCCESS:
            return {
                ...state,
                selectedAgent: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_AGENT_ERROR:
            return {
                ...state,
                selectedAgent: {
                    state: "success",
                    data: {},
                    error: 'Something went wrong'
                }
            }
        case SET_CONFIG_LOADING:
            return {
                ...state,
                config: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_CONFIG_SUCCESS:
            return {
                ...state,
                config: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_CONFIG_ERROR:
            return {
                ...state,
                config: {
                    state: "error",
                    data: {},
                    error: ''
                }
            }
        case SET_ORGANIZATION_GET_LOADING:
            return {
                ...state,
                get: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_ORGANIZATION_GET_SUCCESS:
            return {
                ...state,
                get: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_ORGANIZATION_GET_ERROR:
            return {
                ...state,
                get: {
                    state: "error",
                    data: {},
                    error: action.error || 'Something went wrong'
                }
            }
        default:
            return state
    }
}