import Axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const token = cookies.get("accessToken")

const axios = Axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        "Content-Type": "application/json",
        ...(token ? {
            "Authorization": `Bearer ${token}`
        } : {})
    },
    responseType: 'json'
})

const axiosv2 = Axios.create({
    baseURL: `${process.env.REACT_APP_API_V2_URL}`,
    headers: {
        "Content-Type": "application/json",
        ...(token ? {
            "Authorization": `Bearer ${token}`
        } : {})
    },
    responseType: 'json'
})

export { axios, axiosv2 }