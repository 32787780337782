/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Center, Flex, FormControl, FormHelperText, FormLabel, IconButton, Input, Spinner, Stack, Text, Textarea, useBoolean, useMediaQuery, useToast } from "@chakra-ui/react";
import { BotIcon, ChevronsUpDownIcon, CopyIcon, PaintbrushIcon, PanelLeftIcon, PaperclipIcon, PlayCircleIcon, PlusIcon, RefreshCcwIcon, Trash2Icon } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { CreatableSelect, Select, chakraComponents } from "chakra-react-select";
import Page from "../../@components/Page";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { Agent } from "../../lib/app/platform/types";
import { setAgentSuccess } from "../../lib/app/platform/actions";
import { onDispatchMessage, onRecieveMessage, resetPlayground, setSocketConnecting, setSocketError, setSocketSuccess } from "../../lib/app/playground/actions";
import { Field, Form, Formik } from "formik";
import { updateAgent } from "../../lib/app/assistants/thunk";
import { extractVariables } from "../../utils/helper.utils";
import { resetAgentUpdate } from "../../lib/app/assistants/actions";
import moment from "moment-timezone";

interface ChatBubbleProps {
    role: "ai" | "user";
    agent?: string,
    message: string
    key?: string
}

function ChatBubble(props: ChatBubbleProps) {
    return (
        <Stack key={props.key} gap={1} borderBottom={"0.5px solid #E4E4E4"} p={4} w={['100%', '82.5%']} ml={"auto"} mr={"auto"}>
            <Text fontWeight={"semibold"}>{props.role === "user" ? "You" : `${props.agent || 'AI'}`}</Text>
            <Text>{props.message}</Text>
        </Stack>
    )
}

export default function Playground() {
    const [currentMessage, setCurrentMessage] = useState('')
    const socket = useRef<WebSocket | null>(null);
    const container = useRef<HTMLDivElement>(null);
    const [cookies] = useCookies(["accessToken"])
    const updatedAgent = useAppSelector(state => state.app.assistants.update)
    const messages = useAppSelector(state => state.app.playground.messages.data)
    const connection = useAppSelector(state => state.app.playground.connection)
    const agents = useAppSelector(state => state.app.platform.agents.list)
    // const selectedAgent = useAppSelector(state => state.app.platform.selectedAgent)
    const selectedAgentData = useAppSelector<Agent>(state => state.app.platform.selectedAgent.data)
    const dispatch = useAppDispatch();
    const toaster = useToast()
    const [isMobile] = useMediaQuery('(max-width: 480px)')
    const [hidden, { on, off, toggle }] = useBoolean(false)

    useEffect(() => {
        if (isMobile && !hidden) {
            on()
        } else if (!isMobile && hidden) {
            off()
        }
    }, [isMobile])

    useEffect(() => {
        // Using this to disconnect on unmount
        return () => {
            if (socket.current) {
                socket.current.close()
            }
        }
    }, [])

    useEffect(() => {
        if (connection.state === "success") {
            if (currentMessage.length) {
                socket.current!.send(JSON.stringify({ "message": currentMessage }))
                dispatch(onDispatchMessage(currentMessage))
                setCurrentMessage('')
                setTimeout(() => {
                    if (container.current) {
                        container.current.scrollTo({
                            top: container.current.scrollHeight,
                            behavior: "smooth"
                        })
                    }
                }, 500)
            }
        }
    }, [connection])

    useEffect(() => {
        // The below logic is kind of similar to the above one as well. Will refractor when time permits
        if (updatedAgent.state === "success") {
            toaster({
                title: "Agent updated successfully",
                description: "Your agent is up to date",
                duration: 3000,
                status: "success",
                size: "sm"
            })
            dispatch(setAgentSuccess(updatedAgent.data))
            setTimeout(() => {
                dispatch(resetAgentUpdate())
            }, 1000)
        }
    }, [ updatedAgent ])

    const initiateConnection = useCallback(() => {
        if (!socket.current) {
            // todo: replace this with actual selected agent id
            socket.current = new WebSocket(`${process.env.REACT_APP_WS_URL}/agent/interact/${selectedAgentData.id}?token=${cookies.accessToken}`)
            socket.current.addEventListener("open", () => {
                dispatch(setSocketSuccess())
            })
            socket.current.addEventListener("message", (e) => {
                const data = JSON.parse(e.data)
                dispatch(onRecieveMessage(data.ai))
                setTimeout(() => {
                    if (container.current) {
                        container.current.scrollTo({
                            top: container.current.scrollHeight,
                            behavior: "smooth"
                        })
                    }
                }, 500)
            })
            socket.current.addEventListener("error", (e) => {
                dispatch(setSocketError())
            })
        }
    }, [cookies, selectedAgentData])

    useEffect(() => {
        if (socket.current) {
            socket.current.close()
            socket.current = null
            initiateConnection()
            setCurrentMessage('')
            dispatch(resetPlayground())
        }
    }, [selectedAgentData])

    useEffect(() => {
        if (agents.state === "success" && !selectedAgentData.id) {
            dispatch(setAgentSuccess(agents.data[0]))
        }
    }, [agents])

    const ValueContainer = (props: any) => {
        return (
            chakraComponents.ValueContainer && (
                <chakraComponents.ValueContainer {...props}>
                    <Flex alignItems={"center"}>
                        {!!props.children && (
                            <BotIcon style={{
                                marginTop: -4
                            }} />
                        )}
                        {props.children}
                    </Flex>
                </chakraComponents.ValueContainer>
            )
        );
    };

    return (
        <Page showHamburger onHamburgerClick={toggle} title="Playground">
            <Flex>
                <Stack display={[hidden ? "none" : "flex", "flex"]} w={["calc(100% -54px)", "auto"]} background={"white"} pos={["fixed", "relative"]} zIndex={1} flex={[0, 0.3]} py={4} px={[2, 6]} borderRight={"1px solid #E4E4E4"} gap={[0, 6]} height={"calc(100vh - 64px)"}>
                    {agents.state === "loading" ? <Center height={"100%"}>
                        <Spinner />
                    </Center> : <>
                        <Select onChange={(e) => {
                            dispatch(setAgentSuccess(agents.data.filter((a: any) => a.id === e!.value)[0]))
                        }} selectedOptionColorScheme="primary" variant={"unstyled"} chakraStyles={{
                            dropdownIndicator: (b) => ({
                                ...b,
                                backgroundColor: "transparent",
                                borderColor: "transparent",
                                border: 0
                            }),
                            control: (b) => ({
                                ...b,
                                borderRadius: 8
                            }),
                            menuList: (b) => ({
                                ...b,
                                borderRadius: 8
                            }),
                            valueContainer: (b) => ({
                                ...b,
                                "fontWeight": "bold",
                                cursor: "pointer"
                            })
                        }} components={{
                            DropdownIndicator: (props) => (
                                <chakraComponents.DropdownIndicator {...props}>
                                    <ChevronsUpDownIcon size={16} />
                                </chakraComponents.DropdownIndicator>
                            ),
                            ValueContainer
                        }} value={{
                            label: selectedAgentData.name,
                            value: selectedAgentData.id
                        }} options={agents.data.map((d: any) => ({
                            label: d.name,
                            value: d.id
                        }))}
                        />
                        <Formik onSubmit={(values) => {
                            const payload = {
                                "ai_model": {
                                    "name": values.model_name,
                                    "use_case": values.use_case,
                                    "sub_use_case": values.sub_use_case,
                                    "model_metadata": {},// todo: need to do this
                                    "prompt": values.prompt,
                                    "prompt_variables": values.prompt_variables,
                                    "personality": values.personality
                                },
                                "name": values.name,
                                "profile_pic": "http://example.com/sample.png",
                                "use_case": values.use_case,
                                "sub_use_case": values.sub_use_case,
                                "phone_numbers": values.phone_numbers
                            }
                            dispatch(updateAgent(selectedAgentData.id, payload))
                        }} enableReinitialize
                            initialValues={{
                                name: selectedAgentData.name,
                                prompt: selectedAgentData.ai_model?.prompt,
                                model_name: selectedAgentData.ai_model?.name,
                                use_case: selectedAgentData.use_case,
                                sub_use_case: selectedAgentData.sub_use_case,
                                personality: selectedAgentData.ai_model,
                                prompt_variables: selectedAgentData.ai_model?.prompt_variables || [],
                                phone_numbers: selectedAgentData.phone_numbers || []
                            }}>
                            {({ errors, touched, values, setFieldValue }) => {
                                return (
                                    <Form style={{
                                        flex: 1
                                    }}>
                                        <Stack  height={"calc(100% - 36px)"} justifyContent={"space-between"}>
                                            <Stack gap={4} flex={1}>
                                                <FormControl>
                                                    <FormLabel fontWeight={600}>Name</FormLabel>
                                                    <Field as={Input} borderRadius={8} name="name" size={"sm"} placeholder="Enter a user friendly name" />
                                                    <FormHelperText color={"gray"} ml={4}>{selectedAgentData.id}</FormHelperText>
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel fontWeight={600}>Instructions</FormLabel>
                                                    <Field onChange={(v: any) => {
                                                        setFieldValue('prompt_variables', Array.from(new Set([
                                                            ...extractVariables(v.target.value)
                                                        ]).values()))
                                                        setFieldValue('prompt', v.target.value, true)
                                                    }} as={Textarea} name={"prompt"} resize={"none"} borderRadius={8} rows={6} size={"sm"} placeholder="You are a helpful assistant" />
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel fontWeight={600}>Model</FormLabel>
                                                    <Select onChange={(v) => {
                                                            setFieldValue('model_name', v?.value)
                                                        }} selectedOptionColorScheme="primary" size={"sm"} variant={"outline"} chakraStyles={{
                                                        dropdownIndicator: (b) => ({
                                                            ...b,
                                                            backgroundColor: "transparent",
                                                            borderColor: "transparent",
                                                            border: 0
                                                        }),
                                                        control: (b) => ({
                                                            ...b,
                                                            borderRadius: 8
                                                        }),
                                                        menuList: (b) => ({
                                                            ...b,
                                                            borderRadius: 8
                                                        })
                                                    }} components={{
                                                        DropdownIndicator: (props) => (
                                                            <chakraComponents.DropdownIndicator {...props}>
                                                                <ChevronsUpDownIcon size={12} />
                                                            </chakraComponents.DropdownIndicator>
                                                        )
                                                    }} value={{
                                                        label: values.model_name,
                                                        value: values.model_name
                                                    }} options={[
                                                        {
                                                            label: "gpt-3.5-turbo-16k",
                                                            value: "gpt-3.5-turbo-16k"
                                                        }
                                                    ]} />
                                                </FormControl>
                                                <FormControl>
                                                <FormLabel fontWeight={600}>Prompt Variables</FormLabel>
                                                <CreatableSelect
                                                    isMulti
                                                    onChange={(v) => {
                                                        setFieldValue('prompt_variables', v.map(c => c.value))
                                                    }}
                                                    value={(values.prompt_variables || []).map(p => ({
                                                        "value": p,
                                                        "label": p
                                                    }))}
                                                    placeholder={"Select prompt variables"}
                                                    selectedOptionColorScheme="primary" size={"sm"} variant={"outline"} chakraStyles={{
                                                        dropdownIndicator: (b) => ({
                                                            ...b,
                                                            backgroundColor: "transparent",
                                                            borderColor: "transparent",
                                                            border: 0
                                                        }),
                                                        control: (b) => ({
                                                            ...b,
                                                            borderRadius: 8
                                                        }),
                                                        menuList: (b) => ({
                                                            ...b,
                                                            borderRadius: 8
                                                        })
                                                    }} components={{
                                                        DropdownIndicator: (props) => (
                                                            <chakraComponents.DropdownIndicator {...props}>
                                                                <ChevronsUpDownIcon size={12} />
                                                            </chakraComponents.DropdownIndicator>
                                                        )
                                                    }} />
                                            </FormControl>
                                            </Stack>
                                        </Stack>
                                        <Flex py={2} direction={["row"]} alignItems={[ "center"]} w={"100%"} justifyContent={"space-between"}>
                                        <Flex alignItems={"center"} gap={2}>
                                            <Button isLoading={updatedAgent.state === "loading"} type="submit" variant={"outline"} borderRadius={8} size={"sm"} leftIcon={<RefreshCcwIcon size={16} />}>Update agent</Button>
                                            {/* <Button borderRadius={8} size={"sm"} leftIcon={<CopyIcon size={16} />}>Clone</Button> */}
                                            <IconButton borderRadius={8} size={"sm"} icon={<Trash2Icon size={16} />} aria-label="" />

                                        </Flex>
                                        <Text color={"gray"} fontSize={"small"}>last updated: {moment.parseZone(selectedAgentData.updated_at).fromNow()}</Text>
                                        </Flex>
                                    </Form>
                                )
                            }}
                        </Formik></>}
                </Stack>
                <Stack maxH={['calc(100vh - 36px)', 'calc(100vh - 64px)']} h={['calc(100vh - 72px)', 'auto']} overflow={"hidden"} mb={0} flex={[1, 0.7]} py={3} px={[2, 6]}>
                    <Flex py={3} justifyContent={"space-between"}>
                        <Text fontWeight={500}></Text>
                        <Flex gap={2}>
                            <Button onClick={() => {
                                dispatch(resetPlayground())
                            }} variant={"ghost"} leftIcon={<PaintbrushIcon size={16} />} size={"sm"}>Clear</Button>
                            <Button variant={"outline"} leftIcon={<PanelLeftIcon size={16} />} size={"sm"}>Logs</Button>
                        </Flex>
                    </Flex>
                    <Stack ref={container} maxH={"calc(100vh - 240px)"} overflow={"scroll"} flex={1}>
                        {messages.map(m => <ChatBubble agent={selectedAgentData.name} key={m.key} message={m.message!} role={m.role} />)}
                    </Stack>
                    <Stack alignItems={"center"}>
                        <Box border={"1px solid #E4E4E4"} pt={4} px={4} pb={4} borderRadius={16} w={["100%", "80%"]}>
                            <Input onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    // todo: generalise this, too much repetitive code
                                    if (currentMessage.length) {
                                        if (!socket.current) {
                                            dispatch(setSocketConnecting())
                                            initiateConnection()
                                        } else {
                                            setTimeout(() => {
                                                if (container.current) {
                                                    container.current.scrollTo({
                                                        top: container.current.scrollHeight,
                                                        behavior: "smooth"
                                                    })
                                                }
                                            }, 500)
                                            dispatch(onDispatchMessage(currentMessage))
                                            socket.current!.send(JSON.stringify({ "message": currentMessage }))
                                            setCurrentMessage('')
                                        }
                                    }
                                }
                            }} value={currentMessage} onChange={(v) => {
                                setCurrentMessage(v.target.value)
                            }} _focusVisible={{
                                boxShadow: 0
                            }} pl={0} border={0} outline={0} placeholder="Enter a message..." />
                            <Flex alignItems={"center"} mt={4} justifyContent={"space-between"}>
                                <IconButton size={"sm"} borderRadius={8} aria-label="" icon={<PaperclipIcon size={16} />} />
                                <Flex alignItems={"center"} gap={2}>
                                    <IconButton size={"sm"} borderRadius={8} aria-label="" icon={<PlusIcon size={20} />} />
                                    <Button isLoading={connection.state === "loading"} onClick={() => {
                                        if (currentMessage.length) {
                                            if (!socket.current) {
                                                dispatch(setSocketConnecting())
                                                initiateConnection()
                                            } else {
                                                setTimeout(() => {
                                                    if (container.current) {
                                                        container.current.scrollTo({
                                                            top: container.current.scrollHeight,
                                                            behavior: "smooth"
                                                        })
                                                    }
                                                }, 500)
                                                dispatch(onDispatchMessage(currentMessage))
                                                socket.current!.send(JSON.stringify({ "message": currentMessage }))
                                                setCurrentMessage('')
                                            }
                                        }
                                    }} isDisabled={!currentMessage.length} size={"sm"} fontSize={14} fontWeight={"bold"} borderRadius={8} leftIcon={<PlayCircleIcon size={20} />} variant={"solid"} colorScheme="primary" aria-label="">Run</Button>
                                </Flex>
                            </Flex>
                        </Box>
                        <Text fontWeight={500} color={"gray"} fontSize={"small"}>Playground API is beta. Current version 0.0.1</Text>
                    </Stack>
                </Stack>
            </Flex>
        </Page>
    )
}