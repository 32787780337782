// Action Types
import {
    SET_CREATE_ORGANIZATION_LOADING,
    SET_CREATE_ORGANIZATION_SUCCESS,
    SET_CREATE_ORGANIZATION_ERROR,
    SET_UPDATE_ORGANIZATION_LOADING,
    SET_UPDATE_ORGANIZATION_SUCCESS,
    SET_UPDATE_ORGANIZATION_ERROR,
    SET_DELETE_ORGANIZATION_LOADING,
    SET_DELETE_ORGANIZATION_SUCCESS,
    SET_DELETE_ORGANIZATION_ERROR,
    SET_GET_ORGANIZATION_LOADING,
    SET_GET_ORGANIZATION_SUCCESS,
    SET_GET_ORGANIZATION_ERROR,
    SET_LIST_ORGANIZATION_LOADING,
    SET_LIST_ORGANIZATION_SUCCESS,
    SET_LIST_ORGANIZATION_ERROR
} from './constants';

// Action Creators

export const setCreateOrganizationLoading = () => ({
    type: SET_CREATE_ORGANIZATION_LOADING,
});

export const setCreateOrganizationSuccess = (organization: any) => ({
    type: SET_CREATE_ORGANIZATION_SUCCESS,
    payload: organization,
});

export const setCreateOrganizationError = (error: string) => ({
    type: SET_CREATE_ORGANIZATION_ERROR,
    payload: error,
});

export const setUpdateOrganizationLoading = () => ({
    type: SET_UPDATE_ORGANIZATION_LOADING,
});

export const setUpdateOrganizationSuccess = (organization: any) => ({
    type: SET_UPDATE_ORGANIZATION_SUCCESS,
    payload: organization,
});

export const setUpdateOrganizationError = (error: string) => ({
    type: SET_UPDATE_ORGANIZATION_ERROR,
    payload: error,
});

export const setDeleteOrganizationLoading = () => ({
    type: SET_DELETE_ORGANIZATION_LOADING,
});

export const setDeleteOrganizationSuccess = (organizationId: string) => ({
    type: SET_DELETE_ORGANIZATION_SUCCESS,
    payload: organizationId,
});

export const setDeleteOrganizationError = (error: string) => ({
    type: SET_DELETE_ORGANIZATION_ERROR,
    payload: error,
});

export const setGetOrganizationLoading = () => ({
    type: SET_GET_ORGANIZATION_LOADING,
});

export const setGetOrganizationSuccess = (organization: any) => ({
    type: SET_GET_ORGANIZATION_SUCCESS,
    payload: organization,
});

export const setGetOrganizationError = (error: string) => ({
    type: SET_GET_ORGANIZATION_ERROR,
    payload: error,
});

export const setListOrganizationLoading = () => ({
    type: SET_LIST_ORGANIZATION_LOADING,
});

export const setListOrganizationSuccess = (organizations: any[], metadata: any) => ({
    type: SET_LIST_ORGANIZATION_SUCCESS,
    payload: organizations,
    metadata
});

export const setListOrganizationError = (error: string) => ({
    type: SET_LIST_ORGANIZATION_ERROR,
    payload: error,
});
