import { Flex, IconButton, Stack, StackProps, Text } from "@chakra-ui/react";
import { MenuIcon } from "lucide-react";
import { MouseEventHandler, ReactNode } from "react";

interface PageProps extends StackProps {
    actions?: ReactNode[]
    showHamburger?: boolean
    onHamburgerClick?: MouseEventHandler<HTMLElement>
}

export default function Page(props: PageProps) {
    return (
        <Stack w={"full"} flex={1} gap={0}>
            <Flex alignItems={"center"} py={4} px={4}  w={"full"} justifyContent={"space-between"}>
                <Flex alignItems={"center"} gap={2}>
                    {props.showHamburger ? <IconButton size={"sm"} variant={"none"} display={["flex", "none"]} aria-label="" icon={<MenuIcon size={20} />} onClick={props.onHamburgerClick} /> : <></>}
                    <Text fontWeight={"bold"} fontSize={20}>{props.title}</Text>
                </Flex>
                <Flex>
                    {props.actions}
                </Flex>
            </Flex>
            {props.children}
        </Stack>
    )
}