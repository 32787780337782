/* eslint-disable react-hooks/exhaustive-deps */
import { AbsoluteCenter, Alert, AlertTitle, Box, Button, Center, Divider, Flex, FormControl, FormErrorMessage, FormLabel, Heading, IconButton, Image, Input, InputGroup, InputRightElement, Link, Stack, Text, useBoolean } from "@chakra-ui/react";
import { Field, Formik, Form } from "formik";
import GoogleIcon from '../../assets/img/google.svg'
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { resetState } from "../../lib/auth/signup/actions";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { EyeIcon, EyeOffIcon } from "lucide-react";
import { initiateEmailSignup } from "../../lib/auth/signup/thunk";

export default function SignUp () {
    const dispatch = useAppDispatch();
    const state = useAppSelector(state => state.auth.signup.state)
    const error = useAppSelector(state => state.auth.signup.error)
    const [isVisible, { toggle: toggleVisibility }] = useBoolean(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (state === "success") {
           setTimeout(() => {
            navigate("/app/assistants")
           }, 2000)
        }
    }, [ state ])

    return (
        <Flex justifyContent={"space-between"}>
            <Stack borderRight={"1px solid #f4f4f4"} flex={[0, 0.5]}>

            </Stack>
            <Center height={"100vh"} flex={[1, 0.6]}>
                <Stack alignItems={"center"} borderRadius={8} py={8} px={[2, 12]} w={['80vw', '35vw']}>
                    <Heading w={"full"} size={"lg"} variant={"h3"}>Create an account</Heading>
                    {error.length ? <Alert py={2} mb={3} borderRadius={6} variant={"solid"} status="error">
                        <AlertTitle>{error}</AlertTitle>
                    </Alert> : <></> }
                    <Formik onSubmit={(values) => {
                        dispatch(initiateEmailSignup({
                            ...values,
                            is_google_signup: false
                        }))
                    }} initialValues={{
                        name: '',
                        username: '',
                        email: '',
                        password: ''
                    }}>
                        {({ errors, touched }) => (
                            <Form style={{
                                width: "100%"
                            }} onChange={() => {
                                dispatch(resetState())
                            }}>
                                <Stack gap={2}>
                                    <FormControl isInvalid={!!errors.name && touched.name}>
                                        <FormLabel>Name</FormLabel>
                                        <Field validate={(value: any) => {
                                            let error;
                                            if (!value) {
                                                return "Name is required"
                                            }
                                            return error
                                        }} as={Input} name="name" rounded={"full"} placeholder="Enter your name"  />
                                        <FormErrorMessage>{errors.name}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!errors.username && touched.username}>
                                        <FormLabel>Username</FormLabel>
                                        <Field validate={(value: any) => {
                                            let error;
                                            if (!value) {
                                                return "Username is required"
                                            }
                                            return error
                                        }} as={Input} name="username" rounded={"full"} placeholder="Enter your username"  />
                                        <FormErrorMessage>{errors.username}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!errors.email && touched.email}>
                                        <FormLabel>Email address</FormLabel>
                                        <Field validate={(value: any) => {
                                            let error;
                                            if (!value) {
                                                return "Email address is required"
                                            }
                                            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
                                               return "Invalid email address"
                                            }
                                            return error
                                        }} as={Input} name="email" rounded={"full"} placeholder="Enter your email address" type='email'  />
                                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!errors.password && touched.password}>
                                        <FormLabel>Password</FormLabel>
                                        <InputGroup>
                                            <Field validate={(value: any) => {
                                                let error;
                                                if (!value) {
                                                    error = "Password is required"
                                                }
                                                return error
                                            }} as={Input} name="password" rounded={"full"} placeholder="Enter password" type={isVisible ? 'text' : 'password'}  />
                                            <InputRightElement>
                                                <IconButton variant={"none"} size={"sm"} aria-label="" icon={isVisible ? <EyeOffIcon size={20} /> : <EyeIcon size={20} />} onClick={toggleVisibility} />
                                            </InputRightElement>
                                        </InputGroup>
                                        <FormErrorMessage>{errors.password}</FormErrorMessage>
                                    </FormControl>
                                </Stack>
                                <Button isDisabled={state === "loading" || state === "success"} isLoading={state === "loading" || state === "success"} type="submit" mt={4} variant={"solid"} colorScheme={"primary"} rounded={"full"} w={"full"}>Signup</Button>
                            </Form>
                        )}
                    </Formik>
                    <Box w={"full"} position='relative' padding='5'>
                        <Divider />
                        <AbsoluteCenter bg='white' px='4'>
                            OR
                        </AbsoluteCenter>
                    </Box>
                    <Button isDisabled alignItems={"center"} leftIcon={<Image verticalAlign={"middle"} src={GoogleIcon} boxSize={4} /> } variant={"outline"} rounded={"full"} w={"full"}>Sign in with Google</Button>
                    <Text mt={4} fontSize={"sm"}>Already have an account ? <Link href="/auth/login" textDecor={"underline"} color="var(--chakra-colors-primary-500)">Login now</Link></Text>
                </Stack>
            </Center>
        </Flex>
    )
}