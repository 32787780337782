import { axios, axiosv2 } from "../../../utils/axios.loader";
import { VapiAPIManager } from "../../../utils/vapi.manager";
import { AppDispatch } from "../../store";
import { setAgentCreateError, setAgentCreateLoading, setAgentCreateSuccess, setAgentGetLoading, setAgentGetSuccess, setAgentUpdateError, setAgentUpdateLoading, setAgentUpdateSuccess } from "./actions";

export const createAgent = (organization_id: string, payload: any) => async (dispatch: AppDispatch) => {
    dispatch(setAgentCreateLoading())
    try {
        const request = await axiosv2.post(`/agent/organization/${organization_id}/create`, {
            ...payload,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString()
        })
        const response = request.data
        dispatch(setAgentCreateSuccess(response))
    } catch (err: any) {
        dispatch(setAgentCreateError(["object", "undefined"].includes(typeof(err?.response?.data?.detail)) ? "Something went wrong. Please contact the administrator" : err?.response?.data?.message))
    }
}

export const updateAgent = (id: string, payload: any, organization_id?: string) => async (dispatch: AppDispatch) => {
    dispatch(setAgentUpdateLoading())
    try {
        if (payload.provider_id) {
            const request = await axiosv2.patch(`/agent/organization/${organization_id || payload.organization_id}/${id}`, {
                ...payload,
                status: "ONLINE",
                updated_at: new Date().toISOString()
            })
            const response = request.data
            dispatch(setAgentUpdateSuccess(response))
            dispatch(setAgentGetSuccess(response))
        } else {
            const request = await axios.put(`/agent/update/${id}`, {
                ...payload,
                updated_at: new Date().toISOString()
            })
            const response = request.data
            dispatch(setAgentUpdateSuccess(response))
        }
    } catch (err: any) {
        dispatch(setAgentUpdateError(err?.response?.data?.detail || err?.response?.data?.message))
    }
}



export const loadSpecificAgent = (agent: string, company?: string) => async (dispatch: AppDispatch) => {
    console.warn(agent)
    dispatch(setAgentGetLoading())
    try {
        const request = await axiosv2.get(`/agent/${agent}`)
        const response = request.data;
        dispatch(setAgentGetSuccess(response, {}))
    } catch (err) {
        console.log(err)
    }
}

export const uploadAgentImage = (organization_id: string, agent_id: string, blob: Blob | File | null, callback?: (data: any) => void) => async (dispatch: AppDispatch) => {
    try {
        const manager = new VapiAPIManager(organization_id)
        const response = await manager.uploadProfilePic(agent_id, blob)
        callback?.(response)
    } catch (err) {
        callback?.({
            "status": "failed"
        })
    }
}

export const testAgentViaCall = (name: string, phone_number: string, agent_phone_number: string, onSuccessCallback?: () => void) => async (dispatch: AppDispatch) => {
    try {
        await axios.post(`/agent/call_me_test`, {
            name,
            phone_number,
            agent_phone_number
        })
        onSuccessCallback?.()
    } catch (err) {
        console.log(err)
    }
}

export const testAgentViaCallV2 = (organization_id: string, assistantId: string, phoneNumber: string, number: string, name: string, onSuccessCallback?: () => void) => async (dispatch: AppDispatch) => {
    try {
        const manager = new VapiAPIManager(organization_id)
        await manager.initiateCall(assistantId, number, phoneNumber, name)
        onSuccessCallback?.()
    } catch (err) {
        console.log(err)
    }
}

export const searchPhoneNumbers = async (keyword = '', page = 1, size = 10) => {
    try {
        const request = await axios.get(`/agent/phone/my-numbers/?page=${page}&page_size=${size}${keyword ? `&filters=phone_number[contains]=${keyword}` : ''}`)
        const numbers = request.data;
        return (numbers || []).map((v: any) => ({
            label: v.friendly_name,
            value: v.phone_number
        }))
    } catch (err) {
        return []
    }
}

export const getPhoneNumbers = async (organization_id: string, callback?: () => void) => {
    try {
        const manager = new VapiAPIManager(organization_id)
        const numbers = await manager.getPhoneNumbers()
        return (numbers || []).map((v: any) => ({
            label: `${v.number} ${v.assistantId ? `(In use)` : ''}`,
            value: v.id
        }))
    } catch (err) {
        return []
    }
}

export const attachPhoneNumber = async (organization_id: string, assistantId: string, phoneNumber: string) => {
    // todo: revisit for optimal logic
    try {
        const manager = new VapiAPIManager(organization_id)
        await manager.attachPhoneNumber(phoneNumber, assistantId)
    } catch (err) {
        console.log(err)
    }
}

export const detachPhoneNumber = async (organization_id: string, phoneNumber: string) => {
    // todo: revisit for optimal logic
    try {
        const manager = new VapiAPIManager(organization_id)
        await manager.detachPhoneNumber(phoneNumber)
    } catch (err) {
        console.log(err)
    }
}