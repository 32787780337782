/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Box,
  Button,
  Center,
  CircularProgress,
  CircularProgressLabel,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  IconButton,
  Img,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  List,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Skeleton,
  SkeletonCircle,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Spinner,
  Stack,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  TabProps,
  Tabs,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { ReactNode, RefObject, useEffect, useRef, useState } from "react";
import {
  attachPhoneNumber,
  detachPhoneNumber,
  getPhoneNumbers,
  loadSpecificAgent,
  searchPhoneNumbers,
  testAgentViaCall,
  testAgentViaCallV2,
  updateAgent,
  uploadAgentImage,
} from "../../lib/app/assistants/thunk";
import { Field, Form, Formik, useFormikContext } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import {
  ALargeSmallIcon,
  ArrowLeftIcon,
  ArrowLeftRightIcon,
  AudioLinesIcon,
  BrainIcon,
  CaptionsIcon,
  CheckCheckIcon,
  CheckCircle2Icon,
  ChevronsUpDownIcon,
  CogIcon,
  CopyIcon,
  GaugeIcon,
  HistoryIcon,
  HourglassIcon,
  InfoIcon,
  LanguagesIcon,
  Laptop2Icon,
  LineChartIcon,
  LinkIcon,
  MessagesSquareIcon,
  MicIcon,
  MicOffIcon,
  MoreVerticalIcon,
  PauseIcon,
  PhoneIcon,
  PhoneMissedIcon,
  PhoneOffIcon,
  PhoneOutgoingIcon,
  PlayIcon,
  RefreshCcw,
  RocketIcon,
  RotateCcwIcon,
  ShellIcon,
  ShieldIcon,
  SquareFunctionIcon,
  SyringeIcon,
  TimerResetIcon,
  TrashIcon,
  UploadCloudIcon,
  VerifiedIcon,
  Volume1Icon,
} from "lucide-react";
import { extractVariables } from "../../utils/helper.utils";
import {
  AsyncSelect,
  CreatableSelect,
  Select,
  chakraComponents,
} from "chakra-react-select";
import _ from "lodash";
import moment from "moment-timezone";
import {
  resetAgentUpdate,
  setAgentGetReset,
  setAgentGetSuccess,
  setAgentUpdateSuccess,
} from "../../lib/app/assistants/actions";
import { searchVoices } from "../../lib/app/voice-library/thunk";
import Vapi from "@vapi-ai/web";
import { getOrganization } from "../../lib/app/platform/thunk";
import { PhoneInput } from "react-international-phone";
import '../../assets/css/phone-input.scss';
// @ts-ignore
import { PhoneNumberUtil } from 'google-libphonenumber';
import { Audio } from "./VoiceLibrary";
import Dropzone from "react-dropzone";
import AIGif from '../../assets/img/ai.gif';
import Editor from '@monaco-editor/react';
import Millis from "@millisai/web-sdk";


interface TabItemProps extends TabProps {
  icon: ReactNode;
}

function TabItem(props: TabItemProps) {
  return (
    <Flex align={"center"} gap={2}>
      {props.icon}
      <Text fontWeight={"semibold"} fontSize={14}>
        {props.title}
      </Text>
    </Flex>
  );
}

const labelStyles = {
  mt: "2",
  ml: "0",
  fontSize: "sm",
};

const dropdownStyles: any = {
  dropdownIndicator: (b: any) => ({
    ...b,
    backgroundColor: "transparent",
    borderColor: "transparent",
    border: 0,
  }),
  control: (b: any) => ({
    ...b,
    borderRadius: 8,
  }),
  menuList: (b: any) => ({
    ...b,
    borderRadius: 8,
  }),
};

const dropdownComponents = {
  DropdownIndicator: (props: any) => (
    <chakraComponents.DropdownIndicator {...props}>
      <ChevronsUpDownIcon size={12} />
    </chakraComponents.DropdownIndicator>
  ),
};

interface FieldConfig {
  display_name?: string;
  key?: string;
  default?: 1;
  type?: string;
  description?: string;
  name?: string;
  value?: string | number | boolean;
  placeholder?: string | number | boolean;
  showInfoIcon?: boolean;
  helpText?: string;
  onChangeCallback?: any;
  provider?: string;
  setIsDirty?: any;
}

const KEY_CONFIG: any = {
  OpenAI: {
    temperature: {
      max: 2,
      min: 0,
      step: 0.1,
      default: 1,
    },
    top_p: {
      max: 1,
      min: 0,
      step: 0.1,
      default: 0,
    },
    max_tokens: {
      max: 1000,
      min: 128,
    },
    frequency_penalty: {
      max: 2,
      min: 0,
      step: 0.1,
      default: 0,
    },
    presence_penalty: {
      max: 2,
      min: 0,
      step: 0.1,
      default: 0,
    },
  },
  Anthropic: {
    temperature: {
      max: 2,
      min: 0,
      step: 0.1,
      default: 0.6,
    },
    max_tokens: {
      max: 4096,
      min: 128,
      default: 500,
    },
  },
  Google: {
    temperature: {
      max: 2,
      min: 0,
      step: 0.1,
      default: 0.6,
    },
    top_p: {
      max: 1,
      min: 0,
      step: 0.1,
      default: 0,
    },
    top_k: {
      max: 1,
      min: 0,
      step: 0.1,
      default: 0,
    },
  },
};

function GenerateFieldFromConfig({ config }: { config: FieldConfig }) {
  const [value, setValue] = useState<any>(null);

  useEffect(() => {
    if (!config.value && config.provider) {
      setValue(
        config.value || KEY_CONFIG?.[config.provider!]?.[config.key!]?.default
      );
    } else if (Array.isArray(config.value)) {
      setValue(
        config.value.map((v) => ({
          label: v,
          value: v,
        }))
      );
    } else if (config.value) {
      setValue(config.value);
    }
  }, [config.provider, config.value]);

  return (
    <FormControl>
      <FormLabel>
        <Flex justifyContent={"space-between"} gap={2} align={"center"}>
          <Flex gap={2} align={"center"}>
            <Text fontSize={14} fontWeight={600}>
              {config.display_name}
            </Text>
            {config.description ? (
              <Tooltip
                hasArrow
                rounded={8}
                p={2}
                py={1}
                bg={"#343434"}
                label={config.description}
              >
                <InfoIcon cursor={"pointer"} size={12} />
              </Tooltip>
            ) : (
              <></>
            )}
          </Flex>
          {!["list[str]", "list[SafetySetting]"].includes(config.type!) ? (
            <Text>{value}</Text>
          ) : (
            <></>
          )}
        </Flex>
      </FormLabel>
      {config.type === "list[str]" ? (
        <CreatableSelect
          isMulti
          onChange={(v) => {
            setValue(v);
            config.onChangeCallback?.(
              v.map((j) => j.value),
              config.key
            );
            config?.setIsDirty(true);
          }}
          value={value}
          placeholder={config.placeholder}
          selectedOptionColorScheme="primary"
          size={"sm"}
          variant={"outline"}
          chakraStyles={dropdownStyles}
          components={dropdownComponents}
        />
      ) : config.type === "float" &&
        config.provider &&
        KEY_CONFIG?.[config.provider!]?.[config.key!] !== undefined ? (
        <Slider
          colorScheme="primary"
          value={value}
          onChange={(v) => {
            setValue(v);
            config.onChangeCallback?.(v, config.key);
            config?.setIsDirty(true);
          }}
          min={KEY_CONFIG[config.provider!][config.key!].min}
          step={KEY_CONFIG[config.provider!][config.key!].step}
          max={KEY_CONFIG[config.provider!][config.key!].max}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
      ) : (
        <NumberInput
          value={value || 0}
          max={KEY_CONFIG[config.provider!]?.[config.key!]?.max}
          min={KEY_CONFIG[config.provider!]?.[config.key!]?.min}
        >
          <Field
            as={["int"].includes(config.type!) ? NumberInputField : Input}
            onInput={(ev: any) => {
              // const min = KEY_CONFIG[config.provider!][config.key!].min;
              const max = KEY_CONFIG[config.provider!][config.key!].max;
              if (ev.target.value > max) {
                ev.target.value = max;
              }
              // else if (ev.target.value < min) {
              //   ev.target.value = min
              // }
            }}
            onChange={(v: any) => {
              setValue(v.target.value);
              config.onChangeCallback?.(v.target.value, config.key);
              config?.setIsDirty(true);
            }}
            value={value}
            name={config.name}
            max={KEY_CONFIG[config.provider!]?.[config.key!]?.max}
            min={KEY_CONFIG[config.provider!]?.[config.key!]?.min}
            borderRadius={8}
            size={"sm"}
            placeholder={config.placeholder}
          />
        </NumberInput>
      )}
    </FormControl>
  );
}

interface ConfigProps {
  onFinalSubmit: (payload: any, errors?: any) => void;
  data: any;
  values?: any;
}

function ModelConfig(props: ConfigProps) {
  const ref = useRef<any>();
  const config = useAppSelector((state) => state.app.platform.config);
  const [selectedModel, setSelectedModel] = useState<
    | {
        label?: string;
        value?: string;
      }
    | string
  >({});
  const [provider, setProvider] = useState<{
    label?: string;
    value?: string;
  }>({});

  useEffect(() => {
    const key = Object.keys(config.data.models || {}).filter((v) =>
      config.data.models[v].includes(props.data.ai_model_name)
    )[0];
    setSelectedModel({
      label: props.data.ai_model_name,
      value: props.data.ai_model_name,
    });
    if (key) {
      setProvider({
        label: key,
        value: key,
      });
    }
  }, [config, props.data]);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (provider.value) {
      for (const key of config.data.config?.[provider.value].fields || []) {
      }
    }
  }, [provider]);

  return (
    <Formik
      validateOnMount
      initialValues={{
        prompt: props.data.system_prompt,
        model_name: props.data.ai_model_name,
        prompt_variables: props.data.system_prompt_variables || [],
        temperature: props.data.ai_model_config?.temperature || 1,
        detect_emotion: props.data.ai_model_config?.emotionRecognitionEnabled || 1,
        top_p: props.data.ai_model_config?.top_p,
        max_tokens: props.data.ai_model_config?.max_tokens || 250,
        personality: props.data.ai_model_config?.personality || "AUTO",
        frequency_penalty: props.data.ai_model_config?.frequency_penalty,
        presence_penalty: props.data.ai_model_config?.presence_penalty,
        streaming: props.data.ai_model_config?.streaming || false,
        stop: props.data.ai_model_config?.stop || [],
        greeting_message: props.data?.advanced_config?.firstMessage || ''
      }}
      onSubmit={(v) => {
        console.log(v);
      }}
    >
      {({ errors, touched, setFieldValue, values, setFieldTouched }) => {
        return (
          <Form
            ref={ref}
            onBlur={() => {
              const _errors = Object.keys(errors);
              if (!_errors.length && values.model_name && isDirty) {
                props.onFinalSubmit({
                  ai_model_name: values.model_name,
                  system_prompt: values.prompt,
                  system_prompt_variables: values.prompt_variables || [],
                  ai_model_config: {
                    temperature: values.temperature || 1,
                    top_p: values.top_p,
                    stop: values.stop,
                    max_tokens: Number.parseInt(values.max_tokens, 10),
                    frequency_penalty: values.frequency_penalty,
                    presence_penalty: values.presence_penalty,
                    streaming: values.streaming || false,
                    emotionRecognitionEnabled: values.detect_emotion || false,
                    model_name: values.model_name,
                    personality: values.personality
                  },
                  advanced_config: {
                    ...(props.data.advanced_config || {}),
                    firstMessage: values.greeting_message
                  }
                });
              } else {
                // @ts-ignore
                props.onFinalSubmit?.cancel();
              }
            }}
          >
            <Flex
              borderRadius={8}
              py={6}
              px={4}
              border={"1px solid #e4e4e4"}
              gap={4}
            >
              <Box flex={0.7}>
                <FormControl py={4}>
                  <FormLabel>
                    <Stack gap={0}>
                      <Text fontSize={14}>Greeting Message</Text>
                      <Text color={"gray"} fontSize={"small"}>
                        This is the message that the assistant will say during the start of the call
                      </Text>
                    </Stack>
                  </FormLabel>
                  <Field
                    size="sm"
                    fontSize={14}
                    rounded={6}
                    as={Input}
                    placeholder="Enter greeting message"
                    name="greeting_message"
                    value={values.greeting_message}
                    onChange={(v: any) => {
                      setFieldValue("greeting_message", v.target.value);
                      setIsDirty(true);
                    }}
                  />
                </FormControl>

<Divider />
                <FormControl isInvalid={!!errors.prompt}>
                  <FormLabel>
                    <Flex gap={2} align={"center"}>
                      <Text fontSize={14} fontWeight={600}>
                        System Prompt
                      </Text>
                      <Tooltip label="The system prompt can be used to configure the context, role, personality, instructions and so on for the assistant.">
                        <InfoIcon cursor={"pointer"} size={12} />
                      </Tooltip>
                    </Flex>
                  </FormLabel>
                  <Field
                    value={values.prompt}
                    onChange={(v: any) => {
                      setFieldValue(
                        "prompt_variables",
                        Array.from(
                          new Set([
                            ...(values.prompt_variables || []),
                            ...extractVariables(v.target.value),
                          ]).values()
                        )
                      );
                      setFieldValue("prompt", v.target.value, true);
                      setIsDirty(true);
                    }}
                    validate={(v: any) => {
                      let error;
                      if (!v) {
                        error = "System Prompt is required";
                      }
                      return error;
                    }}
                    as={Textarea}
                    name="prompt"
                    resize={"none"}
                    borderRadius={8}
                    rows={22}
                    size={"sm"}
                    placeholder="You are a helpful assistant"
                  />
                  <FormErrorMessage>{errors.prompt as string}</FormErrorMessage>
                </FormControl>
              </Box>
              <Grid
                flex={0.3}
                gap={4}
                height={"fit-content"}
                gridTemplateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)"]}
              >
                <FormControl>
                  <FormLabel fontSize={"small"} fontWeight={600}>
                    Provider
                  </FormLabel>
                  <Select
                    onChange={(v) => {
                      setFieldValue("provider", v?.value);
                      setProvider({
                        label: v?.label,
                        value: v?.value,
                      });
                      setFieldValue("model_name", "", true);
                      setSelectedModel("");
                      setIsDirty(true);
                    }}
                    value={values.model_name ? provider : undefined}
                    placeholder={"Select ai provider"}
                    selectedOptionColorScheme="primary"
                    size={"sm"}
                    variant={"outline"}
                    chakraStyles={dropdownStyles}
                    components={dropdownComponents}
                    options={Object.keys(config.data.models || {}).map((v) => ({
                      label: v,
                      value: v,
                    }))}
                  />
                </FormControl>
                <FormControl isInvalid={!!errors.model_name}>
                  <FormLabel fontSize={"small"} fontWeight={600}>
                    Model
                  </FormLabel>
                  <Field
                    as={Select}
                    onChange={(v: any) => {
                      if (typeof v === "object") {
                        setFieldValue("model_name", v?.value);
                        setSelectedModel({
                          label: v?.label,
                          value: v?.value,
                        });
                        setFieldTouched("model_name", true);
                        setIsDirty(true);
                      }
                    }}
                    validate={(v: any) => {
                      let error;
                      if (!v) {
                        error = "Model name is required";
                      }
                      return error;
                    }}
                    name={"model_name"}
                    value={selectedModel}
                    placeholder={"Select ai model"}
                    selectedOptionColorScheme="primary"
                    size={"sm"}
                    variant={"outline"}
                    chakraStyles={dropdownStyles}
                    components={dropdownComponents}
                    isLoading={!provider.value}
                    options={
                      provider.value
                        ? config.data?.models?.[provider.value!].map(
                            (m: string) => ({
                              label: m,
                              value: m,
                            })
                          )
                        : []
                    }
                  />
                  <FormErrorMessage>
                    {(errors?.model_name as string) || ""}
                  </FormErrorMessage>
                </FormControl>
                {provider.value ? (
                  Object.values(
                    config.data?.config?.[provider.value].fields || []
                  ).map((f: any) => {
                    return (
                      <GenerateFieldFromConfig
                        config={{
                          ...f,
                          onChangeCallback: (value: any, key: string) => {
                            setFieldValue(key, value);
                          },
                          setIsDirty,
                          value: props.data.ai_model_config?.[f.key],
                          provider: provider.value,
                        }}
                      />
                    );
                  })
                ) : (
                  <></>
                )}
                <FormControl display={"flex"} justifyContent={"space-between"} alignItems={"center"} isInvalid={!!errors.detect_emotion}>
                  <FormLabel my={0} fontWeight={600}>
                    Detect Emotion
                  </FormLabel>
                  <Field
                    as={Switch}
                    isChecked={values.detect_emotion}
                    onChange={(v: any) => {
                      if (typeof v === "object") {
                        setFieldValue("detect_emotion", v.target.checked);
                        setFieldTouched("detect_emotion", true);
                        setIsDirty(true);
                      }
                    }}
                    name={"detect_emotion"}
                    value={values.detect_emotion}
                    selectedOptionColorScheme="primary"
                    size={"sm"}
                    variant={"outline"}
                  />
                  <FormErrorMessage>
                    {(errors?.model_name as string) || ""}
                  </FormErrorMessage>
                </FormControl>
              </Grid>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
}

interface CustomSliderProps {
  label: string;
  value: number;
  max: number;
  min: number;
  step: number;
  onChange: any;
  info_texts: string[];
}

function CustomSlider(props: CustomSliderProps) {
  return (
    <Flex align={"center"} justifyContent={"space-between"}>
      <Text flex={0.3}>{props.label}</Text>
      <Flex alignItems={"center"} gap={6} flex={0.75}>
        <Text w={"36px"}>{props.value}</Text>
        <Slider
          onChange={props.onChange}
          colorScheme="teal"
          defaultValue={props.value}
          step={props.step}
          max={props.max}
          min={props.min}
        >
          {props.info_texts[0] ? (
            <SliderMark
              {...labelStyles}
              display={"flex"}
              alignItems={"center"}
              gap={2}
              value={props.min}
            >
              <Text fontSize={12} color={"gray"}>
                {props.info_texts[0]}
              </Text>
              <InfoIcon size={12} color="gray" />
            </SliderMark>
          ) : (
            <></>
          )}
          {props.info_texts[1] ? (
            <SliderMark
              {...labelStyles}
              left={"auto !important"}
              right={"0"}
              display={"flex"}
              alignItems={"center"}
              gap={2}
              value={1}
            >
              <Text fontSize={12} color={"gray"}>
                {props.info_texts[1]}
              </Text>
              <InfoIcon size={12} color="gray" />
            </SliderMark>
          ) : (
            <></>
          )}
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
      </Flex>
    </Flex>
  );
}

interface CustomSliderV2Props {
  title: string;
  subtitle?: string;
  value: number;
  max: number;
  min: number;
  step: number;
  onChange: any;
  icon?: any;
  info_texts: string[];
}

function CustomSliderV2(props: CustomSliderV2Props) {
  return (
    <Flex py={3} gap={3} align={"center"} justifyContent={"space-between"}>
      {props.icon}
      <Stack gap={0} flex={0.4}>
        <Text>{props.title}</Text>
        <Text fontSize={12}>{props.subtitle}</Text>
      </Stack>
      <Flex alignItems={"center"} gap={6} flex={0.6}>
        <Slider
          onChange={props.onChange}
          colorScheme="teal"
          defaultValue={props.value}
          step={props.step}
          max={props.max}
          min={props.min}
        >
          {props.info_texts[0] ? (
            <SliderMark
              {...labelStyles}
              display={"flex"}
              alignItems={"center"}
              gap={2}
              value={props.min}
            >
              <Text fontSize={12} color={"gray"}>
                {props.info_texts[0]}
              </Text>
              <InfoIcon size={12} color="gray" />
            </SliderMark>
          ) : (
            <></>
          )}
          {props.info_texts[1] ? (
            <SliderMark
              {...labelStyles}
              left={"auto !important"}
              right={"0"}
              display={"flex"}
              alignItems={"center"}
              gap={2}
              value={1}
            >
              <Text fontSize={12} color={"gray"}>
                {props.info_texts[1]}
              </Text>
              <InfoIcon size={12} color="gray" />
            </SliderMark>
          ) : (
            <></>
          )}
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
        <Box
          w={16}
          textAlign={"center"}
          p={2}
          rounded={8}
          border={"1px solid #cacaca"}
        >
          {props.value}
        </Box>
      </Flex>
    </Flex>
  );
}

function TranscriberConfig(props: ConfigProps) {
  const ref = useRef<any>();
  const [isDirty, setIsDirty] = useState(false);
  return (
    <Formik
      initialValues={{
        provider: props.data.transcriber_config?.provider || "deepgram",
        language: props.data.transcriber_config?.language || "en-US",
        model: props.data.transcriber_config?.model || "nova-2-general",
        secondary_language: props.data.transcriber_config?.secondary_language || [],
        multilingual: props.data.transcriber_config?.multilingual || false,
        language_switching: props.data.transcriber_config?.language_switching || false,
        custom_vocabulary: (props.data.transcriber_config?.custom_vocabulary || []).map((e: string) => ({
          "label": e,
          "value": e
        }))
      }}
      onSubmit={() => {}}
    >
      {({ errors, values, setFieldValue, handleBlur }) => {
        return (
          <Form
            ref={ref}
            onBlur={() => {
              const _errors = Object.keys(errors);
              console.log(_errors)
              console.log(values)
              if (!_errors.length && values.provider && isDirty) {
                props.onFinalSubmit({
                  transcriber_config: {
                    provider: values.provider,
                    language: values.language,
                    model: values.model,
                    secondary_language: values.secondary_language,
                    multilingual: values.multilingual,
                    language_switching: values.language_switching,
                    custom_vocabulary: values.custom_vocabulary.map((e: any) => e.value)
                  }
                });
              } else {
                // @ts-ignore
                props.onFinalSubmit?.cancel();
              }
            }}
            onChange={(v) => {}}
          >
            <Stack gap={6}>
              <Stack
                borderRadius={8}
                py={6}
                px={4}
                border={"1px solid #e4e4e4"}
                gap={3}
              >
                <Box>
                  <Heading fontWeight={600} size={"md"}>
                    Transcription
                  </Heading>
                  <Text color={"gray"} fontSize={12}>
                    This section allows you to configure the transcription
                    settings for the asssitant
                  </Text>
                </Box>
                <Divider />
                <Grid gap={4} gridTemplateColumns={"repeat(3, 1fr)"}>
                  <FormControl>
                    <FormLabel fontWeight={600}>Provider</FormLabel>
                    <Field
                      as={Select}
                      value={{
                        label: values.provider,
                        value: values.provider,
                      }}
                      placeholder={"Select transcription provider"}
                      selectedOptionColorScheme="primary"
                      size={"sm"}
                      onChange={(v: any) => {
                        setFieldValue("provider", v?.value);
                        setIsDirty(true);
                      }}
                      variant={"outline"}
                      chakraStyles={dropdownStyles}
                      components={dropdownComponents}
                      options={[
                        {
                          label: "deepgram",
                          value: "deepgram",
                        },
                      ]}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontWeight={600}>Language</FormLabel>
                    <Field
                      as={Select}
                      value={{
                        label: values.language,
                        value: values.language,
                      }}
                      placeholder={"Select transcription language"}
                      selectedOptionColorScheme="primary"
                      size={"sm"}
                      onChange={(v: any) => {
                        setFieldValue("language", v?.value);
                        setIsDirty(true);
                      }}
                      variant={"outline"}
                      chakraStyles={dropdownStyles}
                      components={dropdownComponents}
                      options={[
                        { value: "da", label: "da" },
                        { value: "en", label: "en" },
                        { value: "en-AU", label: "en-AU" },
                        { value: "en-GB", label: "en-GB" },
                        { value: "en-IN", label: "en-IN" },
                        { value: "en-NZ", label: "en-NZ" },
                        { value: "en-US", label: "en-US" },
                        { value: "es", label: "es" },
                        { value: "es-419", label: "es-419" },
                        { value: "fr", label: "fr" },
                        { value: "fr-CA", label: "fr-CA" },
                        { value: "hi", label: "hi" },
                        { value: "hi-Latn", label: "hi-Latn" },
                        { value: "id", label: "id" },
                        { value: "it", label: "it" },
                        { value: "ja", label: "ja" },
                        { value: "ko", label: "ko" },
                        { value: "nl", label: "nl" },
                        { value: "pl", label: "pl" },
                        { value: "pt", label: "pt" },
                        { value: "pt-BR", label: "pt-BR" },
                        { value: "pt-PT", label: "pt-PT" },
                        { value: "ru", label: "ru" },
                        { value: "sv", label: "sv" },
                        { value: "tr", label: "tr" },
                        { value: "uk", label: "uk" },
                        { value: "zh-CN", label: "zh-CN" },
                        { value: "zh-TW", label: "zh-TW" },
                      ]}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontWeight={600}>Model</FormLabel>
                    <Field
                      as={Select}
                      value={{
                        label: values.model,
                        value: values.model,
                      }}
                      onChange={(v: any) => {
                        setFieldValue("model", v?.value);
                        setIsDirty(true);
                      }}
                      placeholder={"Select trascription model"}
                      selectedOptionColorScheme="primary"
                      size={"sm"}
                      variant={"outline"}
                      chakraStyles={dropdownStyles}
                      components={dropdownStyles}
                      options={[
                        { value: "nova-2-general", label: "nova-2-general" },
                        { value: "nova-2-meeting", label: "nova-2-meeting" },
                        {
                          value: "nova-2-phonecall",
                          label: "nova-2-phonecall",
                        },
                        {
                          value: "nova-2-voicemail",
                          label: "nova-2-voicemail",
                        },
                        { value: "nova-2-finance", label: "nova-2-finance" },
                        {
                          value: "nova-2-conversationalai",
                          label: "nova-2-conversationalai",
                        },
                        { value: "nova-2-video", label: "nova-2-video" },
                        { value: "nova-2-medical", label: "nova-2-medical" },
                        {
                          value: "nova-2-drivethru",
                          label: "nova-2-drivethru",
                        },
                        {
                          value: "nova-2-automotive",
                          label: "nova-2-automotive",
                        },
                        { value: "whisper", label: "whisper" },
                      ]}
                    />
                  </FormControl>
                </Grid>
                  <Stack>
                    <Flex py={3} alignItems={"center"} gap={4}>
                      <LanguagesIcon />
                      <Box flex={1}>
                        <Text fontWeight={600}>Multilingual</Text>
                        <Text fontSize={12}>
                        Allow agent to auto detect user speaking language and respond in the same language.
                        </Text>
                      </Box>
                      <Switch
                        isChecked={values.multilingual}
                        onChange={(v) => {
                          setFieldValue(
                            "multilingual",
                            v.target.checked
                          );
                          setIsDirty(true);
                        }}
                      />
                    </Flex>
                    <Flex py={3} alignItems={"center"} gap={4}>
                      <ArrowLeftRightIcon />
                      <Box flex={1}>
                        <Text fontWeight={600}>Language Switching</Text>
                        <Text fontSize={12}>
                        Allow agent to switch language during conversation when user asks for it.
                        </Text>
                      </Box>
                      <Switch
                        isChecked={values.language_switching}
                        onChange={(v) => {
                          setFieldValue(
                            "language_switching",
                            v.target.checked
                          );
                          setIsDirty(true);
                        }}
                      />
                    </Flex>
                    <Grid gap={4} gridTemplateColumns={'repeat(2, 1fr)'}>
                      <FormControl>
                        <FormLabel fontWeight={600}>Secondary Language</FormLabel>
                        <Field
                          as={Select}
                          value={values.secondary_language}
                          placeholder={"Select secondary language for agent"}
                          selectedOptionColorScheme="primary"
                          size={"sm"}
                          isMulti
                          onChange={(v: any) => {
                            setFieldValue("secondary_language", v);
                            setIsDirty(true);
                          }}
                          variant={"outline"}
                          chakraStyles={dropdownStyles}
                          components={dropdownComponents}
                          options={[
                            {
                                "label": "Afrikaans",
                                "value": "af"
                            },
                            {
                                "label": "Albanian",
                                "value": "sq"
                            },
                            {
                                "label": "Arabic",
                                "value": "ar"
                            },
                            {
                                "label": "Armenian",
                                "value": "hy"
                            },
                            {
                                "label": "Azerbaijani",
                                "value": "az"
                            },
                            {
                                "label": "Belarusian",
                                "value": "be"
                            },
                            {
                                "label": "Bengali",
                                "value": "bn"
                            },
                            {
                                "label": "Bosnian",
                                "value": "bs"
                            },
                            {
                                "label": "Bulgarian",
                                "value": "bg"
                            },
                            {
                                "label": "Catalan",
                                "value": "ca"
                            },
                            {
                                "label": "Chinese (Mandarin, Simplified)",
                                "value": "zh"
                            },
                            {
                                "label": "Chinese (Mandarin, Traditional)",
                                "value": "zh-TW"
                            },
                            {
                                "label": "Croatian",
                                "value": "hr"
                            },
                            {
                                "label": "Czech",
                                "value": "cs"
                            },
                            {
                                "label": "Danish",
                                "value": "da"
                            },
                            {
                                "label": "Dutch",
                                "value": "nl"
                            },
                            {
                                "label": "English",
                                "value": "en"
                            },
                            {
                                "label": "English (Australia)",
                                "value": "en-AU"
                            },
                            {
                                "label": "English (India)",
                                "value": "en-IN"
                            },
                            {
                                "label": "English (New Zealand)",
                                "value": "en-NZ"
                            },
                            {
                                "label": "English (UK)",
                                "value": "en-GB"
                            },
                            {
                                "label": "English (US)",
                                "value": "en-US"
                            },
                            {
                                "label": "English/Spanish mix",
                                "value": "multi"
                            },
                            {
                                "label": "Estonian",
                                "value": "et"
                            },
                            {
                                "label": "Finnish",
                                "value": "fi"
                            },
                            {
                                "label": "French",
                                "value": "fr"
                            },
                            {
                                "label": "French (Canada)",
                                "value": "fr-CA"
                            },
                            {
                                "label": "Galician",
                                "value": "gl"
                            },
                            {
                                "label": "Georgian",
                                "value": "ka"
                            },
                            {
                                "label": "German",
                                "value": "de"
                            },
                            {
                                "label": "German (Switzerland)",
                                "value": "de-CH"
                            },
                            {
                                "label": "Greek",
                                "value": "el"
                            },
                            {
                                "label": "Gujarati",
                                "value": "gu"
                            },
                            {
                                "label": "Haitian Creole",
                                "value": "ht"
                            },
                            {
                                "label": "Hausa",
                                "value": "ha"
                            },
                            {
                                "label": "Hebrew",
                                "value": "he"
                            },
                            {
                                "label": "Hindi",
                                "value": "hi"
                            },
                            {
                                "label": "Hungarian",
                                "value": "hu"
                            },
                            {
                                "label": "Icelandic",
                                "value": "is"
                            },
                            {
                                "label": "Indonesian",
                                "value": "id"
                            },
                            {
                                "label": "Italian",
                                "value": "it"
                            },
                            {
                                "label": "Japanese",
                                "value": "ja"
                            },
                            {
                                "label": "Javanese",
                                "value": "jv"
                            },
                            {
                                "label": "Kannada",
                                "value": "kn"
                            },
                            {
                                "label": "Kazakh",
                                "value": "kk"
                            },
                            {
                                "label": "Khmer",
                                "value": "km"
                            },
                            {
                                "label": "Korean",
                                "value": "ko"
                            },
                            {
                                "label": "Latvian",
                                "value": "lv"
                            },
                            {
                                "label": "Lithuanian",
                                "value": "lt"
                            },
                            {
                                "label": "Macedonian",
                                "value": "mk"
                            },
                            {
                                "label": "Malay",
                                "value": "ms"
                            },
                            {
                                "label": "Maori",
                                "value": "mi"
                            },
                            {
                                "label": "Marathi",
                                "value": "mr"
                            },
                            {
                                "label": "Nepali",
                                "value": "ne"
                            },
                            {
                                "label": "Norwegian",
                                "value": "no"
                            },
                            {
                                "label": "Persian",
                                "value": "fa"
                            },
                            {
                                "label": "Polish",
                                "value": "pl"
                            },
                            {
                                "label": "Portuguese",
                                "value": "pt"
                            },
                            {
                                "label": "Portuguese (Brazil)",
                                "value": "pt-BR"
                            },
                            {
                                "label": "Punjabi",
                                "value": "pa"
                            },
                            {
                                "label": "Romanian",
                                "value": "ro"
                            },
                            {
                                "label": "Russian",
                                "value": "ru"
                            },
                            {
                                "label": "Serbian",
                                "value": "sr"
                            },
                            {
                                "label": "Shona",
                                "value": "sn"
                            },
                            {
                                "label": "Slovak",
                                "value": "sk"
                            },
                            {
                                "label": "Slovenian",
                                "value": "sl"
                            },
                            {
                                "label": "Somali",
                                "value": "so"
                            },
                            {
                                "label": "Spanish",
                                "value": "es"
                            },
                            {
                                "label": "Spanish (Latin America)",
                                "value": "es-419"
                            },
                            {
                                "label": "Sundanese",
                                "value": "su"
                            },
                            {
                                "label": "Swahili",
                                "value": "sw"
                            },
                            {
                                "label": "Swedish",
                                "value": "sv"
                            },
                            {
                                "label": "Tagalog",
                                "value": "tl"
                            },
                            {
                                "label": "Tajik",
                                "value": "tg"
                            },
                            {
                                "label": "Tamil",
                                "value": "ta"
                            },
                            {
                                "label": "Telugu",
                                "value": "te"
                            },
                            {
                                "label": "Thai",
                                "value": "th"
                            },
                            {
                                "label": "Thai",
                                "value": "th-TH"
                            },
                            {
                                "label": "Tswana",
                                "value": "tn"
                            },
                            {
                                "label": "Turkish",
                                "value": "tr"
                            },
                            {
                                "label": "Ukrainian",
                                "value": "uk"
                            },
                            {
                                "label": "Urdu",
                                "value": "ur"
                            },
                            {
                                "label": "Vietnamese",
                                "value": "vi"
                            },
                            {
                                "label": "Welsh",
                                "value": "cy"
                            }
                        ]}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel fontWeight={600}>Custom Vocabulary</FormLabel>
                        <Field
                          as={CreatableSelect}
                          isMulti={true}
                          value={values.custom_vocabulary}
                          placeholder={"Choose custom vocabulary"}
                          selectedOptionColorScheme="primary"
                          size={"sm"}
                          onChange={(v: any) => {
                            setFieldValue("custom_vocabulary", v);
                            setIsDirty(true);
                          }}
                          variant={"outline"}
                          chakraStyles={dropdownStyles}
                          components={dropdownComponents}
                        />
                      </FormControl>
                    </Grid>
                  </Stack>
              </Stack>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
}

function AudioV2({
  url,
  onInteraction,
}: {
  url: string;
  onInteraction: (
    state: "playing" | "paused",
    ref?: RefObject<HTMLAudioElement> | null
  ) => void;
}) {
  const [duration, setDuration] = useState(0);
  const [track, setTrack] = useState(0);
  const [isPaused, setPaused] = useState(true);
  const ref = useRef<HTMLAudioElement>(null);

  return (
    <Flex alignItems={"center"} gap={3}>
      <audio
        onEnded={() => {
          onInteraction("paused", ref);
          setTrack(0);
          setPaused(true);
        }}
        onTimeUpdate={(e) => {
          setTrack(Math.ceil(e.currentTarget.currentTime));
        }}
        hidden
        ref={ref}
        src={url}
        onLoadedMetadata={(e) => {
          setDuration(Math.ceil(ref.current?.duration || 0));
        }}
      />
      <CircularProgress
        max={duration}
        
        value={track}
        color="var(--chakra-colors-primary-500)"
        size={12}
      >
        <CircularProgressLabel mx={"auto"}>
          {isPaused ? (
            <PlayIcon
              size={20}
              style={{ marginLeft: "auto", marginRight: "auto" }}
              cursor={"pointer"}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                if (ref.current?.paused) {
                  ref.current?.play();
                  setPaused(false);
                } else {
                  ref.current?.pause();
                  setPaused(true);
                }
              }}
            />
          ) : (
            <PauseIcon
              color="var(--chakra-colors-primary-500)"
              size={20}
              style={{ marginLeft: "auto", marginRight: "auto" }}
              cursor={"pointer"}
              onClick={() => {
                if (ref.current?.paused) {
                  ref.current?.play();
                  setPaused(false);
                } else {
                  ref.current?.pause();
                  setPaused(true);
                }
              }}
            />
          )}
        </CircularProgressLabel>
      </CircularProgress>
      {/* <Slider max={duration} value={track} min={0} colorScheme="primary" defaultValue={0}>
                <SliderTrack>
                    <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
            </Slider> */}
    </Flex>
  );
}

function VoiceConfig(props: ConfigProps) {
  const modelOptions = [
    {
      name: "Eleven Multilingual v2",
      id: "eleven_multilingual_v2",
      description:
        "Our state of the art multilingual speech synthesis model, able to generate life-like speech in 29 languages.",
      can_be_finetuned: true,
      can_use_speaker_boost: true,
      can_use_style: true,
      can_do_voice_conversion: false,
    },
    {
      name: "Eleven English v1",
      id: "eleven_monolingual_v1",
      description:
        "Use our standard English language model to generate speech in a variety of voices, styles and moods.",
      can_be_finetuned: true,
      can_use_speaker_boost: false,
      can_use_style: false,
      can_do_voice_conversion: false,
    },
    {
      name: "Eleven Turbo v2",
      id: "eleven_turbo_v2",
      description:
        "Our cutting-edge turbo model is ideally suited for tasks demanding extremely low latency.",
      can_be_finetuned: false,
      can_use_speaker_boost: false,
      can_use_style: false,
      can_do_voice_conversion: false,
    },
    {
      name: "Eleven Turbo v2.5",
      id: "eleven_turbo_v2_5",
      description:
        "Experimental",
      can_be_finetuned: false,
      can_use_speaker_boost: false,
      can_use_style: false,
      can_do_voice_conversion: false,
    }
  ].map((v) => ({ label: v.name, value: v.id }));
  const [isDirty, setIsDirty] = useState(false);
  const formRef = useRef<any | null>()

  return (
    <Formik
      initialValues={{
        provider: props.data.voice_config?.provider || "ELEVENLABS",
        voice: props.data.voice_config?.voice_id,
        name: props.data.voice_config?.voice_name,
        model: props.data.voice_config?.model,
        backchanneling_enabled:
          props.data.advanced_config?.backchannelingEnabled || false,
        denoising_enabled: props.data.advanced_config?.backgroundDenoisingEnabled || false,
        filter_injection_enabled:
          props.data.voice_config?.fillerInjectionEnabled || false,
        use_speaker_boost: props.data.voice_config?.useSpeakerBoost || false,
        background_noise: props.data.advanced_config?.backgroundSound || null,
        input_min_characters:
          props.data.voice_config?.inputMinCharacters || 30,
        stability: props.data.voice_config?.stability || 0.5,
        similarity: props.data.voice_config?.similarity || 0.8,
        style: props.data.voice_config?.style || 0,
        optimise_latency: props.data.voice_config?.optimizeStreamingLatency || 1,
        recommended_voices: props.data.recommended_voices || []
      }}
      onSubmit={() => {}}
    >
      {({ errors, values, setFieldValue, setFieldTouched }) => {
        return (
          <Form
            ref={formRef}
            onBlur={() => {
              const _errors = Object.keys(errors);
              if (!_errors.length && values.provider && isDirty) {
                props.onFinalSubmit({
                  voice_config: {
                    provider: values.provider || "ELEVENLABS",
                    voice_name: values.name,
                    voice_id: values.voice,
                    model: values.model,
                    fillerInjectionEnabled: values.filter_injection_enabled,
                    useSpeakerBoost: values.use_speaker_boost,
                    inputMinCharacters: values.input_min_characters,
                    stability: values.stability,
                    similarity: values.similarity,
                    optimizeStreamingLatency: values.optimise_latency,
                    style: values.style,
                  },
                  advanced_config: {
                    ...(props.data.advanced_config || {}),
                    backgroundSound: values.background_noise === null ? "off" : values.background_noise,
                    backchannelingEnabled: values.backchanneling_enabled,
                    backgroundDenoisingEnabled: values.denoising_enabled,
                  },
                  recommended_voices: values.recommended_voices || [],
                  system_prompt_variables: props.data.system_prompt_variables || []
                });
              } else {
                // @ts-ignore
                props.onFinalSubmit?.cancel();
              }
            }}
            onChange={(v) => {}}
          >
            <Stack pb={8} gap={12}>
              <Stack
                borderRadius={8}
                py={6}
                px={4}
                border={"1px solid #e4e4e4"}
                gap={3}
              >
                <Box>
                  <Heading fontWeight={600} size={"md"}>
                    Voice Configuration
                  </Heading>
                  <Text mt={1} color={"gray"} fontSize={12}>
                    Choose from the list of voices, or sync your{" "}
                    <Link color={"var(--chakra-colors-primary-500)"}>
                      voice library
                    </Link>{" "}
                    if you aren't able to find your voice in the dropdown. If
                    you are still facing any error, you can enable custom voice
                    and add a voice ID manually.
                  </Text>
                </Box>
                <Divider />
                <Grid gap={4} gridTemplateColumns={"repeat(3, 1fr)"}>
                  <FormControl>
                    <FormLabel fontWeight={600}>Provider</FormLabel>
                    <Select
                      value={{
                        label: "Eleven Labs",
                        value: "ELEVENLABS",
                      }}
                      placeholder={"Select voice provider"}
                      selectedOptionColorScheme="primary"
                      size={"sm"}
                      onChange={(v: any) => {
                        setFieldValue("provider", v?.value);
                        setIsDirty(true);
                      }}
                      variant={"outline"}
                      chakraStyles={dropdownStyles}
                      components={dropdownComponents}
                      options={[
                        {
                          label: "Eleven Labs",
                          value: "ELEVENLABS",
                        },
                      ]}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontWeight={600}>Choose Recommended Voices. (Max 10)</FormLabel>
                    <AsyncSelect
                      isMulti
                      value={values.recommended_voices || []}
                      loadOptions={(input: string) => {
                        return searchVoices(input);
                      }}
                      defaultOptions={true}
                      onChange={(v: any) => {
                        setFieldValue("recommended_voices", v)
                        setIsDirty(true)
                      }}
                      isOptionDisabled={() => values.recommended_voices?.length >= 10}
                      placeholder={"Select recommended voices"}
                      selectedOptionColorScheme="primary"
                      size={"sm"}
                      variant={"outline"}
                      chakraStyles={dropdownStyles}
                      components={dropdownComponents}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontWeight={600}>Model</FormLabel>
                    <Select
                      value={modelOptions.filter(
                        (m: any) => m.value === values.model
                      )}
                      placeholder={"Select voice model"}
                      selectedOptionColorScheme="primary"
                      size={"sm"}
                      variant={"outline"}
                      onChange={(v: any) => {
                        setFieldValue("model", v?.value);
                        setIsDirty(true);
                      }}
                      chakraStyles={dropdownStyles}
                      components={dropdownComponents}
                      options={modelOptions}
                    />
                  </FormControl>
                </Grid>
                <Box mt={4}>
                  <Heading fontWeight={600} size={"md"}>
                      Choose agent voice
                  </Heading>
                  <Text mt={1} color={"gray"} fontSize={12}>
                    Choose agent voice from the below recommended voices. If you don't see any voices, please select the voice from the above recommended voices section
                  </Text>
                </Box>
                <Divider />
                <Grid gap={4} gridTemplateColumns={'repeat(3, 1fr)'}>
                    {values.recommended_voices.map((voice: any) => {
                        return <Flex cursor={"pointer"} onClick={async () => {
                          await setFieldValue("name", voice.name)
                          await setFieldValue("voice", voice.voice_id)
                          setIsDirty(true)
                          await setFieldTouched("name", true)
                          props.onFinalSubmit({
                            voice_config: {
                              provider: values.provider || "ELEVENLABS",
                              voice_name: voice.name,
                              voice_id: voice.voice_id,
                              model: values.model,
                              fillerInjectionEnabled: values.filter_injection_enabled,
                              useSpeakerBoost: values.use_speaker_boost,
                              inputMinCharacters: values.input_min_characters,
                              stability: values.stability,
                              similarity: values.similarity,
                              optimizeStreamingLatency: values.optimise_latency,
                              style: values.style,
                            },
                            advanced_config: {
                              ...(props.data.advanced_config || {}),
                              backgroundSound: values.background_noise === null ? "off" : values.background_noise,
                              backchannelingEnabled: values.backchanneling_enabled,
                              backgroundDenoisingEnabled: values.denoising_enabled,
                            },
                            recommended_voices: values.recommended_voices || [],
                            system_prompt_variables: props.data.system_prompt_variables || []
                          });
                        }} justifyContent={"space-between"} borderRadius={8} p={4} border={"1px solid var(--chakra-colors-gray-200)"} w={"full"}>
                            <Box>
                                  <Flex mb={voice.id !== voice ? 2 : 1}>
                                    <Text fontWeight={600}>{voice.name}</Text>
                                    {voice.voice_id === values.voice ? <Tag colorScheme="green" ml={2}>
                                      <TagLeftIcon as={VerifiedIcon} />
                                      <TagLabel>selected</TagLabel>
                                    </Tag> : <></>}
                                  </Flex>
                                  <Text color={"gray"} fontSize={"small"}>{voice.voice_id}</Text>
                                  <Text color={"gray"} fontSize={"small"}>{`${_.capitalize(voice.gender)} | ${_.capitalize(voice.accent)}`}</Text>
                                </Box>
                            <AudioV2 onInteraction={(state, ref) => {
                                if (state === "playing") {
                                    // currentRef. = ref?.current
                                }
                            }} url={voice.sample_url}  />
                        </Flex>
                    })}
                </Grid>
              </Stack>

              <Stack
                borderRadius={8}
                py={6}
                px={4}
                border={"1px solid #e4e4e4"}
                gap={3}
              >
                <Box>
                  <Heading fontWeight={600} size={"md"}>
                    Additional Configuration
                  </Heading>
                  <Text color={"gray"} fontSize={12}>
                    Configure additional settings for the voice of your
                    assistant.
                  </Text>
                </Box>
                <Divider />
                <Grid gap={4} gridTemplateColumns={"repeat(2, 1fr)"}>
                  <FormControl>
                    <FormLabel
                      display={"flex"}
                      gap={1}
                      alignItems={"center"}
                      fontWeight={600}
                    >
                      Background Sound{" "}
                      <Tooltip label="This is the background sound in the call. Default for phone calls is 'office' and default for web calls is 'off'.">
                        <InfoIcon size={12} />
                      </Tooltip>
                    </FormLabel>
                    <Select
                      value={{
                        label: _.capitalize(values.background_noise),
                        value: values.background_noise,
                      }}
                      placeholder={"Select background sound"}
                      selectedOptionColorScheme="primary"
                      size={"sm"}
                      onChange={(v: any) => {
                        setFieldValue?.("background_noise", v.value);
                        setIsDirty(true);
                      }}
                      variant={"outline"}
                      chakraStyles={dropdownStyles}
                      components={dropdownComponents}
                      options={[
                        {
                          label: "Office",
                          value: "office",
                        },
                        {
                          label: "Off",
                          value: "off",
                        },
                      ]}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      display={"flex"}
                      gap={1}
                      alignItems={"center"}
                      fontWeight={600}
                    >
                      Input Min Characters{" "}
                      <Tooltip label="This is the minimum number of characters that will be passed to the voice provider. This helps decides the minimum chunk size that is sent to the voice provider for the voice generation as the LLM tokens are streaming in.">
                        <InfoIcon size={12} />
                      </Tooltip>
                    </FormLabel>
                    <Field
                      value={values.input_min_characters}
                      as={Input}
                      onChange={(v: any) => {
                        setFieldValue?.("input_min_characters", v.target.value);
                        setIsDirty(true);
                      }}
                      name={"input_min_characters"}
                      borderRadius={8}
                      size={"sm"}
                      placeholder={"10"}
                    />
                  </FormControl>
                </Grid>
                <Stack>
                  <Flex py={3} alignItems={"center"} gap={4}>
                    <SyringeIcon />
                    <Box flex={1}>
                      <Text fontWeight={600}>Filler Injection Enabled</Text>
                      <Text fontSize={12}>
                        This determines whether fillers are injected into the
                        Model output before inputting it into the Voice
                        provider.
                      </Text>
                    </Box>
                    <Switch
                      isChecked={values.filter_injection_enabled}
                      onChange={(v) => {
                        setFieldValue(
                          "filter_injection_enabled",
                          v.target.checked
                        );
                        setIsDirty(true);
                      }}
                    />
                  </Flex>
                  <Flex py={3} alignItems={"center"} gap={4}>
                    <MessagesSquareIcon />
                    <Box flex={1}>
                      <Text fontWeight={600}>Backchanneling Enabled</Text>
                      <Text fontSize={12}>
                        Make the bot say words like 'mhmm', 'ya' etc. while
                        listening to make the conversation sounds natural.
                        Default disabled
                      </Text>
                    </Box>
                    <Switch
                      isChecked={values.backchanneling_enabled}
                      onChange={(v) => {
                        setFieldValue(
                          "backchanneling_enabled",
                          v.target.checked
                        );
                        setIsDirty(true);
                      }}
                    />
                  </Flex>
                  <Flex py={3} alignItems={"center"} gap={4}>
                    <Volume1Icon />
                    <Box flex={1}>
                      <Text fontWeight={600}>Background Denoising Enabled</Text>
                      <Text fontSize={12}>
                        Filter background noise while the user is talking.
                      </Text>
                    </Box>
                    <Switch
                      isChecked={values.denoising_enabled}
                      onChange={(v) => {
                        setFieldValue("denoising_enabled", v.target.checked);
                        setIsDirty(true);
                      }}
                    />
                  </Flex>
                </Stack>
                <Flex py={3} alignItems={"center"} gap={4}>
                  <Box flex={1}>
                    <Text fontWeight={600}>Use Speaker Boost</Text>
                    <Text fontSize={12}>
                      Boost the similarity of the synthesized speech and the
                      voice at the cost of some generation speed.
                    </Text>
                  </Box>
                  <Switch
                    isChecked={values.use_speaker_boost}
                    onChange={(v) => {
                      setFieldValue("use_speaker_boost", v.target.checked);
                      setIsDirty(true);
                    }}
                  />
                </Flex>
                <Stack gap={8}>
                  <CustomSlider
                    label="Stability"
                    value={values.stability}
                    min={0}
                    max={1}
                    info_texts={["More Variable", "More Stable"]}
                    step={0.1}
                    onChange={(v: any) => {
                      setIsDirty(true);
                      setFieldValue("stability", v);
                    }}
                  />
                  <CustomSlider
                    label="Clarity + Similarity"
                    value={values.similarity}
                    min={0}
                    max={1}
                    info_texts={["Low", "High"]}
                    step={0.05}
                    onChange={(v: any) => {
                      setIsDirty(true);
                      setFieldValue("similarity", v);
                    }}
                  />
                  <CustomSlider
                    step={0.1}
                    min={0}
                    max={1}
                    info_texts={["Low", "Exaggerated"]}
                    label={"Style Exaggeration"}
                    value={values.style}
                    onChange={(v: any) => {
                      setIsDirty(true);
                      setFieldValue("style", v);
                    }}
                  />
                  <CustomSlider
                    step={1}
                    min={1}
                    max={4}
                    info_texts={["More Latency", "Less Latency"]}
                    label={"Optimize Streaming Latency"}
                    value={values.optimise_latency}
                    onChange={(v: any) => {
                      setIsDirty(true);
                      setFieldValue("optimise_latency", v);
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
}

function FunctionsConfig(props: ConfigProps) {
  return <Text></Text>;
}

function PhoneConfig(props: ConfigProps) {
  const [isDirty, setIsDirty] = useState(false);
  const [phoneState, setPhoneState] = useState("loading");
  const [phoneOptions, setPhoneOptions] = useState([]);

  useEffect(() => {
    (async () => {
      if (props.data.organization_id) {
        const numbers = await getPhoneNumbers(props.data.organization_id)
        setPhoneOptions(numbers)
        setPhoneState("success")
      } else {
        setPhoneOptions([])
        setPhoneState("success")
      }
    })()
  }, [props.data.organization_id])

  return (
    <Formik
      initialValues={{
        phone_number_id: props.data.advanced_config?.phone_number_id,
        phone_number: props.data.advanced_config?.phone_number,
      }}
      onSubmit={() => {}}
    >
      {({ errors, values, setFieldValue }) => (
        <Form
          onBlur={() => {
            const _errors = Object.keys(errors);
            if (!_errors.length && isDirty) {
              props.onFinalSubmit({
                advanced_config: {
                  ...(props.data.advanced_config || {}),
                  phone_number: values.phone_number,
                  phone_number_id: values.phone_number_id
                }
              });
            } else {
              // @ts-ignore
              props.onFinalSubmit?.cancel();
            }
          }}
        >
          <Stack
            borderRadius={8}
            py={6}
            px={4}
            border={"1px solid #e4e4e4"}
            gap={3}
          >
            <Box>
              <Heading fontWeight={600} size={"md"}>
                Phone Configuration
              </Heading>
              <Text color={"gray"} fontSize={12}>
                Choose phone number associated with the agent
              </Text>
            </Box>
            <Divider />
            <FormControl>
              <FormLabel fontWeight={600}>Choose Phone Number</FormLabel>
              <Select
                value={{
                  label: values.phone_number || '',
                  value: values.phone_number_id || '',
                }}
                options={phoneOptions}
                onChange={async (v: any) => {
                  if (v?.value) {
                    await attachPhoneNumber(props.data.organization_id, props.data.provider_id, v?.value || '')
                  } else {
                    await detachPhoneNumber(props.data.organization_id, values.phone_number_id)
                  }
                  setFieldValue("phone_number", v?.label);
                  setFieldValue("phone_number_id", v?.value);
                  setIsDirty(true);
                }}
                isClearable
                placeholder={"Select phone number"}
                selectedOptionColorScheme="primary"
                size={"sm"}
                variant={"outline"}
                chakraStyles={dropdownStyles}
                components={dropdownComponents}
              />
            </FormControl>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

function AdvancedConfig(props: ConfigProps) {
  const [isDirty, setIsDirty] = useState(false);
  const successEvaluationOptions = [
    {
      "rubricType": "AutomaticRubric",
      "description": "Automatically evaluates submissions based on predefined criteria and metrics."
    },
    {
      "rubricType": "NumericScale",
      "description": "Evaluates submissions using a numerical scale, typically from 1 to 10 or 1 to 5."
    },
    {
      "rubricType": "PercentageScale",
      "description": "Evaluates submissions by assigning a percentage score."
    },
    {
      "rubricType": "PassFail",
      "description": "Evaluates submissions with a simple pass or fail outcome."
    },
    {
      "rubricType": "Checklist",
      "description": "Evaluates submissions based on a checklist of required criteria."
    },
    {
      "rubricType": "DescriptiveScale",
      "description": "Provides detailed, qualitative feedback and evaluation based on descriptive criteria."
    },
    {
      "rubricType": "LikertScale",
      "description": "Evaluates submissions using traditional letter grades Strongly Agree, Agree, Neutral, Disagree, Strongly Disagree"
    },
    {
      "rubricType": "Matrix",
      "description": "Evaluates submissions using a matrix that combines multiple criteria and levels of achievement."
    }
  ]
  return (
    <Formik
      initialValues={{
        hipaa_enabled: props.data.advanced_config?.hipaaEnabled,
        recording_enabled: props.data.advanced_config?.recordingEnabled,
        silence_timeout: props.data.advanced_config?.silence_timeout || 30,
        response_delay: props.data.advanced_config?.responseDelaySeconds || 1,
        llm_request_delay: props.data.advanced_config?.llmRequestDelaySeconds || 0.1,
        interruption_threshold:
          props.data.advanced_config?.numWordsToInterruptAssistant || 0,
        maximum_call_duration:
          props.data.advanced_config?.maxDurationSeconds || 1800,
        server_url: props.data.advanced_config?.server_url,
        client_messages: props.data.advanced_config?.clientMessages || ["transcript", "hang", "function-call", "conversation-update", "metadata", "speech-update"],
        server_messages: props.data.advanced_config?.serverMessages || ["end-of-call-report", "hang"],
        voicemail_message: props.data.advanced_config?.voicemailMessage,
        endcall_message: props.data.advanced_config?.endCallMessage,
        forwarding_number: props.data.advanced_config?.forwardingPhoneNumber,
        greeting_message: props.data.advanced_config?.firstMessage,
        end_call_phrases: (props.data.advanced_config?.endCallPhrases || []).join(", "),
        enable_end_call_function: props.data.advanced_config?.endCallFunctionEnabled,
        success_evaluation_rubrik: props.data.advanced_config?.analysisPlan?.successEvaluationRubric,
        summary_prompt: props.data.advanced_config?.analysisPlan?.summaryPrompt,
        success_evaluation_prompt: props.data.advanced_config?.analysisPlan?.successEvaluationPrompt,
        structured_data_prompt: props.data.advanced_config?.analysisPlan?.structuredDataPrompt,
        structured_data_schema: props.data.advanced_config?.analysisPlan?.structuredDataSchema || {}
      }}
      onSubmit={() => {}}
    >
      {({ errors, values, setFieldValue }) => (
        <Form
          onBlur={() => {
            const _errors = Object.keys(errors);
            if (!_errors.length && isDirty) {
              props.onFinalSubmit({
                advanced_config: {
                  ...(props.data.advanced_config || {}),
                  analysisPlan: {
                    ...(props.data.advanced_config?.analysisPlan || {}),
                    successEvaluationRubric: values.success_evaluation_rubrik,
                    summaryPrompt: values.summary_prompt,
                    successEvaluationPrompt: values.success_evaluation_prompt,
                    structuredDataPrompt: values.structured_data_prompt,
                    structuredDataSchema: values.structured_data_schema ? typeof(values.structured_data_schema) === "string" ? JSON.parse(values.structured_data_schema) : values.structured_data_schema : {
                      "type": "object",
                      "properties": {
                          "words": {
                              "type": "array",
                              "description": "Top keywords spoke by the user. You need to only extract words that are related to hotel booking or hospitality services. Ignore dates, email addresses, phone numbers, names and filler words",
                              "items": {
                                  "type": "string"
                              }
                          },
                          "questions": {
                              "type": "array",
                              "description": "Top questions spoken by the user. You are only allowed to extract hotel booking or hospitality related questions",
                              "items": {
                                  "type": "string"
                              }
                          },
                          "score": {
                              "type": "number",
                              "description": "Based on the success_metrics features below, agent_performance and customer_reaction, score yourself on the scale of 0 to 10"
                          },
                          "success_metrics": {
                            "type": "object",
                            "properties": {
                                "warm_greeting": {
                                    "type": "boolean",
                                    "description": "Did you greet the caller warmly and ask for their full name?"
                                },
                                "engagement": {
                                    "type": "boolean",
                                    "description": "Did you engage in small talk to make the caller feel comfortable?"
                                },
                                "understanding_needs": {
                                    "type": "boolean",
                                    "description": "Did you listen carefully to the caller’s request and respond accordingly?"
                                },
                                "detailed_follow_up": {
                                    "type": "boolean",
                                    "description": "Did you ask follow-up questions based on the caller’s request?"
                                },
                                "date_and_time": {
                                    "type": "boolean",
                                    "description": "Did you ask for and confirm the preferred dates and times for the booking?"
                                },
                                "confirmation": {
                                    "type": "boolean",
                                    "description": "Did you confirm all details with the caller?"
                                },
                                "package_details": {
                                    "type": "boolean",
                                    "description": "Did you inform the caller about sending package details via SMS or WhatsApp and confirm the preferred method?"
                                },
                                "corporate_booking": {
                                    "type": "boolean",
                                    "description": "For corporate inquiries, did you offer to set up a call with the corporate team or arrange a hotel visit if requested?"
                                },
                                "politeness_efficiency": {
                                    "type": "boolean",
                                    "description": "Were you polite, friendly, and efficient throughout the call?"
                                }
                            }
                        },
                        "customer_requests": {
                          "type": "array",
                          "items": {
                            "type": "string"
                          },
                          "description": "List the primary requests or concerns of the customer"
                        },
                        "next_steps": {
                          "type": "array",
                          "items": {
                            "type": "string"
                          },
                          "description": "Any follow-up actions needed from your side or the customer's side"
                        },
                        "agent_performance": {
                          "type": "string",
                          "description": "List the primary requests or concerns of the customer"
                        },
                        "customer_reaction": {
                          "type": "string",
                          "description": "How did the customer react to the information and assistance provided?"
                        },
                        "call_summary": {
                          "type": "string",
                          "description": "A brief overview of the conversation"
                        }
                      }
                  }
                  },
                  hipaaEnabled: values.hipaa_enabled,
                  recordingEnabled: values.recording_enabled,
                  silence_timeout: values.silence_timeout,
                  responseDelaySeconds: Number.parseInt(`${values.response_delay || 0}`, 10),
                  llmRequestDelaySeconds: values.llm_request_delay,
                  numWordsToInterruptAssistant: values.interruption_threshold,
                  maxDurationSeconds: values.maximum_call_duration,
                  server_url: values.server_url,
                  firstMessage: values.greeting_message,
                  clientMessages: values.client_messages,
                  serverMessages: values.server_messages,
                  voicemailMessage: values.voicemail_message,
                  endCallMessage: values.endcall_message,
                  forwardingPhoneNumber: values.forwarding_number?.length || null,
                  endCallPhrases: values.end_call_phrases?.length ? (values.end_call_phrases || '').split(",").map((s: any) => s.trim()) : [],
                },
              });
            } else {
              // @ts-ignore
              props.onFinalSubmit?.cancel();
            }
          }}
        >
          <Stack pb={8} gap={12}>
            <Stack
              borderRadius={8}
              py={4}
              px={4}
              border={"1px solid #e4e4e4"}
              gap={1}
            >
              <Box>
                <Heading fontWeight={600} size={"md"}>
                  Privacy
                </Heading>
                <Text mt={1} color={"gray"} fontSize={12}>
                  This section allows you to configure the privacy settings for
                  the assistant. 
                </Text>
              </Box>
              <Divider />
              <Flex py={1} alignItems={"center"} gap={4}>
                <ShieldIcon />
                <Box flex={1}>
                  <Text
                    display={"flex"}
                    alignItems={"center"}
                    gap={2}
                    fontWeight={600}
                  >
                    HIPAA Compliance{" "}
                    <Tooltip
                      hasArrow
                      rounded={8}
                      p={2}
                      py={1}
                      bg={"#343434"}
                      label={
                        "HIPAA Compliance is enforced at the organization level and overrides individual settings."
                      }
                    >
                      <InfoIcon cursor={"pointer"} size={12} />
                    </Tooltip>
                  </Text>
                  <Text fontSize={12}>
                    When this is enabled, no logs, recordings, or transcriptions
                    will be stored.
                  </Text>
                </Box>
                <Switch
                  isChecked={values.hipaa_enabled}
                  onChange={(v) => {
                    setFieldValue("hipaa_enabled", v.target.checked);
                    setIsDirty(true);
                  }}
                />
              </Flex>
              <Flex py={1} alignItems={"center"} gap={4}>
                <MicIcon />
                <Box flex={1}>
                  <Text fontWeight={600}>Recording Enabled </Text>
                  <Text fontSize={12}>
                    Record the conversation with the assistant.
                  </Text>
                </Box>
                <Switch
                  isChecked={values.recording_enabled}
                  onChange={(v) => {
                    setFieldValue("recording_enabled", v.target.checked);
                    setIsDirty(true);
                  }}
                />
              </Flex>
              <Flex py={1} alignItems={"center"} gap={4}>
                <PhoneMissedIcon />
                <Box flex={1}>
                  <Text
                    display={"flex"}
                    alignItems={"center"}
                    gap={2}
                    fontWeight={600}
                  >
                    Enable End Call Function{" "}
                    <Tooltip
                      hasArrow
                      rounded={8}
                      p={2}
                      py={1}
                      bg={"#343434"}
                      label={
                        "HIPAA Compliance is enforced at the organization level and overrides individual settings."
                      }
                    >
                      <InfoIcon cursor={"pointer"} size={12} />
                    </Tooltip>
                  </Text>
                  <Text fontSize={12}>
                  This will allow the assistant to end the call from its side. (Best for gpt-4 and bigger models.)
                  </Text>
                </Box>
                <Switch
                  isChecked={values.enable_end_call_function}
                  onChange={(v) => {
                    setFieldValue("enable_end_call_function", v.target.checked);
                    setIsDirty(true);
                  }}
                />
              </Flex>
            </Stack>
            <Stack
              borderRadius={8}
              py={4}
              px={4}
              border={"1px solid #e4e4e4"}
              gap={1}
            >
              <Box>
                <Heading fontWeight={600} size={"md"}>
                  Pipeline Configuration
                </Heading>
                <Text mt={1} color={"gray"} fontSize={12}>
                  This section allows you to configure the pipeline settings for
                  the assistant.
                </Text>
              </Box>
              <Divider />
              <CustomSliderV2
                onChange={(v: any) => {
                  setIsDirty(true);
                  setFieldValue("silence_timeout", v);
                }}
                info_texts={["10 (sec)", "600 (sec)"]}
                value={values.silence_timeout}
                min={10}
                max={600}
                step={1}
                icon={<HourglassIcon />}
                title="Silence Timeout"
                subtitle="How long to wait before a call is automatically ended due to inactivity."
              />
              <CustomSliderV2
                onChange={(v: any) => {
                  setIsDirty(true);
                  setFieldValue("response_delay", v);
                }}
                info_texts={["1 (sec)", "2 (sec)"]}
                value={values.response_delay}
                min={0}
                max={2}
                step={0.1}
                icon={<HistoryIcon />}
                title="Response Delay"
                subtitle="The minimum number of seconds the assistant waits after user speech before it starts speaking."
              />
              <CustomSliderV2
                onChange={(v: any) => {
                  setIsDirty(true);
                  setFieldValue("llm_request_delay", v);
                }}
                info_texts={["0 (sec)", "3 (sec)"]}
                value={values.llm_request_delay}
                min={1}
                max={3}
                step={0.1}
                icon={<TimerResetIcon />}
                title="LLM Request Delay"
                subtitle="The minimum number of seconds to wait after punctuation before sending a request to the LLM."
              />
              <CustomSliderV2
                onChange={(v: any) => {
                  setIsDirty(true);
                  setFieldValue("interruption_threshold", v);
                }}
                info_texts={["0 (words)", "10 (words)"]}
                value={values.interruption_threshold}
                min={0}
                max={10}
                step={1}
                icon={<ALargeSmallIcon />}
                title="Interruption Threshold"
                subtitle="The number of words the user must say before the assistant considers being interrupted."
              />
              <CustomSliderV2
                onChange={(v: any) => {
                  setIsDirty(true);
                  setFieldValue("maximum_call_duration", v);
                }}
                info_texts={["10 (sec)", "3600 (sec)"]}
                value={values.maximum_call_duration}
                min={30}
                max={3600}
                step={1}
                icon={<GaugeIcon />}
                title="Maximum Duration"
                subtitle="The maximum number of seconds a call will last."
              />
            </Stack>
            <Stack
              borderRadius={8}
              py={4}
              px={4}
              border={"1px solid #e4e4e4"}
              gap={1}
            >
              <Box>
                <Heading fontWeight={600} size={"md"}>
                  Messaging
                </Heading>
                <Text mt={1} color={"gray"} fontSize={12}>
                  Message configuration for messages that are sent to and from
                  the assistant.
                </Text>
              </Box>
              <Divider />
              <Stack>
                <FormControl py={4}>
                  <FormLabel>
                    <Stack gap={0}>
                      <Text fontSize={14}>Server URL</Text>
                      <Text
                        fontStyle={"italic"}
                        color={"gray"}
                        fontSize={"small"}
                      >
                        This is the URL Vapi will use to communicate messages
                        via HTTP POST Requests. This is used for retrieving
                        context, function calling, and end-of-call reports.{" "}
                        <Text
                          as={Link}
                          href="#"
                          color={"var(--chakra-colors-primary-500)"}
                        >
                          Read More
                        </Text>
                      </Text>
                    </Stack>
                  </FormLabel>
                  <Field
                    size="sm"
                    fontSize={14}
                    rounded={6}
                    as={Input}
                    placeholder="Enter Url to handle Server messages"
                    name="server_url"
                    value={values.server_url}
                    onChange={(v: any) => {
                      setFieldValue("server_url", v.target.value);
                      setIsDirty(true);
                    }}
                  />
                </FormControl>
                <Divider />
                <FormControl py={4}>
                  <FormLabel>
                    <Stack gap={0}>
                      <Text fontSize={14}>Client Messages</Text>
                      <Text
                        fontStyle={"italic"}
                        color={"gray"}
                        fontSize={"small"}
                      >
                        These are the messages that will be sent to the Client SDKs.
                      </Text>
                    </Stack>
                  </FormLabel>
                  <Select
                      isMulti
                      selectedOptionStyle="check"
                      value={values.client_messages.map((v: string) => ({
                        label: v,
                        value: v
                      }))}
                      placeholder={"Select messages"}
                      selectedOptionColorScheme="primary"
                      size={"sm"}
                      variant={"outline"}
                      onChange={(v: any) => {
                        setFieldValue("client_messages", v.map((a: any) => a.value));
                        setIsDirty(true);
                      }}
                      chakraStyles={dropdownStyles}
                      components={dropdownComponents}
                      options={[ "conversation-update", "transcript", "hang", "function-call", "function-call-result", "model-output", "metadata", "speech-update", "status-update", "tool-calls", "tool-calls-result", "user-interrupted", "voice-input"].map(v => ({
                        label: v,
                        value: v
                      }))}
                    />
                </FormControl>
                <Divider />
                <FormControl py={4}>
                  <FormLabel>
                    <Stack gap={0}>
                      <Text fontSize={14}>Server Messages</Text>
                      <Text
                        fontStyle={"italic"}
                        color={"gray"}
                        fontSize={"small"}
                      >
                        These are the messages that will be sent to the Client SDKs.
                      </Text>
                    </Stack>
                  </FormLabel>
                  <Select
                      isMulti
                      selectedOptionStyle="check"
                      value={values.server_messages.map((v: string) => ({
                        label: v,
                        value: v
                      }))}
                      placeholder={"Select messages"}
                      selectedOptionColorScheme="primary"
                      size={"sm"}
                      variant={"outline"}
                      onChange={(v: any) => {
                        setFieldValue("server_messages", v.map((a: any) => a.value));
                        setIsDirty(true);
                      }}
                      chakraStyles={dropdownStyles}
                      components={dropdownComponents}
                      options={[ "conversation-update", "transcript", "hang", "function-call", "end-of-call-report", "function-call-result", "model-output", "phone-call-control", "speech-update", "status-update", "tool-calls", "user-interrupted", "voice-input", "transfer-destination-request"].map(v => ({
                        label: v,
                        value: v
                      }))}
                    />
                </FormControl>
                <Divider />
                <FormControl py={4}>
                    <FormLabel>
                      <Stack gap={0}>
                        <Text fontSize={14}>Success Evaluation Rubrik</Text>
                        <Text color={"gray"} fontSize={"small"}>
                        This enforces the rubric of the evaluation upon the Success Evaluation.
                        </Text>
                      </Stack>
                    </FormLabel>
                    <Select
                      value={{
                        label: values.success_evaluation_rubrik,
                        value: values.success_evaluation_rubrik,
                      }}
                      placeholder={"Select success evaluation rubrik"}
                      selectedOptionColorScheme="primary"
                      size={"sm"}
                      onChange={(v: any) => {
                        setFieldValue("success_evaluation_rubrik", v?.value);
                        setIsDirty(true);
                      }}
                      variant={"outline"}
                      chakraStyles={dropdownStyles}
                      components={{
                        DropdownIndicator: dropdownComponents.DropdownIndicator,
                        Option: ({ innerProps, isDisabled, data }) => {
                          return !isDisabled ? (
                            <Box borderBottom={"1px solid #e4e4e4"} p={2} px={4} _hover={{ backgroundColor: '#e4e4e4' }} cursor={"pointer"} {...innerProps}>
                              <Text fontSize={"sm"}>{data.label}</Text>
                              {/* // @ts-ignore */}
                              <Text fontSize={"xs"}>{(data as any).description}</Text>
                            </Box>
                          ) : null;
                        }
                      }}
                      options={successEvaluationOptions.map(s => {
                        return {
                          label: s.rubricType,
                          value: s.rubricType,
                          description: s.description
                        }
                      })}
                    />
                  </FormControl>
                  <Divider />
                <FormControl py={4}>
                  <FormLabel>
                    <Stack gap={0}>
                      <Text fontSize={14}>Voicemail Message</Text>
                      <Text color={"gray"} fontSize={"small"}>
                        This is the message that the assistant will say if the
                        call is forwarded to voicemail.
                      </Text>
                    </Stack>
                  </FormLabel>
                  <Field
                    size="sm"
                    fontSize={14}
                    rounded={6}
                    as={Input}
                    placeholder="Enter voicemail message"
                    name="voicemail_message"
                    value={values.voicemail_message}
                    onChange={(v: any) => {
                      setFieldValue("voicemail_message", v.target.value);
                      setIsDirty(true);
                    }}
                  />
                </FormControl>
                <Divider />
                <FormControl py={4}>
                  <FormLabel>
                    <Stack gap={0}>
                      <Text fontSize={14}>End Call Message</Text>
                      <Text color={"gray"} fontSize={"small"}>
                        This is the message that the assistant will say if it
                        ends the call.
                      </Text>
                    </Stack>
                  </FormLabel>
                  <Field
                    size="sm"
                    fontSize={14}
                    rounded={6}
                    as={Input}
                    placeholder="Enter end call message"
                    name="endcall_message"
                    value={values.endcall_message}
                    onChange={(v: any) => {
                      setFieldValue("endcall_message", v.target.value);
                      setIsDirty(true);
                    }}
                  />
                </FormControl>
                <Divider />
                <FormControl py={4}>
                  <FormLabel>
                    <Stack gap={0}>
                      <Text fontSize={14}>Forwarding Phone Number</Text>
                      <Text
                        fontStyle={"italic"}
                        color={"gray"}
                        fontSize={"small"}
                      >
                        This Phone number will be used to transfer call from the assistant. (Only applicable with Phone calls not on web calls)
                        <Text
                          as={Link}
                          href="#"
                          color={"var(--chakra-colors-primary-500)"}
                        >
                          Read More
                        </Text>
                      </Text>
                    </Stack>
                  </FormLabel>
                  <Field
                    size="sm"
                    fontSize={14}
                    rounded={6}
                    as={Input}
                    placeholder="Enter forwarding number"
                    name="forwarding_number"
                    value={values.forwarding_number}
                    onChange={(v: any) => {
                      setFieldValue("forwarding_number", v.target.value);
                      setIsDirty(true);
                    }}
                  />
                </FormControl>
                <Divider />
                <FormControl py={4}>
                  <FormLabel>
                    <Stack gap={0}>
                      <Text fontSize={14}>End Call Phrases</Text>
                      <Text
                        fontStyle={"italic"}
                        color={"gray"}
                        fontSize={"small"}
                      >
                        Enter phrases, separated by commas, that will trigger the bot to end the call when spoken. (End Call Phrases work well with gpt-3.5-turbo and smaller models.)
                      </Text>
                    </Stack>
                  </FormLabel>
                  <Field
                    size="sm"
                    fontSize={14}
                    rounded={6}
                    as={Input}
                    placeholder="Example: Good Bye, Disconnect the call, That's all"
                    name="end_call_phrases"
                    value={values.end_call_phrases}
                    onChange={(v: any) => {
                      setFieldValue("end_call_phrases", v.target.value);
                      setIsDirty(true);
                    }}
                  />
                </FormControl>
                <Divider />
                <FormControl py={4}>
                  <FormLabel>
                    <Stack gap={0}>
                      <Text fontSize={14}>Summary Prompt</Text>
                      <Text
                        fontStyle={"italic"}
                        color={"gray"}
                        fontSize={"small"}
                      >
                        This is the prompt that's used to summarize the call. The output is stored in call.analysis.summary. You can also find the summary in the Call Logs Page.
                      </Text>
                    </Stack>
                  </FormLabel>
                  <Field
                    size="sm"
                    fontSize={14}
                    rounded={6}
                    as={Textarea}
                    rows={8}
                    placeholder="Enter prompt"
                    name="summary_prompt"
                    value={values.summary_prompt}
                    onChange={(v: any) => {
                      setFieldValue("summary_prompt", v.target.value);
                      setIsDirty(true);
                    }}
                  />
                </FormControl>
                <Divider />
                <FormControl py={4}>
                  <FormLabel>
                    <Stack gap={0}>
                      <Text fontSize={14}>Success Evaluation Prompt</Text>
                      <Text
                        fontStyle={"italic"}
                        color={"gray"}
                        fontSize={"small"}
                      >
                        Evaluate if your call was successful. You can use Rubric standalone or in combination with Success Evaluation Prompt. If both are provided, they are concatenated into appropriate instructions.
                      </Text>
                    </Stack>
                  </FormLabel>
                  <Field
                    size="sm"
                    fontSize={14}
                    rounded={6}
                    as={Textarea}
                    rows={8}
                    placeholder="Enter prompt"
                    name="success_evaluation_prompt"
                    value={values.success_evaluation_prompt}
                    onChange={(v: any) => {
                      setFieldValue("success_evaluation_prompt", v.target.value);
                      setIsDirty(true);
                    }}
                  />
                </FormControl>
                <Divider />
                <FormControl py={4}>
                  <FormLabel>
                    <Stack gap={0}>
                      <Text fontSize={14}>Structured Data Prompt</Text>
                      <Text
                        fontStyle={"italic"}
                        color={"gray"}
                        fontSize={"small"}
                      >
                        Extract structured data from call conversation. You can use Data Schema standalone or in combination with Structured Data Prompt. If both are provided, they are concatenated into appropriate instructions.
                      </Text>
                    </Stack>
                  </FormLabel>
                  <Field
                    size="sm"
                    fontSize={14}
                    rounded={6}
                    as={Textarea}
                    rows={8}
                    placeholder="Enter prompt"
                    name="structured_data_prompt"
                    value={values.structured_data_prompt}
                    onChange={(v: any) => {
                      setFieldValue("structured_data_prompt", v.target.value);
                      setIsDirty(true);
                    }}
                  />
                </FormControl>
                <FormControl py={4}>
                <FormLabel>
                    <Stack gap={0}>
                      <Text fontSize={14}>Structured Data Schema</Text>
                      <Text
                        fontStyle={"italic"}
                        color={"gray"}
                        fontSize={"small"}
                      >
                        This defines the structure of the data to be extracted. Add various properties you want in the Structured Data Output
                      </Text>
                    </Stack>
                  </FormLabel>
                  <Field
                  as={Editor}
                  defaultValue={"//Enter your json schema"}
                height='550px'
                defaultLanguage="json"
                width = {"100%"}
                onChange={(v: string | undefined) => {
                  try {
                    console.log(v)
                    setFieldValue("structured_data_schema", v);
                  setIsDirty(true);
                  } catch (e) {}
                }}
                value={typeof(values.structured_data_schema) === "object" ? JSON.stringify(values.structured_data_schema || {}, null, "\t") : values.structured_data_schema}
                />
                {/* <Editor
                  maxWidth={'100%'}
                  data={values.structured_data_schema}
                  colors={darktheme}
                  setData={(v: any) => {
                    try {
                      setFieldValue("structured_data_schema", JSON.stringify(v));
                    setIsDirty(true);
                    } catch (e) {}
                  }}
              /> */}
                </FormControl>
              </Stack>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

function GeneralConfig(props: ConfigProps) {
  const [isDirty, setIsDirty] = useState(false);
  return (
    <Formik
      initialValues={{
        name: props.data.name
      }}
      onSubmit={() => {}}
    >
      {({ errors, values, setFieldValue }) => (
        <Form
          onBlur={() => {
            const _errors = Object.keys(errors);
            if (!_errors.length && isDirty && values.name) {
              props.onFinalSubmit({
                name: values.name
              });
            } else {
              // @ts-ignore
              props.onFinalSubmit?.cancel();
            }
          }}
        >
          <Stack pb={8} gap={12}>
            <Stack
              borderRadius={8}
              py={4}
              px={4}
              border={"1px solid #e4e4e4"}
              gap={1}
            >
              <Box>
                <Heading fontWeight={600} size={"md"}>
                  Assistant Details
                </Heading>
                <Text mt={1} color={"gray"} fontSize={12}>
                  Edit your assistant details
                </Text>
              </Box>
              <Divider />
              <Stack>
                <FormControl isInvalid={!!errors.name} py={4}>
                  <FormLabel>
                    <Stack gap={0}>
                      <Text fontSize={14}>Name</Text>
                    </Stack>
                  </FormLabel>
                  <Field
                    validate={(v: string) => {
                      let error
                      if (!v) {
                        error = "Assistant name required"
                      }
                      return error
                    }}
                    size="sm"
                    fontSize={14}
                    rounded={6}
                    as={Input}
                    placeholder="Enter assistant name"
                    name="name"
                    value={values.name}
                    onChange={(v: any) => {
                      setFieldValue("name", v.target.value);
                      setIsDirty(true);
                    }}
                  />
                </FormControl>
                <FormErrorMessage>{errors.name as string}</FormErrorMessage>
                <Divider />
              </Stack>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

function AnalysisConfig(props: ConfigProps) {
  return <Text></Text>;
}
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

export default function SpecificAssistant() {
  const { assistant: id } = useParams();
  const assistant = useAppSelector((state) => state.app.assistants.get);
  const organization = useAppSelector((state) => state.app.platform.get);
  const updatedAssistant = useAppSelector(
    (state) => state.app.assistants.update
  );
  const dispatch = useAppDispatch();
  const [tab, setCurrentTab] = useState<number>(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMuted, setIsMuted] = useState(false)
  const toast = useToast();
  const navigate = useNavigate()
  const vapi = useRef<Vapi | undefined>();
  const millis = useRef<any>();
  const onFinalSubmit = _.debounce((payload) => {
    console.group("Updating agent");
    const clone = _.cloneDeep(assistant.data);
    const merged: any = {
      ...clone,
      ...payload,
    };
    if (!merged.system_prompt_variables) {
      merged.system_prompt_variables = []
    }
    console.log(payload, merged);
    dispatch(setAgentGetSuccess(merged));
    console.groupEnd();
  }, 0);
  const [hasCallStarted, setHasCallStarted] = useState(false)
  const [isTestLoading, setTestLoading] = useState(false);
  const [time, setTime] = useState(0)
  const timer = useRef<NodeJS.Timeout | number | undefined>()
  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();
  const [isImageUploading, setImageUploading] = useState(false);
  const imageOptions = [
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/man-mid.webp?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/indian-girl.png?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/australian.webp?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/genz.webp?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/lady-u.webp?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/african-american.webp?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/woman-mature.webp?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/abhishekranjan_an_icon_for_a_female_personal_assistant_in_pop_25490071-4179-4ae9-ab8f-2943339aa058_2.png?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/abhishekranjan_round_icon_of_a_young_indian_woman_with_indian_t_ece500da-629b-4063-93f3-da4e1c2d0881.png?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/abeba.png?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/amy.png?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/anjani.png?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/candice.png?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/oya.png?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/shatru.png?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/stacy.png?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/vedant.png?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/zendaya.png?alt=media",
    "https://www.googleapis.com/storage/v1/b/dhansoo-public/o/neo.png?alt=media"
]
  const [selectedImage, setSelectedImage] = useState<string | null>(null)

  useEffect(() => {
    dispatch(loadSpecificAgent(id!));
  }, []);

  useEffect(() => {
    return () => {
      if (vapi.current) {
        vapi.current.stop()
      }
      if (millis.current) {
        millis.current.stop()
      }
      dispatch(setAgentGetReset());
      onFinalSubmit.cancel();
    };
  }, []);

  useEffect(() => {
    if (!vapi.current && organization.data?.providers?.vapi?.public_api_key && assistant.data.provider === "VAPI") {
      vapi.current = new Vapi(organization.data?.providers?.vapi?.public_api_key);
      vapi.current.on("call-end", () => {
        if (timer.current !== null) {
          clearInterval(timer.current)
        }
        setHasCallStarted(false)
      })
    } else if (!millis.current && organization.data?.providers?.millis?.public_api_key && assistant.data.provider === "MILLIS") {
      millis.current = Millis.createClient({
        publicKey: organization.data?.providers?.millis?.public_api_key
      });
      console.log(millis.current)
      millis.current.on("onclose", () => {
        if (timer.current !== null) {
          clearInterval(timer.current)
        }
        setHasCallStarted(false)
      })
    }
  }, [ organization.data, assistant.data ]);

  useEffect(() => {
    if (assistant.data.organization_id) {
      dispatch(getOrganization(assistant.data.organization_id))
    }
  }, [ assistant.data ]);

  useEffect(() => {
    if (updatedAssistant.state === "success") {
      setTimeout(() => {
        dispatch(resetAgentUpdate());
      }, 3000);
    }
  }, [updatedAssistant]);

  return (
    <Stack pos={"relative"} px={4} gap={6}>
    <Modal
      size={"md"}
        isCentered
        isOpen={isUploadOpen}
        onClose={() => {
          onUploadClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={4}>
            <Heading size={"sm"}>Update your agent's picture</Heading>
            <Tabs colorScheme="primary" variant={"enclosed"} mt={4} isLazy>
              <TabList>
                <Tab fontWeight={600}>Choose Image</Tab>
                <Tab fontWeight={600}>Upload Image</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Grid gap={4} gridTemplateColumns={'repeat(5, 1fr)'}>
                    {imageOptions.map(img => (
                      <Stack cursor={"pointer"} onClick={selectedImage !== img ? () => {
                        setSelectedImage(img)
                        onFinalSubmit({
                          profile_pic: img
                        })
                        toast({
                          title: "Image updated successfully",
                          status: "success",
                        });
                      } : undefined} pos={"relative"}>
                        {selectedImage === img ? <Tag size={"sm"} fontWeight={600} colorScheme={"green"} pos={"absolute"} right={1} bottom={1}>Selected</Tag> : <></>}
                        <Img w={"full"} src={img} />
                      </Stack>
                    ))}
                  </Grid>
                  {/* <Flex mt={4} justifyContent={"end"}>
                    <Button
                      size={"sm"}
                      colorScheme="primary"
                      isLoading={updatedAssistant.state === "loading"}
                      type="submit"
                    >
                      Update
                    </Button>
                  </Flex> */}

                </TabPanel>
                <TabPanel>
                  <Box>
                    <Formik
                      initialValues={{
                        file: null,
                      }}
                      onSubmit={(values) => {
                        setImageUploading(true);
                        dispatch(
                          uploadAgentImage(
                            organization.data.id,
                            assistant.data.id,
                            values.file,
                            async (_data) => {
                              if (_data.status === "failed") {
                                toast({
                                  title:
                                    "Image upload failed. Please try again",
                                  status: "error",
                                });
                              } else {
                                toast({
                                  title: "Image uploaded successfully",
                                  status: "success",
                                });
                                dispatch(setAgentUpdateSuccess(_data));
                              }
                              setImageUploading(false);
                              onUploadClose();
                            }
                          )
                        );
                      }}
                    >
                      {({ errors, touched, values, setFieldValue }) => {
                        return (
                          <Form
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Stack
                              rounded={10}
                              justifyContent={"space-between"}
                              background={"white"}
                            >
                              <Flex
                                pb={0}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <Heading size={"md"}>Upload</Heading>
                                {/* <IconButton
                          size={"sm"}
                          variant={"none"}
                          icon={<XIcon size={20} />}
                          aria-label=""
                          onClick={() => {
                            onUploadClose();
                          }}
                        /> */}
                              </Flex>
                              <Divider my={2} />
                              <Stack flex={1}>
                                <FormControl>
                                  <FormLabel>Choose your image</FormLabel>
                                  <Dropzone
                                    maxFiles={1}
                                    accept={{
                                      "application/*": [
                                        ".png",
                                        ".jpg",
                                        ".jpeg",
                                        ".webp",
                                        ".svg",
                                      ],
                                    }}
                                    useFsAccessApi={false}
                                    onDrop={(acceptedFiles) => {
                                      setFieldValue("file", acceptedFiles[0]);
                                    }}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <section>
                                        {/* @ts-ignore */}
                                        <div {...getRootProps({ style: baseStyle, })}
                                        >
                                          <input
                                            accept=".png,.svg,.jpeg,.jpg,.webp"
                                            {...getInputProps()}
                                          />
                                          <p>
                                            Drag 'n' drop some files here, or
                                            click to select image
                                          </p>
                                        </div>
                                      </section>
                                    )}
                                  </Dropzone>
                                </FormControl>
                                <Text>Files</Text>
                                <List>
                                {/* @ts-ignore */}
                                  {values.file ? (<Text>{values.file.name}</Text>) : (
                                    <Text>No image selected</Text>
                                  )}
                                </List>
                              </Stack>
                              <Flex gap={2} m={2} justify={"flex-end"}>
                                <Button
                                  onClick={() => {
                                    onUploadClose();
                                  }}
                                  type="submit"
                                  isDisabled={isImageUploading}
                                  fontSize={"sm"}
                                  variant={"outline"}
                                  colorScheme="none"
                                >
                                  Cancel
                                </Button>
                                <Button
                                  isDisabled={!values.file}
                                  boxShadow={`0 0 12px -2px rgba(254, 91, 37, 0), 0 0 12px -2px rgba(163, 85, 247, 0), 0 0 4px 2px rgba(35, 39, 46, .24), inset 0 2px 2px rgba(255, 255, 255, .24)`}
                                  _hover={{
                                    boxShadow: `0 0 12px -2px var(--chakra-colors-primary-200), 0 0 12px -2px #a355f7, 0 0 4px 2px rgba(35, 39, 46, .24), inset 0 2px 2px 0 rgba(255, 255, 255, .24)`,
                                  }}
                                  isLoading={isImageUploading}
                                  type="submit"
                                  fontSize={"sm"}
                                  variant={"solid"}
                                  colorScheme="primary"
                                >
                                  Click to upload
                                </Button>
                              </Flex>
                            </Stack>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={4}>
            <Heading size={"sm"}>Enter details to test your assistant</Heading>
            <Box mt={4}>
              <Formik
                initialValues={{
                  name: "",
                  phone_number: ""
                }}
                onSubmit={(values) => {
                  if (
                    values.name &&
                    values.phone_number && assistant.data.advanced_config?.phone_number_id
                  ) {
                    setTestLoading(true);
                    dispatch(
                      testAgentViaCallV2(
                        organization.data.id,
                        assistant.data.provider_id,
                        assistant.data.advanced_config?.phone_number_id,
                        values.phone_number,
                        values.name,
                        () => {
                          setTestLoading(false);
                          toast({
                            status: "success",
                            title: "Call initiated successfully",
                            description: `You should recieve a call from ${assistant.data.name} within a few seconds`,
                          });
                          onClose()
                        }
                      )
                    );
                  }
                }}
              >
                {({ errors, touched, values, setFieldValue }) => {
                  return (
                    <Form>
                      <Stack gap={4}>
                        <FormControl isInvalid={!!errors.name && touched.name}>
                          <FormLabel fontSize={"small"}>Name</FormLabel>
                          <Field
                            value={values.name}
                            as={Input}
                            validate={(value: any) => {
                              let error;
                              if (!value) {
                                error = "Name is required";
                              }
                              return error;
                            }}
                            name="name"
                            resize={"none"}
                            borderRadius={8}
                            rows={22}
                            size={"sm"}
                            placeholder="Enter your name"
                          />
                          <FormErrorMessage>{errors.name}</FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={
                            !!errors.phone_number && touched.phone_number
                          }
                        >
                          <FormLabel fontSize={"small"}>Phone number</FormLabel>
                          <Field
                            value={values.phone_number}
                            as={PhoneInput}
                            name="phone_number"
                            defaultCountry="in"
                            resize={"none"}
                            disableDialCodePrefill={true}
                            onChange={(phone: any) => setFieldValue("phone_number", phone)}
                            validate={(value: any) => {
                              let error;
                              if (!value) {
                                error = "Phone number is required";
                              }
                              if (value) {
                                const instance = PhoneNumberUtil.getInstance();
                                try {
                                  instance.isValidNumber(instance.parseAndKeepRawInput(value))
                                } catch (err) {
                                  error = "Invalid phone number"
                                }
                              }
                              return error;
                            }}
                            borderRadius={8}
                            rows={22}
                            size={"sm"}
                            placeholder="Enter your phone number"
                          />
                          <FormErrorMessage>
                            {errors.phone_number}
                          </FormErrorMessage>
                        </FormControl>
                        {/* <FormControl
                          isInvalid={
                            !!errors.agent_phone_number
                          }
                        >
                          <FormLabel fontSize={"small"}>
                            Choose Agent Number
                          </FormLabel>
                          <Select
                            onChange={(v) => {
                              setFieldValue("agent_phone_number", v?.value);
                            }}
                            value={{
                              label: values.agent_phone_number,
                              value: values.agent_phone_number,
                            }}
                            placeholder={"Select agent phone number"}
                            selectedOptionColorScheme="primary"
                            size={"sm"}
                            variant={"outline"}
                            chakraStyles={dropdownStyles}
                            components={dropdownComponents}
                            options={(assistant.data.phone_numbers || []).map(
                              (v: string) => ({
                                label: v,
                                value: v,
                              })
                            )}
                          />
                          <FormErrorMessage>
                            {errors.agent_phone_number as string}
                          </FormErrorMessage>
                        </FormControl> */}
                      </Stack>
                      <Flex mt={4} justifyContent={"end"}>
                        <Button
                          colorScheme="primary"
                          isLoading={isTestLoading}
                          size={"sm"}
                          type="submit"
                        >
                          Submit
                        </Button>
                      </Flex>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box
        zIndex={4}
        backdropFilter={"blur(5px)"}
        background={"transparent"}
        display={hasCallStarted ? "block" : "none"}
        pos={"absolute"}
        w={"100vw"}
        h={"100vh"}
      >
        <Center h={"80vh"} w={"75vw"}>
        <Box cursor={"pointer"} ml={8} _before={{
          pos: "absolute",
          background: `linear-gradient(to left, #DD71B8 10%, #FDDE3C')`,
          filter: 'blur(10px)',
          left: 0,
          borderRadius: "full",
          top: 0,
          content: '" "',
          h: "full",
          w: "full"
        }} pos={"relative"} height={12}>
          <Img pos={"relative"} src={AIGif} h={20} />
        </Box>
        </Center>
      </Box>
        <Flex alignItems={"center"} zIndex={4} gap={6} rounded={36} background={"white"} left={"50%"} display={hasCallStarted ? "flex" : "none"} bottom={12} py={2} px={2} border={"1px solid #cacaca"} pos={"fixed"}>

            <IconButton onClick={() => {
              if (isMuted) {
                vapi?.current?.setMuted(false)
                setIsMuted(false)
              } else {
                vapi?.current?.setMuted(true)
                setIsMuted(true)
              }
            }} variant={"outline"} border={"1px solid #cacaca"} colorScheme={isMuted ? "primary" : "none"} rounded={"full"} icon={isMuted ? <MicOffIcon size={20} /> : <MicIcon size={20} />} aria-label="" />
            <Text fontWeight={700}>{`${String(Math.floor((time % 3600) / 60)).padStart(2, "0")}:${String(time % 60).padStart(2, "0")}`}</Text>
            <IconButton colorScheme="red" onClick={() => {
              vapi.current?.stop()
              millis.current?.stop()
              if (timer.current !== null) {
                clearInterval(timer.current)
              }
              setHasCallStarted(false)
            }} rounded={"full"} icon={<PhoneOffIcon size={20} />} aria-label="" />
        </Flex>
      <Flex
        gap={2}
        direction={["column", "row"]}
        mt={6}
        justifyContent={["start", "space-between"]}
        alignItems={["start", "center"]}
      >
        <Stack gap={2}>
          <Flex alignItems={"center"} gap={2}>
            <IconButton onClick={() => {
              navigate(-1)
            }} rounded={"full"} aria-label="" icon={<ArrowLeftIcon size={16} />} variant={"outline"} />
            <Skeleton rounded={"full"} isLoaded={assistant.state !== "loading"}>
            <Box pos={"relative"}>
                <IconButton
                  onClick={() => {
                    onUploadOpen();
                  }}
                  background={"white"}
                  variant={"outline"}
                  zIndex={2}
                  bottom={-2}
                  right={-2}
                  aria-label=""
                  pos={"absolute"}
                  icon={<UploadCloudIcon size={12} />}
                  size={"xs"}
                />
                <Avatar
                  src={assistant.data.profile_pic}
                  name={assistant.data.name}
                />
              </Box>
            </Skeleton>
            <Box ml={2}>
              <Skeleton isLoaded={assistant.state !== "loading"}>
                  {/* <Editable defaultValue='Take some chakra'>
                  <EditablePreview />
                  <EditableInput />
                </Editable> */}
                <Text fontWeight={"semibold"} fontSize={24}>
                  {assistant.data.name}
                </Text>
                <Text fontSize={12}>
                  {assistant.data.organization?.name}
                </Text>
              </Skeleton>
              {/* <Text color={"gray"} mt={0}>
              {_.capitalize((assistant.data.use_case || '').replaceAll("_", ""))}
            </Text> */}
            </Box>
          </Flex>
          <Skeleton isLoaded={assistant.state !== "loading"}>
            <InputGroup>
              <Input
                h={10}
                borderRadius={10}
                w={300}
                value={assistant.data.id}
                size={"sm"}
                readOnly
              />
              <InputRightElement
                justifyContent={"flex-end"}
                w={120}
                h={9}
                top={0.5}
                borderRadius={10}
                background={
                  "linear-gradient(to right, transparent 1px, white, white)"
                }
                gap={2}
                right={"54px"}
              >
                {/* <IconButton
                  size={"xs"}
                  aria-label=""
                  icon={<CopyIcon size={12} />}
                /> */}
                <IconButton
                  size={"xs"}
                  aria-label=""
                  onClick={async () => {
                    await window.navigator.clipboard.writeText(
                      `${window.location.protocol}//app.${window.location.host}/demo?agent=${assistant.data.provider_id}&assistantId=${assistant.data.id}&key=${assistant.data.provider === "MILLIS" ? organization.data?.providers?.millis?.public_api_key : organization.data?.providers?.vapi?.public_api_key}`
                    );
                    toast({
                      title: "Agent demo link copied",
                      status: "success",
                    });
                  }}
                  icon={<LinkIcon size={12} />}
                />
              </InputRightElement>
            </InputGroup>
          </Skeleton>
          <Text fontSize={"small"} color={"gray"}></Text>
        </Stack>
        <Box>
          <Flex alignItems={"center"} gap={3}>
            <Skeleton isLoaded={assistant.state !== "loading"}>
            <Menu>
                <MenuButton
                  isLoading={isTestLoading}
                  as={Button}
                  aria-label='Options'
                  colorScheme="primary"
                  size="sm"
                  rightIcon={<RocketIcon size={16} />}
                > Talk with {assistant?.data?.name}</MenuButton>
                <MenuList>
                <Tooltip hasArrow label={!organization.data?.providers?.vapi?.public_api_key ? "No public key present in organization for web call" : ""}>
                  <MenuItem
                    isDisabled={!organization.data?.providers?.vapi?.public_api_key}
                    onClick={async () => {
                      if (assistant.data?.provider_id) {
                        setTestLoading(true)
                        if (assistant.data.provider === "VAPI") {
                          await vapi.current?.start(assistant.data?.provider_id)
                        } else {
                          console.log(millis.current)
                          await millis.current?.start(assistant.data?.provider_id)
                        }
                        setTestLoading(false)
                        setHasCallStarted(true)
                        timer.current = setInterval(() => {
                          setTime((v) => v+1);
                        }, 1000)
                        toast({
                          title: `Call started. You can now speak with ${assistant.data?.name || ''}`
                        })
                      }
                    }}
                  fontWeight={600}
                  fontSize={"sm"} icon={<Laptop2Icon size={20} />}>
                    Web Call
                  </MenuItem>
                  </Tooltip>
                  <Tooltip hasArrow label={!assistant.data?.advanced_config?.phone_number_id ? "Please attach the phone number below to test the agent" : ""}>
                    <MenuItem
                  fontWeight={600}
                  fontSize={"sm"}
                  isDisabled={!assistant.data?.advanced_config?.phone_number_id}
                  onClick={() => {
                    onOpen();
                    // dispatch(testAgentViaCall())
                  }} icon={<PhoneOutgoingIcon size={20} />}>
                    Phone Call
                  </MenuItem>
                  </Tooltip>
                </MenuList>
              </Menu>
            </Skeleton>

            <Button
              onClick={() => {
                dispatch(updateAgent(id!, assistant.data))
              }}
              isLoading={updatedAssistant.state === "loading"}
              type="submit"
              size={"sm"}
              variant={"solid"}
              colorScheme="black"
              bg={"black"}
              leftIcon={<RotateCcwIcon size={20} />}
            >Publish Changes</Button>
            <Skeleton isLoaded={assistant.state !== "loading"}>
              <Button
                hidden
                colorScheme="primary"
                size="sm"
                variant={"outline"}
                rightIcon={<RefreshCcw size={16} />}
              >
                Publish
              </Button>
            </Skeleton>
            <Menu size={"sm"}>
              <MenuButton
                hidden
                variant="outline"
                size="sm"
                as={IconButton}
                icon={<MoreVerticalIcon size="16" />}
              ></MenuButton>
              <MenuList>
                <MenuItem
                  color={"var(--chakra-colors-red-500)"}
                  icon={<TrashIcon size={16} />}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
          <Text fontSize={"small"} color={"gray"} textAlign={"end"} mt={1}>
            {updatedAssistant.state === "success" ? (
              <Flex
                justify={"end"}
                gap={2}
                color="#38A169"
                alignItems={"center"}
              >
                <CheckCircle2Icon size={16} color="#38A169" />
                <Text textAlign={"end"} fontSize={"small"}>
                  Updated just now
                </Text>
              </Flex>
            ) : (
              `Last updated: ${moment
                .parseZone(assistant.data.updated_at)
                .fromNow()}`
            )}
          </Text>
        </Box>
      </Flex>
      <Tabs
        onChange={(index) => {
          setCurrentTab(index);
        }}
        index={tab}
        size={"sm"}
        colorScheme="primary"
      >
        <TabList
          borderColor={
            assistant.state === "loading" ? "transparent" : "inherit"
          }
          gap={2}
        >
          <Skeleton isLoaded={assistant.state !== "loading"}>
            <Tab>
              <TabItem title="Model" icon={<BrainIcon size={14} />} />
            </Tab>
          </Skeleton>
          <Skeleton isLoaded={assistant.state !== "loading"}>
            <Tab>
              <TabItem title="Transcriber" icon={<CaptionsIcon size={14} />} />
            </Tab>
          </Skeleton>
          <Skeleton isLoaded={assistant.state !== "loading"}>
            <Tab>
              <TabItem title="Voice" icon={<AudioLinesIcon size={14} />} />
            </Tab>
          </Skeleton>
          <Skeleton isLoaded={assistant.state !== "loading"}>
            <Tab>
              <TabItem title="Phone" icon={<PhoneIcon size={14} />} />
            </Tab>
          </Skeleton>
          <Skeleton isLoaded={assistant.state !== "loading"}>
            <Tab hidden>
              <TabItem
                title="Functions"
                icon={<SquareFunctionIcon size={14} />}
              />
            </Tab>
          </Skeleton>
          <Skeleton isLoaded={assistant.state !== "loading"}>
            <Tab>
              <TabItem title="Advanced" icon={<CogIcon size={14} />} />
            </Tab>
          </Skeleton>
          <Skeleton isLoaded={assistant.state !== "loading"}>
            <Tab hidden>
              <TabItem title="Analysis" icon={<LineChartIcon size={14} />} />
            </Tab>
          </Skeleton>
          <Skeleton isLoaded={assistant.state !== "loading"}>
            <Tab>
              <TabItem title="General" icon={<ShellIcon size={14} />} />
            </Tab>
          </Skeleton>
        </TabList>
      </Tabs>
      <Stack
        overflow={"hidden"}
        height={"calc(100vh - 96px)"}
        justifyContent={"space-between"}
      >
        {assistant.state === "loading" ? (
          <Stack>
            <Center height={"60vh"}>
              <Spinner />
            </Center>
          </Stack>
        ) : (
          <Stack overflow={"scroll"} height={"calc(100% - 36px)"} flex={1}>
            {tab === 0 ? (
              <ModelConfig
                onFinalSubmit={onFinalSubmit}
                data={assistant.data}
              />
            ) : tab === 1 ? (
              <TranscriberConfig
                onFinalSubmit={onFinalSubmit}
                data={assistant.data}
              />
            ) : tab === 2 ? (
              <VoiceConfig
                onFinalSubmit={onFinalSubmit}
                data={assistant.data}
              />
            ) : tab === 3 ? (
              <PhoneConfig
                onFinalSubmit={onFinalSubmit}
                data={assistant.data}
              />
            ) : tab === 4 ? (
              <FunctionsConfig onFinalSubmit={onFinalSubmit} data={assistant} />
            ) : tab === 5 ? (
              <AdvancedConfig
                onFinalSubmit={onFinalSubmit}
                data={assistant.data}
              />
            ) : tab === 6 ? (
              <AnalysisConfig onFinalSubmit={onFinalSubmit} data={assistant} />
            ) : tab === 7 ? (
              <GeneralConfig
                onFinalSubmit={onFinalSubmit}
                data={assistant.data}
              />
            ) : (
              <></>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
