import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import Login from "./@pages/auth/Login"
import Playground from "./@pages/app/Playground"
import Assistants from "./@pages/app/Assistants"
import { useEffect } from "react"
import { useCookies } from "react-cookie"
import Shell from "./@pages/Shell"
import SignUp from "./@pages/auth/SignUp"
import SpecificAssistant from "./@pages/app/SpecificAssistant"
import Home from "./@pages/app/Home"
import AuditLogs from "./@pages/app/Audit"
import Settings from "./@pages/app/Settings"
import VoiceLibrary from "./@pages/app/VoiceLibrary"
import Organizations from "./@pages/app/Organizations"

export const App = () => {
  const [cookies] = useCookies(["accessToken"]);
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!location.pathname.startsWith("/auth")  && !cookies.accessToken) {
      navigate(`/auth/login`)
    } else if (location.pathname === "/") {
      navigate('/app/assistants')
    }
  }, [ cookies ])

  return (
    <Routes>
      <Route path="auth/">
        <Route path="login/" element={<Login />} />
        <Route path="signup/" element={<SignUp />} />
      </Route>
      <Route path="app/" element={<Shell />}>
        <Route path="home/" element={<Home />} />
        <Route path="voice-library/" element={<VoiceLibrary />} />
        <Route path="playground/" element={<Playground />} />
        <Route path="assistants/" element={<Assistants />} />
        <Route path="organizations/" element={<Organizations />} />
        <Route path="assistants/:assistant" element={<SpecificAssistant />} />
        <Route path="logs/" element={<AuditLogs />} />
        <Route path="settings/" element={<Settings />} />
      </Route>
    </Routes>
  )
}
