export function extractVariables(template: string, skipBraces = false) {
    const regex = /{([^{}]+)}/g;
    const matches = Array.from(template.matchAll(regex));
    const variables = [];

    for (const match of matches) {
        const variable = match[1].trim();
        if (!skipBraces || variable[0] !== "{" || variable[variable.length - 1] !== "}") {
            variables.push(variable);
        }
    }

    return variables;
}