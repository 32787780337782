import { axios } from "../../../utils/axios.loader";
import { AppDispatch } from "../../store";
import { setCreateOrganizationLoading, setCreateOrganizationSuccess, setGetOrganizationLoading, setGetOrganizationSuccess, setListOrganizationLoading, setListOrganizationSuccess, setUpdateOrganizationLoading, setUpdateOrganizationSuccess } from "./actions";

export const getOrganizations = (page = 1, size = 10, keyword = '') => async (dispatch: AppDispatch) => {
    dispatch(setListOrganizationLoading())
    try {
        const request = await axios.get(`/organization/?page=${page}&page_size=${size}${keyword ? `&filters=name[contains]=${keyword}` : ''}&sort_value=-updated_at`)
        const { organizations, total_count, page: _page } = request.data;
        dispatch(setListOrganizationSuccess(organizations, {
            total_count,
            page: _page,

        }))
    } catch (err) {

    }
}

export const getSpecificOrganization = (organization: string) => async (dispatch: AppDispatch) => {
    dispatch(setGetOrganizationLoading())
    try {
        const request = await axios.get(`/organization/${organization}`)
        const response = request.data;
        dispatch(setGetOrganizationSuccess(response))
    } catch (err) {

    }
}

export const createOrganization = (data: { name: string, description: string, private_key: string, public_key: string }, callback?: (data?: any) => void) => async (dispatch: AppDispatch) => {
    dispatch(setCreateOrganizationLoading())
    try {
        const request = await axios.post(`/organization/`, {
            name: data.name,
            description: data.description,
            "providers": {
                "vapi": {
                    "api_key": data.private_key,
                    "public_api_key": data.public_key
                }
            },
        })
        const response = request.data;
        dispatch(setCreateOrganizationSuccess(response))
        callback?.(response)
    } catch (err) {

    }
}

export const updateOrganization = (organization: string, payload: any, callback?: (data: any) => void) => async (dispatch: AppDispatch) => {
    dispatch(setUpdateOrganizationLoading())
    try {
        const request = await axios.put(`/organization/${organization}`, {
            ...payload
        })
        const response = request.data;
        dispatch(setUpdateOrganizationSuccess(response))
        callback?.(response)
    } catch (err) {

    }
}

export const deleteOrganization = (organization: string) => async (dispatch: AppDispatch) => {
    try {

    } catch (err) {

    }
}