/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Badge,
  Box,
  Button,
  Center,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  IconButton,
  Input,
  Spinner,
  Stack,
  Text,
  useBoolean,
  useDisclosure,
  useMediaQuery,
  useToast,
  Select as CoreSelect,
  Divider,
  FormErrorMessage,
  Img,
} from "@chakra-ui/react";
import _ from "lodash";
import PresetImg from "../../assets/img/fill-preset.svg";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import moment from "moment-timezone";
import { setAgentSuccess } from "../../lib/app/platform/actions";
import {
  ArrowLeftRightIcon,
  BriefcaseBusinessIcon,
  CalendarCheck2Icon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronsLeftIcon,
  ChevronsRightIcon,
  ChevronsUpDownIcon,
  HeadsetIcon,
  PlusIcon,
  XIcon,
} from "lucide-react";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  resetAgentCreate,
  resetAgentUpdate,
} from "../../lib/app/assistants/actions";
import { createAgent } from "../../lib/app/assistants/thunk";
import { loadAgents, searchOrganizations, searchTeams } from "../../lib/app/platform/thunk";
import { AsyncSelect, Select, chakraComponents } from "chakra-react-select";

const dropdownStyles: any = {
  dropdownIndicator: (b: any) => ({
    ...b,
    backgroundColor: "transparent",
    borderColor: "transparent",
    border: 0,
  }),
  control: (b: any) => ({
    ...b,
    borderRadius: 8,
  }),
  menuList: (b: any) => ({
    ...b,
    borderRadius: 8,
  }),
};

const dropdownComponents = {
  DropdownIndicator: (props: any) => (
    <chakraComponents.DropdownIndicator {...props}>
      <ChevronsUpDownIcon size={12} />
    </chakraComponents.DropdownIndicator>
  ),
};

export default function Assistants() {
  const agents = useAppSelector((state) => state.app.platform.agents.list);
  const metadata = useAppSelector((state) => state.app.platform.agents.list.metadata);
  const createdAgent = useAppSelector((state) => state.app.assistants.create);
  const updatedAgent = useAppSelector((state) => state.app.assistants.update);
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const toaster = useToast();
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const [hidden, { on, off }] = useBoolean(false);
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(15)
  const [selectedOption, setSelectionOption] = useState("blank");
  const options = [
    {
      icon: <PlusIcon size={16} />,
      title: "Blank Template",
      subtitle:
        "This blank slate template with minimal configurations. It's a starting point for creating your custom assistant.",
      key: "blank",
      name: "New Assistant",
      params: {
        use_case: "GENERAL",
        interaction_mode: "CONVERSATIONAL",
        status: "OFFLINE",
        ai_model_name: "gpt-3.5-turbo",
        ai_model_config: {}
      }
    },
    {
      icon: <CalendarCheck2Icon size={16} />,
      title: "Appointment Setter",
      subtitle:
        "Appointment setter is designed for dental practices to demonstrate Appointment setting assitant. It streamlines appointment scheduling, answers common questions, and provides service information.",
      key: "appointment",
      name: "Mary",
      params: {
        use_case: "SUPPORT",
        interaction_mode: "CONVERSATIONAL",
        status: "OFFLINE",
        ai_model_name: "gpt-3.5-turbo",
        ai_model_config: {}
      }
    },
    {
      icon: <HeadsetIcon size={16} />,
      title: "Customer Support",
      subtitle:
        "This is a versatile assistant template, designed with a perfect mix of emotional intelligence and technical knowledge. Ideal for empathetic, efficient customer support.",
      key: "support",
      name: "Leo",
      params: {
        use_case: "SUPPORT",
        interaction_mode: "CONVERSATIONAL",
        status: "OFFLINE",
        ai_model_name: "gpt-3.5-turbo",
        ai_model_config: {}
      }
    },
    {
      icon: <ArrowLeftRightIcon size={16} />,
      title: "Inbound Q/A",
      subtitle:
        "This is an inbound call agent designed to provide comprehensive support for SmartHome Innovations. With a deep understanding of product details and troubleshooting.",
      key: "inbound",
      name: "Leo",
      params: {
        use_case: "SUPPORT",
        interaction_mode: "CONVERSATIONAL",
        status: "OFFLINE",
        ai_model_name: "gpt-3.5-turbo",
        ai_model_config: {}
      }
    },
    {
      icon: <BriefcaseBusinessIcon size={16} />,
      title: "Recruitment",
      subtitle:
        "This is an assistant for demonstrating an recruitment, She is designed to offer guidance, lore, and insights into the mysteries of the natural world.",
      key: "game",
      name: "Elenya",
      params: {
        use_case: "SUPPORT",
        interaction_mode: "CONVERSATIONAL",
        status: "OFFLINE",
        ai_model_name: "gpt-3.5-turbo",
        ai_model_config: {}
      }
    },
  ];
  const debouncedSearch = _.debounce((v: string) => {
    dispatch(loadAgents(1, size, v))
  }, 300)
  const [organizationOptions, setOrganizationOptions] = useState([])
  const [teamOptions, setTeamOptions] = useState([])
  const [selectedOrg, setSelectionOrg] = useState({})
  const [selectedTeam, setSelectionTeam] = useState({})

  useEffect(() => {
    dispatch(loadAgents(page, size))
  }, [page, size])

  useEffect(() => {
    if (isMobile && !hidden) {
      on();
    } else if (!isMobile && hidden) {
      off();
    }
  }, [isMobile]);

  useEffect(() => {
    (async () => {
      const options = await searchOrganizations()
      setOrganizationOptions(options)
    })()
  }, [])

  useEffect(() => {
    if ((selectedOrg as any)?.value) {
      (async () => {
        const options = await searchTeams((selectedOrg as any).value )
        setTeamOptions(options)
      })()
    }
  }, [selectedOrg])

  useEffect(() => {
    if (createdAgent.state === "success") {
      toaster({
        title: "Agent created successfully",
        description: "Your agent is ready to use",
        duration: 3000,
        status: "success",
      });
      dispatch(setAgentSuccess(createdAgent.data));
      navigate(`/app/assistants/${createdAgent.data.id}`)
      setTimeout(() => {
        dispatch(resetAgentCreate());
      }, 1000);
      onClose();
    } else if (createdAgent.state === "error") {
      toaster({
        title: "Failed to create agent",
        description: createdAgent.error,
        duration: 3000,
        status: "error",
      });
      setTimeout(() => {
        dispatch(resetAgentCreate());
      }, 1000);
    }
  }, [createdAgent]);

  useEffect(() => {
    // The below logic is kind of similar to the above one as well. Will refractor when time permits
    if (updatedAgent.state === "success") {
      // toaster({
      //   title: "Agent updated successfully",
      //   description: "Your agent is up to date",
      //   duration: 3000,
      //   status: "success",
      // });
      // dispatch(setAgentSuccess(updatedAgent.data));
      // setTimeout(() => {
      //   dispatch(resetAgentUpdate());
      // }, 1000);
    } else if (updatedAgent.state === "error") {
      toaster({
        title: "Failed to update agent",
        description: updatedAgent.error,
        duration: 3000,
        status: "error",
      });
      setTimeout(() => {
        dispatch(resetAgentUpdate());
      }, 1000);
    }
  }, [updatedAgent]);

  return (
    <Box>
      <Drawer size={"lg"} isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay
          style={{
            backdropFilter: "blur(4px)",
          }}
        />
        <DrawerContent boxShadow={0} background={"transparent"}>
          <Formik
            initialValues={{
              name: "",
              organization_id: "",
              team_id: null,
              provider: "VAPI"
            }}
            onSubmit={(values) => {
              if (values.organization_id) {
                dispatch(createAgent(values.organization_id, {
                  name: values.name,
                  system_prompt: 'Smart assistant',
                  about: 'Smart assistant',
                  ...options.filter(k => k.key === selectedOption)[0].params,
                  ai_model_config: {
                    max_tokens: 250
                  },
                  transcriber_config: {
                    language: "en",
                    model: "nova-2",
                    provider: "deepgram"
                  },
                  voice_config: {
                    voice_id: "21m00Tcm4TlvDq8ikWAM",
                    voice_name: "Rachel"
                  },
                  voice_id: "ba7a019e-df90-4229-811b-b72b4e712a45",
                  status: "OFFLINE",
                  ai_model_name: "gpt-4o",
                  provider: values.provider,
                  team_id: values.team_id,
                  interaction_mode: "CONVERSATIONAL",
                  advanced_config: {
                    analysisPlan: {
                      successEvaluationRubric: "DescriptiveScale",
                      summaryPrompt: "Given the transcript, summarize the content by\n1. Agent Performance: How did you perform during the call? Highlight any strengths or areas for improvement\n2. Customer Reaction: How did the customer react to the information and assistance provided?\n3. Customer Requests: List the primary requests or concerns of the customer\n4. Next steps: Any follow-up actions needed from your side or the customer's side",
                      successEvaluationPrompt: "Evaluate the success of the recent call based on:\n1. \"warm_greeting\": Did you greet the caller warmly and ask for their full name?\n2. \"engagement\": Did you engage in small talk to make the caller feel comfortable?\n3. \"understanding_needs\": Did you listen carefully to the caller’s request and respond accordingly?\n4. \"detailed_follow_up\": Did you ask follow-up questions based on the caller’s request?\n5. \"date_and_time\": Did you ask for and confirm the preferred dates and times for the booking?\n6. \"confirmation\": Did you confirm all details with the caller?\n7. \"package_details\": Did you inform the caller about sending package details via SMS or WhatsApp and confirm the preferred method?\n8. \"corporate_booking\": For corporate inquiries, did you offer to set up a call with the corporate team or arrange a hotel visit if requested?\n9. \"politeness_efficiency\": Were you polite, friendly, and efficient throughout the call?",
                      structuredDataPrompt: "From the given transcript, you need to extract most top questions as an array of string, top words as array of string, conversation score out of 10, next steps that the agent or customer has to do, agent performance, customer reaction, top customer requests and overall summary of the call and success metrics needed to evaluate the success of the call.",
                      structuredDataSchema: {
                        "type": "object",
                        "properties": {
                            "words": {
                                "type": "array",
                                "description": "Top words",
                                "items": {
                                    "type": "string"
                                }
                            },
                            "questions": {
                                "type": "array",
                                "description": "Top questions",
                                "items": {
                                    "type": "string"
                                }
                            },
                            "score": {
                                "type": "number",
                                "description": "Conversation Score from 0 to 10"
                            }
                        }
                      }
                    }
                  },
                  organization_id: values.organization_id,
                  recommended_voices: [
                    {
                        "label": "Saira - Young Casual Voice",
                        "value": "vghiSqG5ezdhd8F3tKAD",
                        "id": "c8ec34d4-95ca-476a-a77f-95a65d26f92c",
                        "voice_id": "vghiSqG5ezdhd8F3tKAD",
                        "voice_provider": "ELEVENLABS",
                        "name": "Saira - Young Casual Voice",
                        "description": null,
                        "gender": "FEMALE",
                        "accent": "BRITISH",
                        "tone": null,
                        "age_category": "YOUNG",
                        "category": "PREMADE",
                        "use_case": "CONVERSATIONAL",
                        "sample_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/vghiSqG5ezdhd8F3tKAD/lVQiUua3EmORrlbKpfr0.mp3",
                        "created_at": "2023-11-28T00:27:31.819752",
                        "updated_at": "2023-11-28T00:27:31.819756"
                    },
                    {
                        "label": "Hope - natural conversations",
                        "value": "OYTbf65OHHFELVut7v2H",
                        "id": "f907a5dd-4f86-4227-9c40-7f51820ec0fa",
                        "voice_id": "OYTbf65OHHFELVut7v2H",
                        "voice_provider": "ELEVENLABS",
                        "name": "Hope - natural conversations",
                        "description": "",
                        "gender": "FEMALE",
                        "accent": "AMERICAN",
                        "tone": "CONVERSATIONAL",
                        "age_category": "YOUNG",
                        "category": "PREMADE",
                        "use_case": "CONVERSATIONAL",
                        "sample_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/OYTbf65OHHFELVut7v2H/ZN73m0kIWmrTlinxqSiN.mp3",
                        "created_at": "2024-08-02T00:45:41.397547",
                        "updated_at": "2024-08-02T00:45:41.397547"
                    },
                    {
                        "label": "Barbara - Eloquent, intelligent female\r\n",
                        "value": "kwZZS3WAVfk7EncqOz2K",
                        "id": "5c108a13-b5b4-48cf-a5a1-c40bd25bf82a",
                        "voice_id": "kwZZS3WAVfk7EncqOz2K",
                        "voice_provider": "ELEVENLABS",
                        "name": "Barbara - Eloquent, intelligent female\r\n",
                        "description": "A young female British speaker, eloquent and intelligent, who tends to speak somewhat rapidly.Great for podcast.",
                        "gender": "FEMALE",
                        "accent": "BRITISH",
                        "tone": "CONVERSATIONAL",
                        "age_category": "YOUNG",
                        "category": "PREMADE",
                        "use_case": "CONVERSATIONAL",
                        "sample_url": "https://storage.googleapis.com/eleven-public-prod/udmG0I9oKegHHyrU3sEvatdvG2p1/voices/xt3HPA16vjtEep1aKvP2/4fb33ada-8003-4e0e-b367-5de11dae9bf9.mp3",
                        "created_at": "2024-08-02T00:45:43.974683",
                        "updated_at": "2024-08-02T00:45:43.974683"
                    },
                    {
                        "label": "Archer",
                        "value": "L0Dsvb3SLTyegXwtm47J",
                        "id": "01763abb-6dce-4f35-afd0-d390a0a18f93",
                        "voice_id": "L0Dsvb3SLTyegXwtm47J",
                        "voice_provider": "ELEVENLABS",
                        "name": "Archer",
                        "description": "Conversational, calm British, thirty-something, perfect for an AI agent.",
                        "gender": "MALE",
                        "accent": "BRITISH",
                        "tone": "CONVERSATIONAL",
                        "age_category": "MIDDLE_AGED",
                        "category": "PREMADE",
                        "use_case": "CONVERSATIONAL",
                        "sample_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/L0Dsvb3SLTyegXwtm47J/EKoXN08bacukhTjtp6sq.mp3",
                        "created_at": "2024-08-02T00:45:43.654923",
                        "updated_at": "2024-08-02T00:45:43.654923"
                    },
                    {
                        "label": "Miriam - Casual and Wry",
                        "value": "Ah0Fg1nzczXgBpMPTtQb",
                        "id": "31014311-bc44-410b-bd1d-d9a20a1748dd",
                        "voice_id": "Ah0Fg1nzczXgBpMPTtQb",
                        "voice_provider": "ELEVENLABS",
                        "name": "Miriam - Casual and Wry",
                        "description": "Middle aged female with an Australian accent. Voice is low, smooth and casual. Great for conversations.",
                        "gender": "FEMALE",
                        "accent": "AUSTRALIAN",
                        "tone": "CONVERSATIONAL",
                        "age_category": "MIDDLE_AGED",
                        "category": "PREMADE",
                        "use_case": "CONVERSATIONAL",
                        "sample_url": "https://storage.googleapis.com/eleven-public-prod/0uIqkkS46jQxfFloXWQg5TWCzKm1/voices/JKpK5B1pXInRQlSuN4pQ/6bc93aa0-5c0b-408c-8b5f-1b6d7bdb8f2d.mp3",
                        "created_at": "2024-08-02T00:45:47.892662",
                        "updated_at": "2024-08-02T00:45:47.892662"
                    },
                    {
                        "label": "Sam - Aussie",
                        "value": "3DkcznWTIDSnX3f0J6DG",
                        "id": "7c1c5caf-5bd9-4f4a-a3c6-e35ad3defb3c",
                        "voice_id": "3DkcznWTIDSnX3f0J6DG",
                        "voice_provider": "ELEVENLABS",
                        "name": "Sam - Aussie",
                        "description": "A young adult male voice hailing from down under, showcasing relatable, friendly Aussie accent.  The voice of the everyman! (well, one of them!)  A good fit for a project where a conversational, casual style is needed. Would be great for YouTube or social media.",
                        "gender": "MALE",
                        "accent": "AUSTRALIAN",
                        "tone": "CONVERSATIONAL",
                        "age_category": "YOUNG",
                        "category": "PREMADE",
                        "use_case": "CONVERSATIONAL",
                        "sample_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/3DkcznWTIDSnX3f0J6DG/fZmMOcPbqy0B6gBZ2sLW.mp3",
                        "created_at": "2024-08-02T00:45:48.973501",
                        "updated_at": "2024-08-02T00:45:48.973501"
                    },
                    {
                        "label": "Trixie - Young Gossip Girl",
                        "value": "c7XGL37TTXR5zdorzHX9",
                        "id": "429993a9-2010-460f-bb98-ea5ab307c22d",
                        "voice_id": "c7XGL37TTXR5zdorzHX9",
                        "voice_provider": "ELEVENLABS",
                        "name": "Trixie - Young Gossip Girl",
                        "description": "A youthful, energetic, gossip girl voice. Perfect for storytelling, conversations, podcasts, entertainment, and pop culture. ",
                        "gender": "FEMALE",
                        "accent": "AMERICAN",
                        "tone": "CONVERSATIONAL",
                        "age_category": "YOUNG",
                        "category": "PREMADE",
                        "use_case": "CONVERSATIONAL",
                        "sample_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/c7XGL37TTXR5zdorzHX9/wDROlBcDXPW683m7wN1v.mp3",
                        "created_at": "2024-08-02T00:45:50.696860",
                        "updated_at": "2024-08-02T00:45:50.696860"
                    },
                    {
                        "label": "Christopher - friendly guy next door",
                        "value": "RPEIZnKMqlQiZyZd1Dae",
                        "id": "5435c18c-80c3-4d80-b92e-47b0ac4591eb",
                        "voice_id": "RPEIZnKMqlQiZyZd1Dae",
                        "voice_provider": "ELEVENLABS",
                        "name": "Christopher - friendly guy next door",
                        "description": "A middle age American male with a slight raspiness in his voice and a mix of an East Coast and Midwestern accent. Great for conversations & Explainer videos.",
                        "gender": "MALE",
                        "accent": "AMERICAN",
                        "tone": "CONVERSATIONAL",
                        "age_category": "MIDDLE_AGED",
                        "category": "PREMADE",
                        "use_case": "CONVERSATIONAL",
                        "sample_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/RPEIZnKMqlQiZyZd1Dae/P8rxSTTRjB7TxLxZ89Cd.mp3",
                        "created_at": "2024-08-02T00:45:44.647374",
                        "updated_at": "2024-08-02T00:45:44.647374"
                    },
                    {
                        "label": "Raju - Relatable Hindi Voice",
                        "value": "zT03pEAEi0VHKciJODfn",
                        "id": "b4e2c67d-7ade-40bc-9b62-ef61edc74a39",
                        "voice_id": "zT03pEAEi0VHKciJODfn",
                        "voice_provider": "ELEVENLABS",
                        "name": "Raju - Relatable Hindi Voice",
                        "description": "This voice has the familiar warmth and approachability of a regular Indian speaker, yet it carries a professionalism that makes it versatile for a range of applications. It's clear and articulate enough to engage audiences on social media, provide guidance through customer care bots, and serve in various other communication roles. Its authentic Indian tone adds a layer of relatability without compromising on clarity or effectiveness, making it an excellent choice for connecting with audiences.",
                        "gender": "MALE",
                        "accent": "INDIAN",
                        "tone": "CONVERSATIONAL",
                        "age_category": "MIDDLE_AGED",
                        "category": "PREMADE",
                        "use_case": "CONVERSATIONAL",
                        "sample_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/zT03pEAEi0VHKciJODfn/E1SwTL1qIKPkIxyZQQfk.mp3",
                        "created_at": "2024-08-02T00:45:48.628098",
                        "updated_at": "2024-08-02T00:45:48.628098"
                    },
                    {
                        "label": "Muskaan - Casual Hindi Voice",
                        "value": "xoV6iGVuOGYHLWjXhVC7",
                        "id": "5f82ad42-7f0e-4e0e-ad4a-7795598bf25d",
                        "voice_id": "xoV6iGVuOGYHLWjXhVC7",
                        "voice_provider": "ELEVENLABS",
                        "name": "Muskaan - Casual Hindi Voice",
                        "description": "Muskaan is the pen name of a very experienced financial trainer in India. Her voice is very natural and feels relatable, unlike the corporate tone that often feels disconnected. This voice will be very good for use cases like social media, conversational contexts, and e-learning content.",
                        "gender": "FEMALE",
                        "accent": "INDIAN",
                        "tone": "CONVERSATIONAL",
                        "age_category": "MIDDLE_AGED",
                        "category": "PREMADE",
                        "use_case": "CONVERSATIONAL",
                        "sample_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/xoV6iGVuOGYHLWjXhVC7/HLJ6hooUJSQwgFVGM32b.mp3",
                        "created_at": "2024-08-02T00:45:31.477778",
                        "updated_at": "2024-08-02T00:45:31.477778"
                    }
                ]
                }))
              }
            }}
          >
            {({ errors, touched, values, setFieldValue }) => {
              return (
                <Form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Stack
                    h={"calc(100vh - 24px)"}
                    rounded={10}
                    m={3}
                    justifyContent={"space-between"}
                    background={"white"}
                  >
                    <Flex
                      p={4}
                      pb={0}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Heading size={"md"}>Create your Assistant</Heading>
                      <IconButton
                        size={"sm"}
                        variant={"none"}
                        icon={<XIcon size={20} />}
                        aria-label=""
                        onClick={() => {
                          onClose();
                        }}
                      />
                    </Flex>
                    <Divider my={2} />
                    <Box
                      flex={1}
                      py={4}
                      pb={10}
                      h={"calc(100vh - 84px)"}
                      overflow={"scroll"}
                      rounded={8}
                      // border={"1px solid var(--chakra-colors-gray-200)"}
                      mx={3}
                    >
                      <Box mb={4} px={4}>
                        <FormControl isInvalid={!!errors.organization_id}>
                          <FormLabel fontWeight={600}>Choose Organization</FormLabel>
                          <AsyncSelect
                            value={selectedOrg}
                            loadOptions={(input: string) => {
                              return searchOrganizations(input);
                            }}
                            defaultOptions={organizationOptions}
                            onChange={(v: any) => {
                              setSelectionOrg(v)
                              setFieldValue("organization_id", v?.value);
                              setTeamOptions([])
                            }}
                            
                            isClearable
                            placeholder={"Select organization"}
                            selectedOptionColorScheme="primary"
                            size={"sm"}
                            variant={"outline"}
                            chakraStyles={dropdownStyles}
                            components={dropdownComponents}
                          />
                          <FormErrorMessage>{errors.organization_id}</FormErrorMessage>
                        </FormControl>
                      </Box>
                      <Box mb={4} px={4}>
                      <FormControl isInvalid={!!errors.team_id}>
                          <FormLabel fontWeight={600}>Choose Team</FormLabel>
                          <AsyncSelect
                            value={selectedTeam}
                            loadOptions={(input: string) => {
                              return searchTeams(values.organization_id, input);
                            }}
                            defaultOptions={teamOptions}
                            onChange={(v: any) => {
                              setSelectionTeam(v)
                              setFieldValue("team_id", v?.value);
                            }}
                            
                            isClearable
                            placeholder={"Select team"}
                            selectedOptionColorScheme="primary"
                            size={"sm"}
                            variant={"outline"}
                            chakraStyles={dropdownStyles}
                            components={dropdownComponents}
                          />
                          <FormErrorMessage>{errors.team_id}</FormErrorMessage>
                        </FormControl>
                        </Box>
                        <Box mb={4} px={4}>
                      <FormControl isInvalid={!!errors.team_id}>
                          <FormLabel fontWeight={600}>Choose Provider</FormLabel>
                          <Select
                            value={{
                              value: values.provider,
                              label: values.provider
                            }}
                            onChange={(v: any) => {
                              setFieldValue("provider", v?.value);
                            }}
                            options={["VAPI", "MILLIS"].map(e => ({
                              label: e,
                              value: e
                            }))}
                            isClearable
                            placeholder={"Select provider"}
                            selectedOptionColorScheme="primary"
                            size={"sm"}
                            variant={"outline"}
                            chakraStyles={dropdownStyles}
                            components={dropdownComponents}
                          />
                          <FormErrorMessage>{errors.provider}</FormErrorMessage>
                        </FormControl>
                        </Box>
                      <Stack>
                        <Stack py={1} px={4}>
                          <Text fontWeight={600} fontSize={"large"}>
                            Choose a template
                          </Text>
                          <Text color={"gray"} fontSize={14}>
                            Here's a few templates to get you started, or you
                            can create your own template and use it to create a
                            new assistant.
                          </Text>
                        </Stack>
                        <Divider mt={1} mb={4} />
                        <Stack pb={4}>
                          {options.slice(0, 1).map((option) => {
                            return (
                              <Flex
                                transition={"all 0.3s"}
                                _active={{
                                  transform: "scale(0.92)",
                                }}
                                onClick={() => {
                                  setSelectionOption(option.key);
                                  setFieldValue("name", option.name)
                                }}
                                background={
                                  option.key === selectedOption
                                    ? "var(--chakra-colors-primary-50)"
                                    : "white"
                                }
                                cursor={"pointer"}
                                py={4}
                                px={3}
                                rounded={12}
                                border={
                                  "1px solid var(--chakra-colors-gray-200)"
                                }
                                mx={7}
                                gap={3}
                              >
                                <Box
                                  p={2}
                                  background={"white"}
                                  h={"min-content"}
                                  rounded={4}
                                >
                                  {option.icon}
                                </Box>
                                <Box>
                                  <Text fontWeight={600}>{option.title}</Text>
                                  <Text fontSize={"small"}>
                                    {option.subtitle}
                                  </Text>
                                </Box>
                              </Flex>
                            );
                          })}
                          <Stack
                            gap={4}
                            pos={"relative"}
                            rounded={12}
                            border={"2px solid #cacaca"}
                            mx={3}
                            px={2}
                            mt={8}
                            py={3}
                          >
                            <Img
                              pos={"absolute"}
                              top={-4}
                              left={4}
                              h={4}
                              src={PresetImg}
                            />
                            {options.slice(1).map((option) => {
                              return (
                                <Flex
                                  transition={"all 0.3s"}
                                  _active={{
                                    transform: "scale(0.92)",
                                  }}
                                  onClick={() => {
                                    setSelectionOption(option.key);
                                    setFieldValue("name", option.name)
                                  }}
                                  background={
                                    option.key === selectedOption
                                      ? "var(--chakra-colors-primary-50)"
                                      : "white"
                                  }
                                  cursor={"pointer"}
                                  py={4}
                                  px={3}
                                  rounded={12}
                                  border={
                                    "1px solid var(--chakra-colors-gray-200)"
                                  }
                                  mx={2}
                                  gap={3}
                                >
                                  <Box
                                    p={2}
                                    background={"white"}
                                    h={"min-content"}
                                    rounded={4}
                                  >
                                    {option.icon}
                                  </Box>
                                  <Box>
                                    <Text fontWeight={600}>{option.title}</Text>
                                    <Text fontSize={"small"}>
                                      {option.subtitle}
                                    </Text>
                                  </Box>
                                </Flex>
                              );
                            })}
                          </Stack>
                        </Stack>
                      </Stack>
                      <Divider my={2} />
                      <FormControl
                        px={4}
                        pb={2}
                        isInvalid={!!errors.name && touched.name}
                      >
                        <FormLabel fontSize={14}>Assistant Name</FormLabel>
                        <Field
                          fontSize={"sm"}
                          as={Input}
                          placeholder="Enter your assistant name"
                          name="name"
                          validate={(value: any) => {
                            let error;
                            if (!value) {
                              return "Name is required";
                            }
                            return error;
                          }}
                        />
                        <FormErrorMessage fontSize={"small"}>
                          {errors.name}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                    <Flex m={2} justify={"flex-end"}>
                      <Button
                        isLoading={createdAgent.state === "loading"}
                        type="submit"
                        fontSize={"sm"}
                        variant={"solid"}
                        colorScheme="primary"
                      >
                        Create assistant
                      </Button>
                    </Flex>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </DrawerContent>
      </Drawer>
      <Stack px={4}>
        <Flex mt={2} justifyContent={"space-between"} alignItems={"center"}>
          <Box mx={3} mt={4}>
            <Heading fontSize={"lg"}>Assistants</Heading>
            <Text fontSize={"sm"} color={"gray"}>
              Create, Modify your assistants
            </Text>
          </Box>
          <Button
            onClick={() => {
              onOpen();
            }}
            colorScheme="primary"
            size={"sm"}
            leftIcon={<PlusIcon size={16} />}
          >
            Create Assistant
          </Button>
        </Flex>
        <Flex ml={3} gap={2}>
          <Input
            rounded={4}
            w={300}
            onChange={(v) => {
              debouncedSearch(v.target.value)
            }}
            fontSize={"sm"}
            placeholder="Enter your assistant name"
          />
          <Button hidden fontSize={"sm"} variant={"outline"}>
            Filters
          </Button>
        </Flex>
        {!agents.data.length && agents.state === "loading" ? (
          <Center height={"100%"}>
            <Spinner />
          </Center>
        ) : (
          <Grid gap={4} py={4} gridTemplateColumns={"repeat(3, 1fr)"} px={2}>
            {agents.data.map((agent: any) => (
              <Stack
                transition={"all 0.3s"}
                _hover={{
                  border: "1px solid gray",
                }}
                cursor={"pointer"}
                border={"1px solid #ECECF1"}
                onClick={() => {
                  navigate(`/app/assistants/${agent.id}`);
                }}
                key={agent.id}
                py={3}
                px={4}
                borderRadius={12}
              >
                <Flex alignItems={"center"} gap={3}>
                  <Avatar
                    src={agent.profile_pic}
                    size={"sm"}
                    name={agent.name}
                  />
                  <Box flex={1}>
                    <Text fontWeight={"semibold"}>{agent.name}</Text>
                    <Text fontSize={"small"} color={"gray"}>
                      {_.capitalize(agent.use_case)}
                    </Text>
                  </Box>
                  <Stack>
                    <Badge
                      rounded={"full"}
                      colorScheme={(() => {
                        switch (agent.status) {
                          case "OFFLINE":
                            return "red";
                          case "ONLINE":
                            return "green";
                          default:
                            return "primary";
                        }
                      })()}
                      title={_.capitalize(agent.status)}
                      size={"sm"}
                      px={2}
                    >
                      {_.capitalize(agent.status)}
                    </Badge>
                  </Stack>
                </Flex>
                <Flex justifyContent={"space-between"} mt={1}>
                  <Box>
                    <Text color={"gray"} fontSize={12}>
                      Created By
                    </Text>
                    <Text fontWeight={600} mt={-1} fontSize={"small"}>
                      {agent.created_by?.name}
                    </Text>
                  </Box>
                  <Box>
                    <Text color={"gray"} fontSize={12}>
                      Created At
                    </Text>
                    <Text fontWeight={600} mt={-1} fontSize={"small"}>
                      {moment(agent.created_at).format("DD MMM, YYYY")}
                    </Text>
                  </Box>
                </Flex>
              </Stack>
            ))}
          </Grid>
        )}
        <Flex ml={2} justifyContent={"space-between"}>
          <Flex align={"center"} gap={2}>
            <Text whiteSpace={"nowrap"} fontSize={"small"}>
              {/* @ts-ignore */}
              Showing {metadata?.total_count < (page * size) ? metadata?.total_count : page * size} of {metadata?.total_count}
            </Text>
            <CoreSelect onChange={(v) => {
              setSize(Number.parseInt(v.target.value, 10))
            }}  defaultValue={size} size="sm" borderRadius={8}>
              <option value={15} selected>
                15
              </option>
              <option value={30}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </CoreSelect>
          </Flex>
          <Flex gap={2} align={"center"}>
            <IconButton
              isDisabled={page === 1}
              onClick={() => {
                setPage(1)
              }}
              rounded={"full"}
              variant={"outline"}
              icon={<ChevronsLeftIcon size={16} />}
              aria-label=""
            />
            <IconButton
              isDisabled={page === 1}
              onClick={() => {
                setPage(page - 1)
              }}
              rounded={"full"}
              variant={"outline"}
              icon={<ChevronLeftIcon size={16} />}
              aria-label=""
            />
            <IconButton
                // @ts-ignore
              isDisabled={metadata?.total_count <= (page * size)}
              onClick={() => {
                setPage(page + 1)
              }}
              rounded={"full"}
              variant={"outline"}
              icon={<ChevronRightIcon size={16} />}
              aria-label=""
            />
            <IconButton
                // @ts-ignore
              isDisabled={metadata?.total_count <= (page * size)}
              onClick={() => {
                // @ts-ignore
                setPage(Math.ceil(metadata?.total_count / size))
              }}
              rounded={"full"}
              variant={"outline"}
              icon={<ChevronsRightIcon size={16} />}
              aria-label=""
            />
          </Flex>
        </Flex>
      </Stack>
    </Box>
  );
}
