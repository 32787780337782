export const SET_CREATE_ORGANIZATION_LOADING = "organization/create/loading"
export const SET_CREATE_ORGANIZATION_SUCCESS = "organization/create/success"
export const SET_CREATE_ORGANIZATION_ERROR = "organization/create/error"
export const SET_UPDATE_ORGANIZATION_LOADING = "organization/update/loading"
export const SET_UPDATE_ORGANIZATION_SUCCESS = "organization/update/success"
export const SET_UPDATE_ORGANIZATION_ERROR = "organization/update/error"
export const SET_DELETE_ORGANIZATION_LOADING = "organization/delete/loading"
export const SET_DELETE_ORGANIZATION_SUCCESS = "organization/delete/success"
export const SET_DELETE_ORGANIZATION_ERROR = "organization/delete/error"
export const SET_GET_ORGANIZATION_LOADING = "organization/get/loading"
export const SET_GET_ORGANIZATION_SUCCESS = "organization/get/success"
export const SET_GET_ORGANIZATION_ERROR = "organization/get/error"
export const SET_LIST_ORGANIZATION_LOADING = "organization/list/loading"
export const SET_LIST_ORGANIZATION_SUCCESS = "organization/list/success"
export const SET_LIST_ORGANIZATION_ERROR = "organization/list/error"