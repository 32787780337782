import {
  Grid,
  Stat,
  StatArrow,
  StatHelpText,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import Page from "../../@components/Page";
import Chart from "react-apexcharts";

export default function Home() {
  return (
    <Page title="Insights">
      <Grid mx={4} gap={4} gridTemplateColumns={`repeat(5, 1fr)`}>
        <Stat
          cursor={"pointer"}
          rounded={8}
          px={2}
          py={2}
          border={"1px solid #cacaca"}
        >
          <StatLabel>Dialed Calls</StatLabel>
          <StatNumber>50</StatNumber>
          <StatHelpText mt={1} fontSize={12}>
            How many calls you've made each day.
          </StatHelpText>
        </Stat>
        <Stat
          cursor={"pointer"}
          rounded={8}
          px={2}
          py={2}
          border={"1px solid #cacaca"}
        >
          <StatLabel>Total Minutes</StatLabel>
          <StatNumber>25.1</StatNumber>
          <StatHelpText mt={1} fontSize={12}>
            The total number of minutes you've used each day.
          </StatHelpText>
        </Stat>
        <Stat
          cursor={"pointer"}
          rounded={8}
          px={2}
          py={2}
          border={"1px solid #cacaca"}
        >
          <StatLabel>A. Score</StatLabel>
          <StatNumber>88.1%</StatNumber>
          <StatHelpText mt={1} fontSize={12}>
            <StatArrow type="increase" />
            9.05%
          </StatHelpText>
        </Stat>
        <Stat
          cursor={"pointer"}
          rounded={8}
          px={2}
          py={2}
          border={"1px solid #cacaca"}
        >
          <StatLabel>Overall Summary</StatLabel>
          <StatNumber>Good</StatNumber>
          <StatHelpText mt={1} fontSize={12}>
            Your call response rate is on target
          </StatHelpText>
        </Stat>
      </Grid>
      <Grid pb={8} mt={4} mx={4} gap={4} gridTemplateColumns={`repeat(2, 1fr)`}>
        <Stat
          cursor={"pointer"}
          rounded={8}
          px={2}
          py={2}
          border={"1px solid #cacaca"}
        >
          <StatLabel>Overall Summary</StatLabel>
          <StatHelpText color={"gray"} fontSize={12}>
            Your call response rate is on target
          </StatHelpText>
          <StatNumber>
            <Chart
              options={{
                chart: {
                  height: 350,
                  type: "area",
                  toolbar: {
                    show: false,
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: "smooth",
                },
                xaxis: {
                  type: "datetime",
                  categories: [
                    "2018-09-19T00:00:00.000Z",
                    "2018-09-19T01:30:00.000Z",
                    "2018-09-19T02:30:00.000Z",
                    "2018-09-19T03:30:00.000Z",
                    "2018-09-19T04:30:00.000Z",
                    "2018-09-19T05:30:00.000Z",
                    "2018-09-19T06:30:00.000Z",
                  ],
                },
                tooltip: {
                  x: {
                    format: "dd/MM/yy HH:mm",
                  },
                },
              }}
              series={[
                {
                  name: "series1",
                  data: [31, 40, 28, 51, 42, 109, 100],
                },
                {
                  name: "series2",
                  data: [11, 32, 45, 32, 34, 52, 41],
                },
              ]}
              type="area"
            />
          </StatNumber>
        </Stat>
        <Stat
          cursor={"pointer"}
          rounded={8}
          px={2}
          py={2}
          border={"1px solid #cacaca"}
        >
          <StatLabel>Call Count</StatLabel>
          <StatHelpText color={"gray"} fontSize={12}>
          How many calls you've made each day.
          </StatHelpText>
          <StatNumber>
            <Chart
              options={{
                chart: {
                  height: 350,
                  type: "area",
                  toolbar: {
                    show: false,
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: "smooth",
                },
                xaxis: {
                  type: "datetime",
                  categories: [
                    "2018-09-19T00:00:00.000Z",
                    "2018-09-19T01:30:00.000Z",
                    "2018-09-19T02:30:00.000Z",
                    "2018-09-19T03:30:00.000Z",
                    "2018-09-19T04:30:00.000Z",
                    "2018-09-19T05:30:00.000Z",
                    "2018-09-19T06:30:00.000Z",
                  ],
                },
                tooltip: {
                  x: {
                    format: "dd/MM/yy HH:mm",
                  },
                },
              }}
              series={[
                {
                  name: "series1",
                  data: [31, 40, 28, 51, 42, 109, 100],
                },
                {
                  name: "series2",
                  data: [11, 32, 45, 32, 34, 52, 41],
                },
              ]}
              type="area"
            />
          </StatNumber>
        </Stat>
        <Stat
          cursor={"pointer"}
          rounded={8}
          px={2}
          py={2}
          border={"1px solid #cacaca"}
        >
          <StatLabel>Average Call Duration</StatLabel>
          <StatHelpText color={"gray"} fontSize={12}>
          Average Call Duration
          </StatHelpText>
          <StatNumber>
            <Chart
              options={{
                chart: {
                  height: 350,
                  type: "area",
                  toolbar: {
                    show: false,
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: "smooth",
                },
                xaxis: {
                  type: "datetime",
                  categories: [
                    "2018-09-19T00:00:00.000Z",
                    "2018-09-19T01:30:00.000Z",
                    "2018-09-19T02:30:00.000Z",
                    "2018-09-19T03:30:00.000Z",
                    "2018-09-19T04:30:00.000Z",
                    "2018-09-19T05:30:00.000Z",
                    "2018-09-19T06:30:00.000Z",
                  ],
                },
                tooltip: {
                  x: {
                    format: "dd/MM/yy HH:mm",
                  },
                },
              }}
              series={[
                {
                  name: "series1",
                  data: [31, 40, 28, 51, 42, 109, 100],
                },
              ]}
              type="area"
            />
          </StatNumber>
        </Stat>
        <Stat
          cursor={"pointer"}
          rounded={8}
          px={2}
          py={2}
          border={"1px solid #cacaca"}
        >
          <StatLabel>Daily Spend</StatLabel>
          <StatHelpText color={"gray"} fontSize={12}>
          How much you've spent across all your calls.
          </StatHelpText>
          <StatNumber>
            <Chart
              options={{
                chart: {
                  height: 350,
                  type: "area",
                  toolbar: {
                    show: false,
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                xaxis: {
                  type: "datetime",
                  categories: [
                    "2018-09-19T00:00:00.000Z",
                    "2018-09-19T01:30:00.000Z",
                    "2018-09-19T02:30:00.000Z",
                    "2018-09-19T03:30:00.000Z",
                    "2018-09-19T04:30:00.000Z",
                    "2018-09-19T05:30:00.000Z",
                    "2018-09-19T06:30:00.000Z",
                  ],
                },
                tooltip: {
                  x: {
                    format: "dd/MM/yy HH:mm",
                  },
                },
              }}
              series={[
                {
                  name: "series1",
                  data: [31, 40, 28, 51, 42, 109, 100],
                },
              ]}
              type="line"
            />
          </StatNumber>
        </Stat>
      </Grid>
    </Page>
  );
}
