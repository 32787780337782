import { environment } from "./environment.loader";
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth'

const firebase = initializeApp(environment.FIREBASE_API);

const AuthManager = getAuth(firebase);

export {
    firebase,
    AuthManager
}