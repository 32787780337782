import { ChakraProvider, ColorModeScript, extendTheme } from "@chakra-ui/react"
import * as ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { App } from "./App"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import { store } from "./lib/store"
import './index.scss';

const container = document.getElementById("root")
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container)

const theme = extendTheme({
  fonts: {
    heading: `"Circular", sans-serif;`,
    body: `"Circular", sans-serif;`
  },
  colors: {
    primary: {
      main: "#4F44E0",
      50: "#eae9fb",
      100: "#c1bdf4",
      200: "#9892ed",
      300: "#6f66e6",
      400: "#463ade",
      500: "#4F44E0",
      600: "#4F44E0",
      700: "#4F44E0",
      800: "#0f0b42",
      900: "#050416"
    }
  }
})

root.render(
  <Provider store={store}>
    <ColorModeScript initialColorMode="light" />
    {localStorage.getItem('chakra-ui-color-mode')? <></>:<>{localStorage.setItem('chakra-ui-color-mode', 'light')}</>}
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ChakraProvider>
  </Provider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

