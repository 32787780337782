import { axios } from "../../../utils/axios.loader";
import { AppDispatch } from "../../store";
// import { setAgentGetLoading, setAgentGetSuccess } from "../assistants/actions";
import { setCompany, setConfigError, setConfigLoading, setConfigSuccess, setListAgentsLoading, setListAgentsSuccess, setOrganizationGetLoading, setOrganizationGetSuccess, setUserProfile } from "./actions";

export const loadUserProfile = () => async (dispatch: AppDispatch) => {
    try {
        const request = await axios.get('/users/me')
        const response = request.data;
        dispatch(setUserProfile(response))
    } catch (err) {

    }
}

export const loadCompanyByUser = () => async (dispatch: AppDispatch) => {
    try {
        const request = await axios.get('/company/')
        const response = request.data;
        dispatch(setCompany(response))
        dispatch(loadAgents(response.id))
    } catch (err) {

    }
}

export const loadAgents = (page = 1, size = 25, keyword = '', sort: string = '-created_at,status') => async (dispatch: AppDispatch) => {
    dispatch(setListAgentsLoading())
    try {
        const request = await axios.get(`/agent/?page=${page}&page_size=${size}&sort_value=${sort}${keyword ? `&filters=name[contains]=${keyword}` : ''}`)
        const response = request.data;
        dispatch(setListAgentsSuccess(response))
    } catch (err) {

    }
}

export const loadConfig = () => async (dispatch: AppDispatch) => {
    dispatch(setConfigLoading())
    try {
        const request = await axios.get(`/agent/model-config`)
        const response = request.data;
        dispatch(setConfigSuccess(response))
    } catch (err) {
        dispatch(setConfigError())
    }
}

export const getOrganization = (organization: string) => async (dispatch: AppDispatch) => {
    dispatch(setOrganizationGetLoading())
    try {
        const request = await axios.get(`/organization/${organization}`)
        const response = request.data
        dispatch(setOrganizationGetSuccess(response))
    } catch (err) {}
}

export const searchOrganizations = async (keyword: string = '') => {
    try {
        const request = await axios.get(`/organization/?page_size=100&filters=name[contains]=${keyword}`)
        return (request.data?.organizations || []).map((v: any) => ({
            label: v.name,
            value: v.id
        }))
    } catch (err) {
        return []
    }
}

export const searchTeams = async (organization: string, keyword: string = '') => {
    try {
        const request = await axios.get(`/teams/organizations/${organization}?page_size=100&filters=name[contains]=${keyword}`)
        return (request.data?.teams || []).map((v: any) => ({
            label: v.name,
            value: v.id
        }))
    } catch (err) {
        return []
    }
}