import { RESET_AGENT_CREATE, RESET_AGENT_UPDATE, SET_AGENT_CREATE_ERROR, SET_AGENT_CREATE_LOADING, SET_AGENT_CREATE_SUCCESS, SET_AGENT_UPDATE_ERROR, SET_AGENT_UPDATE_LOADING, SET_AGENT_UPDATE_SUCCESS, SET_GET_AGENT_ERROR, SET_GET_AGENT_LOADING, SET_GET_AGENT_RESET, SET_GET_AGENT_SUCCESS } from "./constants";

export function setAgentCreateLoading () {
    return {
        type: SET_AGENT_CREATE_LOADING
    }
}

export function setAgentCreateSuccess (payload: any) {
    return {
        type: SET_AGENT_CREATE_SUCCESS,
        payload
    }
}

export function setAgentCreateError (error: any) {
    return {
        type: SET_AGENT_CREATE_ERROR,
        error
    }
}

export function resetAgentCreate () {
    return {
        type: RESET_AGENT_CREATE
    }
}

export function setAgentUpdateLoading () {
    return {
        type: SET_AGENT_UPDATE_LOADING
    }
}

export function setAgentUpdateSuccess (payload: any) {
    return {
        type: SET_AGENT_UPDATE_SUCCESS,
        payload
    }
}

export function setAgentUpdateError (error: any) {
    return {
        type: SET_AGENT_UPDATE_ERROR,
        error
    }
}

export function resetAgentUpdate () {
    return {
        type: RESET_AGENT_UPDATE
    }
}

export function setAgentGetLoading () {
    return {
        type: SET_GET_AGENT_LOADING
    }
}

export function setAgentGetReset () {
    return {
        type: SET_GET_AGENT_RESET
    }
}


export function setAgentGetSuccess (payload: any, metadata: any = {}) {
    return {
        type: SET_GET_AGENT_SUCCESS,
        payload,
        metadata
    }
}

export function setAgentGetError (error: any) {
    return {
        type: SET_GET_AGENT_ERROR,
        error
    }
}