export const SET_PLATFORM_LOADING = "platform/loading";
export const SET_PLATFORM_SUCCESS = "platform/success";
export const SET_PLATFORM_ERROR = "platform/error";
export const SET_USER_PROFILE = "platform/setUserProfile";
export const SET_COMPANY = "platform/setCompany";
export const SET_LIST_AGENTS_LOADING = "platform/agents/list/loading";
export const SET_LIST_AGENTS_SUCCESS = "platform/agents/list/success";
export const SET_LIST_AGENTS_ERROR = "platform/agents/list/error";
export const SET_AGENT_LOADING = "platform/selectedAgent/loading"
export const SET_AGENT_SUCCESS = "platform/selectedAgent/success"
export const SET_AGENT_ERROR = "platform/selectedAgent/error"
export const SET_CONFIG_LOADING = "platform/config/loading"
export const SET_CONFIG_SUCCESS = "platform/config/success"
export const SET_CONFIG_ERROR = "platform/config/error"
export const SET_ORGANIZATION_GET_LOADING = "organization/get/loading";
export const SET_ORGANIZATION_GET_SUCCESS = "organization/get/success";
export const SET_ORGANIZATION_GET_ERROR = "organization/get/error";