import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { LoginReducer } from "./auth/login/reducers";
import { PlatformReducer } from "./app/platform/reducer";
import { PlaygroundReducer } from "./app/playground/reducer";
import { AssistantsReducer } from "./app/assistants/reducer";
import { SignupReducer } from "./auth/signup/reducers";
import { VoiceLibraryReducer } from "./app/voice-library/reducer";
import { OrganizationReducer } from "./app/organizations/reducers";

const store = configureStore({
    reducer: {
        auth: combineReducers({
            login: LoginReducer,
            signup: SignupReducer
        }),
        app: combineReducers({
            platform: PlatformReducer,
            playground: PlaygroundReducer,
            assistants: AssistantsReducer,
            voices: VoiceLibraryReducer,
            organizations: OrganizationReducer
        })
    }
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export { store }