import { Flex, Stack, Text, Select as CoreSelect, IconButton, Grid, Box, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Tooltip, useToast, Button, Input } from "@chakra-ui/react"
import Page from "../../@components/Page"
import { ChevronLeftIcon, ChevronRightIcon, ChevronsLeftIcon, ChevronsRightIcon, CopyIcon, PauseIcon, PlayIcon } from "lucide-react"
import { useAppDispatch, useAppSelector } from "../../lib/hooks"
import { RefObject, useEffect, useRef, useState } from "react"
import { getVoicesList } from "../../lib/app/voice-library/thunk"
import _ from "lodash"

export function Audio ({ url, onInteraction }: { url: string, onInteraction: (state: "playing" | "paused", ref?: RefObject<HTMLAudioElement> | null) => void }) {
    const [duration, setDuration] = useState(0)
    const [track, setTrack] = useState(0)
    const [isPaused, setPaused] = useState(true)
    const ref = useRef<HTMLAudioElement>(null)

    return (
        <Flex alignItems={"center"} gap={3}>
            <audio onEnded={() => {
                onInteraction("paused", ref)
                setTrack(0)
                setPaused(true)
            }} onTimeUpdate={(e) => {
                setTrack(Math.ceil(e.currentTarget.currentTime))
            }} hidden ref={ref} src={url} onLoadedMetadata={(e) => {
                setDuration(Math.ceil(ref.current?.duration || 0))
            }} />
            {isPaused ? <PlayIcon cursor={"pointer"} onClick={() => {
                if (ref.current?.paused) {
                    ref.current?.play()
                    setPaused(false)
                } else {
                    ref.current?.pause()
                    setPaused(true)
                }
            }} /> : <PauseIcon cursor={"pointer"} onClick={() => {
                if (ref.current?.paused) {
                    ref.current?.play()
                    setPaused(false)
                } else {
                    ref.current?.pause()
                    setPaused(true)
                }
            }} />}
            <Text fontSize={"small"}>{`00:0${track}`}</Text>
            <Slider max={duration} value={track} min={0} colorScheme="primary" defaultValue={0}>
                <SliderTrack>
                    <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
            </Slider>
            <Text fontSize={"small"}>{`00:0${duration}`}</Text>
        </Flex>
    )
}

export default function VoiceLibrary() {
    const voices = useAppSelector(state => state.app.voices.list)
    const metadata = useAppSelector(state => state.app.voices.list.metadata)
    const dispatch = useAppDispatch()
    const [page, setPage] = useState(1)
    const [size, setSize] = useState(15)
    const toast = useToast()
    const currentRef = useRef<HTMLAudioElement>(null)
    const debouncedSearch = _.debounce((v: string) => {
      dispatch(getVoicesList(1, size, v))
    }, 300)

    useEffect(() => {
        dispatch(getVoicesList(page, size))
    }, [page, size])

    return (
        <Page title="Voice Library">
            <Flex mb={4} ml={3} gap={2}>
            <Input
                rounded={4}
                w={300}
                onChange={(v) => {
                debouncedSearch(v.target.value)
                }}
                fontSize={"sm"}
                placeholder="Enter voice name"
            />
            <Button hidden fontSize={"sm"} variant={"outline"}>
                Filters
            </Button>
            </Flex>
            <Stack mx={4}>
                <Grid gap={4} gridTemplateColumns={'repeat(3, 1fr)'}>
                    {voices.data.map((voice: any) => {
                        return <Stack borderRadius={8} p={4} border={"1px solid var(--chakra-colors-gray-200)"} w={"full"}>
                            <Flex alignItems={"center"} justifyContent={"space-between"}>
                                <Box>
                                    <Text fontWeight={600}>{voice.name}</Text>
                                    <Text color={"gray"} fontSize={"small"}>{voice.voice_id}</Text>
                                    <Text color={"gray"} fontSize={"small"}>{`${_.capitalize(voice.gender)} | ${_.capitalize(voice.accent)}`}</Text>
                                </Box>
                                <Tooltip label="Copy ID">
                                    <IconButton onClick={async () => {
                                        toast({
                                            size: "sm",
                                            title: "ID Copied",
                                            status: "success"
                                        })
                                        await window.navigator.clipboard.writeText(voice.voice_id)
                                    }} size={"xs"} aria-label="" icon={<CopyIcon size={12} />} />
                                </Tooltip>
                            </Flex>
                            <Audio onInteraction={(state, ref) => {
                                if (state === "playing") {
                                    // currentRef. = ref?.current
                                }
                            }} url={voice.sample_url}  />
                        </Stack>
                    })}
                </Grid>
                <Flex ml={2} justifyContent={"space-between"}>
          <Flex align={"center"} gap={2}>
            <Text whiteSpace={"nowrap"} fontSize={"small"}>
              {/* @ts-ignore */}
              Showing {metadata?.total_count < (page * size) ? metadata?.total_count : page * size} of {metadata?.total_count}
            </Text>
            <CoreSelect onChange={(v) => {
              setSize(Number.parseInt(v.target.value, 10))
            }}  defaultValue={size} size="sm" borderRadius={8}>
              <option value={15} selected>
                15
              </option>
              <option value={30}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </CoreSelect>
          </Flex>
          <Flex gap={2} align={"center"}>
            <IconButton
              isDisabled={page === 1}
              onClick={() => {
                setPage(1)
              }}
              rounded={"full"}
              variant={"outline"}
              icon={<ChevronsLeftIcon size={16} />}
              aria-label=""
            />
            <IconButton
              isDisabled={page === 1}
              onClick={() => {
                setPage(page - 1)
              }}
              rounded={"full"}
              variant={"outline"}
              icon={<ChevronLeftIcon size={16} />}
              aria-label=""
            />
            <IconButton
                // @ts-ignore
              isDisabled={metadata?.total_count <= (page * size)}
              onClick={() => {
                setPage(page + 1)
              }}
              rounded={"full"}
              variant={"outline"}
              icon={<ChevronRightIcon size={16} />}
              aria-label=""
            />
            <IconButton
                // @ts-ignore
              isDisabled={metadata?.total_count <= (page * size)}
              onClick={() => {
                // @ts-ignore
                setPage(Math.ceil(metadata?.total_count / size))
              }}
              rounded={"full"}
              variant={"outline"}
              icon={<ChevronsRightIcon size={16} />}
              aria-label=""
            />
          </Flex>
        </Flex>
                
            </Stack>
        </Page>
    )
}