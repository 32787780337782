import { Flex, Stack, Text, Select as CoreSelect, IconButton, Grid, Box, Tooltip, useToast, Button, Input, Img, Avatar, Menu, MenuButton, MenuList, MenuItem, useDisclosure, Modal, ModalOverlay, ModalContent, ModalBody, Heading, Tabs, TabList, Tab, TabPanels, TabPanel, FormControl, FormLabel, Textarea, FormErrorMessage } from "@chakra-ui/react"
import Page from "../../@components/Page"
import { ChevronLeftIcon, ChevronRightIcon, ChevronsLeftIcon, ChevronsRightIcon, CopyIcon, MoreVerticalIcon, PlusIcon } from "lucide-react"
import { useAppDispatch, useAppSelector } from "../../lib/hooks"
import { useEffect, useRef, useState } from "react"
import _ from "lodash"
import { createOrganization, getOrganizations, updateOrganization } from "../../lib/app/organizations/thunk"
import { Field, Form, Formik } from "formik"
import { getOrganization } from "../../lib/app/platform/thunk"

export default function Organizations() {
    const organizations = useAppSelector(state => state.app.organizations.list)
    const create = useAppSelector(state => state.app.organizations.create)
    const update = useAppSelector(state => state.app.organizations.update)
    const metadata = useAppSelector(state => state.app.organizations.list.metadata)
    const dispatch = useAppDispatch()
    const [page, setPage] = useState(1)
    const [size, setSize] = useState(15)
    const toast = useToast()
    const currentRef = useRef<HTMLAudioElement>(null)
    const [keyword, setKeyword] = useState('')
    const debouncedSearch = _.debounce((v: string) => {
      dispatch(getOrganizations(1, size, v))
    }, 300)

    useEffect(() => {
        dispatch(getOrganizations(page, size))
    }, [page, size])
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [currentOrganization, setCurrentOrganization] = useState<any | null>(null)

    return (
        <Page title="Organizations">
            <Modal
                size={"md"}
                isCentered
                isOpen={isOpen}
                onClose={() => {
                    onClose();
                }}
            >
                <ModalOverlay />
                <ModalContent>
                <ModalBody p={4}>
                    <Heading mb={4} size={"sm"}>{currentOrganization ? "Update Organization" : "Create Organization"}</Heading>
                    <Formik
              onSubmit={(values) => {
                if (currentOrganization?.id) {
                  dispatch(updateOrganization(currentOrganization.id, {
                    ...currentOrganization,
                    ...values,
                    "providers": {
                        "vapi": {
                            "api_key": values.private_key,
                            "public_api_key": values.public_key
                        },
                        "millis": {
                          "api_key": values.millis_private_key,
                          "public_api_key": values.millis_public_key
                        }
                    },
                  }, () => {
                    toast({
                      title: "Organization updated",
                      status: "success"
                    });
                    onClose()
                    setCurrentOrganization(null)
                    dispatch(getOrganizations(page, size, keyword));
                  }))
                } else {
                  dispatch(createOrganization({
                    ...values
                  }, () => {
                    toast({
                      title: "Feedback submitted",
                      status: "success"
                    });
                    onClose()
                    dispatch(getOrganizations(page, size, keyword));
                  }))
                }
              }}
              initialValues={{
                name: currentOrganization?.name || '',
                description: currentOrganization?.description || '',
                private_key: currentOrganization?.providers?.vapi?.api_key || '',
                public_key: currentOrganization?.providers?.vapi?.public_api_key || '',
                millis_private_key: currentOrganization?.providers?.millis?.private_key || '',
                millis_public_key: currentOrganization?.providers?.millis?.public_api_key
              }}
            >
              {({ errors, touched, values }) => (
                <Form
                  style={{
                    width: "100%",
                  }}
                >
                  <Stack>
                  <FormControl isInvalid={!!errors.name}>
                      <FormLabel fontSize={"sm"}>
                         Name {" "}
                        {/* <Tooltip hasArrow label="info icon">
                          <InfoOutlineIcon cursor={"pointer"} />
                        </Tooltip> */}
                      </FormLabel>
                      <Field
                        name="name"
                        value={values.name}
                        rows={4}
                        as={Input}
                        validate={(v: string) => {
                          let error;
                          if (!v) {
                            error = "Enter name";
                          }
                          return error;
                        }}
                        fontSize={"sm"}
                        placeholder="Please enter organization name"
                      />
                      <FormErrorMessage>{errors.name as string}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.description}>
                      <FormLabel fontSize={"sm"}>
                         Description {" "}
                        {/* <Tooltip hasArrow label="info icon">
                          <InfoOutlineIcon cursor={"pointer"} />
                        </Tooltip> */}
                      </FormLabel>
                      <Field
                        name="description"
                        value={values.description}
                        rows={4}
                        as={Textarea}
                        validate={(v: string) => {
                          let error;
                          if (!v) {
                            error = "Enter a description";
                          }
                          return error;
                        }}
                        fontSize={"sm"}
                        placeholder="Please enter a description"
                      />
                      <FormErrorMessage>{errors.description as string}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.private_key}>
                      <FormLabel fontSize={"sm"}>
                         VAPI Private Key {" "}
                        {/* <Tooltip hasArrow label="info icon">
                          <InfoOutlineIcon cursor={"pointer"} />
                        </Tooltip> */}
                      </FormLabel>
                      <Field
                        name="private_key"
                        value={values.private_key}
                        rows={4}
                        as={Input}
                        validate={(v: string) => {
                          let error;
                          if (!v) {
                            error = "Enter private key";
                          }
                          return error;
                        }}
                        fontSize={"sm"}
                        placeholder="Please enter private key"
                      />
                      <FormErrorMessage>{errors.private_key as string}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.public_key}>
                      <FormLabel fontSize={"sm"}>
                      VAPI Public Key {" "}
                        {/* <Tooltip hasArrow label="info icon">
                          <InfoOutlineIcon cursor={"pointer"} />
                        </Tooltip> */}
                      </FormLabel>
                      <Field
                        name="public_key"
                        value={values.public_key}
                        rows={4}
                        as={Input}
                        validate={(v: string) => {
                          let error;
                          if (!v) {
                            error = "Enter public key";
                          }
                          return error;
                        }}
                        fontSize={"sm"}
                        placeholder="Please enter public key"
                      />
                      <FormErrorMessage>{errors.public_key as string}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.millis_private_key}>
                      <FormLabel fontSize={"sm"}>
                         Millis Private Key {" "}
                        {/* <Tooltip hasArrow label="info icon">
                          <InfoOutlineIcon cursor={"pointer"} />
                        </Tooltip> */}
                      </FormLabel>
                      <Field
                        name="millis_private_key"
                        value={values.millis_private_key}
                        rows={4}
                        as={Input}
                        validate={(v: string) => {
                          let error;
                          return error;
                        }}
                        fontSize={"sm"}
                        placeholder="Please enter millis private key"
                      />
                      <FormErrorMessage>{errors.millis_private_key as string}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.millis_public_key}>
                      <FormLabel fontSize={"sm"}>
                      Millis Public Key {" "}
                        {/* <Tooltip hasArrow label="info icon">
                          <InfoOutlineIcon cursor={"pointer"} />
                        </Tooltip> */}
                      </FormLabel>
                      <Field
                        name="millis_public_key"
                        value={values.millis_public_key}
                        rows={4}
                        as={Input}
                        validate={(v: string) => {
                          let error;
                          return error;
                        }}
                        fontSize={"sm"}
                        placeholder="Please enter millis public key"
                      />
                      <FormErrorMessage>{errors.millis_public_key as string}</FormErrorMessage>
                    </FormControl>
                  </Stack>
                    <Flex justifyContent={"end"} mt={4}>
                      <Button isLoading={create.state === "loading" || update.state === "loading"} colorScheme={"primary"} size={"sm"}  type="submit">{currentOrganization?.id ? "Update" : "Create"}</Button>
                    </Flex>
                </Form>
              )}
            </Formik>
                    
                </ModalBody>
                </ModalContent>
            </Modal>
            <Flex justifyContent={"space-between"} mb={4} mx={3} gap={2}>
            <Input
                rounded={4}
                w={300}
                onChange={(v) => {
                debouncedSearch(v.target.value)
                }}
                fontSize={"sm"}
                placeholder="Enter organization name"
            />
            <Button hidden fontSize={"sm"} variant={"outline"}>
                Filters
            </Button>

            <Button
                onClick={() => {
                onOpen();
                }}
                colorScheme="primary"
                size={"sm"}
                leftIcon={<PlusIcon size={16} />}
            >
                Create Organization
            </Button>
            </Flex>
            <Stack mx={4}>
                <Grid gap={4} gridTemplateColumns={'repeat(3, 1fr)'}>
                    {organizations.data.map((organization: any) => {
                        return <Stack gap={0} borderRadius={8} p={4} border={"1px solid var(--chakra-colors-gray-200)"} w={"full"}>
                            <Flex justifyContent={"space-between"}>
                                <Box>
                                    <Avatar rounded={0} name={organization.name} src={organization.image_url} />
                                    <Text mt={1} fontWeight={600}>{organization.name}</Text>
                                </Box>
                                <Flex gap={2}>
                                <Tooltip label="Copy ID">
                                    <IconButton onClick={async () => {
                                        toast({
                                            size: "sm",
                                            title: "ID Copied",
                                            status: "success"
                                        })
                                        // await window.navigator.clipboard.writeText(voice.voice_id)
                                    }} size={"xs"} aria-label="" icon={<CopyIcon size={12} />} />
                                </Tooltip>
                                <Menu>
                                    <MenuButton size={"xs"} as={IconButton} icon={<MoreVerticalIcon size={12} />}>
                                        
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem onClick={() => {
                                            setCurrentOrganization(organization)
                                            onOpen()
                                        }} fontSize={"sm"}>Update</MenuItem>
                                    </MenuList>
                                </Menu>
                                </Flex>
                                
                            </Flex>
                            <Box>
                            <Text color={"gray"} fontSize={"small"}>{organization.id}</Text>
                            <Text color={"gray"} fontSize={"small"}>{organization.description}</Text>
                            </Box>
                        </Stack>
                    })}
                </Grid>
                <Flex ml={2} justifyContent={"space-between"}>
          <Flex align={"center"} gap={2}>
            <Text whiteSpace={"nowrap"} fontSize={"small"}>
              {/* @ts-ignore */}
              Showing {metadata?.total_count < (page * size) ? metadata?.total_count : page * size} of {metadata?.total_count}
            </Text>
            <CoreSelect onChange={(v) => {
              setSize(Number.parseInt(v.target.value, 10))
            }}  defaultValue={size} size="sm" borderRadius={8}>
              <option value={15} selected>
                15
              </option>
              <option value={30}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </CoreSelect>
          </Flex>
          <Flex gap={2} align={"center"}>
            <IconButton
              isDisabled={page === 1}
              onClick={() => {
                setPage(1)
              }}
              rounded={"full"}
              variant={"outline"}
              icon={<ChevronsLeftIcon size={16} />}
              aria-label=""
            />
            <IconButton
              isDisabled={page === 1}
              onClick={() => {
                setPage(page - 1)
              }}
              rounded={"full"}
              variant={"outline"}
              icon={<ChevronLeftIcon size={16} />}
              aria-label=""
            />
            <IconButton
                // @ts-ignore
              isDisabled={metadata?.total_count <= (page * size)}
              onClick={() => {
                setPage(page + 1)
              }}
              rounded={"full"}
              variant={"outline"}
              icon={<ChevronRightIcon size={16} />}
              aria-label=""
            />
            <IconButton
                // @ts-ignore
              isDisabled={metadata?.total_count <= (page * size)}
              onClick={() => {
                // @ts-ignore
                setPage(Math.ceil(metadata?.total_count / size))
              }}
              rounded={"full"}
              variant={"outline"}
              icon={<ChevronsRightIcon size={16} />}
              aria-label=""
            />
          </Flex>
        </Flex>
                
            </Stack>
        </Page>
    )
}